import btcuzdan from '../images/btccuzdan.png';
const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>Kripto Paralar</h4>
                <h5>Kripto para Nedir?</h5>
                <p>Kripto para, eşler arası işleyen bir ekonomik sistemde başlıca görevi değer takas aracılığı yapmak olan bir dijital varlıktır. Bu ekonomik sistem; işlemleri doğrulamak, güvenceye almak ve yeni birimlerin oluşturulmasını kontrol altında tutmak için kriptografi kullanır.</p>
                <p>Merkezi bankacılık sistemlerinin aksine çoğu kripto paralar dünyanın dört bir yanına dağılmış düğümler (node) olarak da bilinen bilgisayar ağlarına dağıtılarak merkeziyetsizleştirilmiştir.
                    Merkeziyetsiz olmak, kripto paraların tek bir kişi veya kurum tarafından kontrol edilemeyeceği ve üçüncü parti bir aracıya ihtiyaç duymadan kullanıcılarının geliştirdiği ve yönettiği merkeziyetsizliğin de farklı dereceleri bulunduğu sistemlere sahiptir. Ağ yapısına ve node dağılımına dayanarak bazı kripto paralar diğerlerlerinden daha merkezi kabul edilmektedir.</p>
                <h5>Kripto paranın geleceği nasıl olacak?</h5>
                <p>Bugünün sanal dünyasında her şey yavaş yavaş dijitalleşmeye başladı. Kripto para ortaya çıkalı çok uzun bir zaman olmamasına rağmen potansiyelinin sadece küçük bir miktarı ile bile finans dünyasında büyük değişiklikler yapacağını gösterdi. Bu yol biraz engebeli, ama Bitcoin ilk ortaya çıktığında durum çok daha belirsizdi. Bir çok engel vardı. Ortada bu vizyona inanan bir avuç teknoloji delisi insandan başka bir şey yoktu. Şu anda ise tüm yaşanan olumsuzluklara rağmen herkes kripto paranın kaçınılmaz bir şekilde geleceğin kendisi olduğu fikrinde birleşiyor. Fark sadece nasıl ve ne şekilde olacağı konusunda.</p>
            </div>
        </div>
    )
}

export default TabActive;