

const Tab1 = () => {

  return (
    <div class="tab-pane-tab-1">
      <div class="contents-rich-text-block w-richtext">
        <h4>Hakkımızda</h4>
        <p>BtcOley, yatırıma ve varlığa dayalı kripto para ihracı ile yaratılacak ekosistemin teknolojik
          altyapısını oluşturmayı ve yerli, yabancı, bireysel ve kurumsal kullanıcılara hızlı, güvenli ve
          dünya fiyatları ile avantajlı alım/satım hizmeti sağlamayı amaçlamaktadır.</p>

        <p>BtcOley, yatırımları ve blockchain en yeni teknolojileri ile, geniş kripto para yelpazesinde tüm
          ihtiyaç ve beklentilere cevap verebilmek, birikimlerinizi, en çok değeri üretecek şekilde geleceğe
          taşımak, işlemlerinizi en hızlı ve kesintisiz sonuçlandırmak için çalışmaktadır.</p>

        <p>Kurucu ve çalışanlarının finans sektöründe kazandığı bilgi ve birikimlerinden faydalanarak, 7/24
          iletişim desteği ile geleceğin finans aracı kripto para ekosisteminde aktif ve güvenilir işlem
          platformu olarak, kullanıcılarına yüksek kalitede hizmet sunmaktır.</p>
        <h4>Vizyonumuz,</h4>
        <p>Türkiye’de Blockchain teknolojilerine yatırım yaparak, yerel ve global kurumsal markaların
          dijital ödeme sistemlerini geliştirmek ve adına üretilen tokenları başta BtcOley borsasında daha
          sonra diğer yerel ve global borsalarda listelenmesine aracılık etmek suretiyle dijital varlık
          ekosisteminde öncü ve lider olmak.</p>
        <h4>Misyonumuz,</h4>
        <p>Türkiye’de Blockchain ile Ödeme Sistemlerini bir arada uygulayan ilk ve tek Kripto Varlık
          Platformu olarak, milisaniyeler seviyesinde işlem yapan Takas Merkezimiz sayesinde müşterilerine
          ve yatırımcılara en ileri seviyedeki teknolojik altyapımızla hızlı, güvenli ve kesintisiz hizmet
          vermek.</p>
        <h4>Değerlerimiz,</h4>
        <p>Dürüstlük, güvenilirlik, yaratıcılık ve müşteri memnuniyetine odaklı hizmet anlayışı</p>
      </div>
    </div>
  )
}

export default Tab1