import { react, useEffect, useState } from "react"
import { cleanprice } from "../../utils/FormatMarketPrice";
import qrmobile from "../../images/qrmobile.png"
import { Link } from 'react-router-dom';

function Home() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const pos = document.getElementById("nav61")
    const [confirm, setConfirm] = useState();
    const SOCKET_URL = "wss://ws-v2.btcoley.com/markets";
    const [data1, setData1] = useState([]);


    useEffect(() => {
        const socket = new WebSocket(SOCKET_URL);

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            ;
            if (data["market"]) {
                setData1(data["market"]);
            }
        }

        // WebSocket bağlantısı ve event listener'ı sadece bir kere kurulsun
        // Component unmount olduğunda temizleme işlemini yapmamız iyi bir pratik olur
        return () => {
            socket.close(); // WebSocket bağlantısını kapat
        };
    }, []);


    useEffect(() => {
        function handleScroll() {
            const position = window.pageYOffset;
            setScrollPosition(position);

            setConfirm(localStorage.getItem("cookies"));
        }

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (scrollPosition > 0) {

        pos?.classList.remove("navbar61")

    }
    if (scrollPosition === 0) {
        pos?.classList.add("navbar61")
    }


    return (
        <>
            <div className="page-wrapper">
                <div data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
                    <div id="nav61" className="navbar-container w-container col-sm-12 navbar61" >
                        <div className="navbar-padding">
                            <div className="navbar-wrapper">
                                <div className="navbar-logo">
                                    <a href="#" className="brand w-nav-brand">
                                        <img src="images/Logo-Color.svg" loading="lazy" alt="site-logo" className="image-7" />
                                    </a>
                                </div>
                                <div className="navbar-menu">
                                    <nav role="navigation" className="navbar-menu-wrapper w-nav-menu">
                                        <a href="https://pro.btcoley.com/market" target="_blank" className="nav-link w-nav-link">Gelişmiş Al/Sat</a>
                                        <a href="https://pro.btcoley.com/easy-buy-sell" target="_blank" className="nav-link w-nav-link">Kolay Al/Sat</a>
                                        {/*  <a href="https://pro.btcoley.com/change" target="_blank" className="nav-link w-nav-link">Dönüştür</a> */}
                                        <a href="https://pro.btcoley.com/market/FTVT_USDT" target="_blank" className="nav-link w-nav-link">FTVT Satın Al</a>
                                        <a href="https://pro.btcoley.com/register" target="_blank" className="navbar-menu-register w-inline-block">
                                            <div> Kayıt Ol</div>
                                        </a>
                                        <a href="https://pro.btcoley.com/login" target="_blank" className="navbar-menu-login w-inline-block">
                                            <div>Giriş Yap</div>
                                        </a>
                                    </nav>
                                    <div className="menu-button w-nav-button">2
                                        <div className="icon w-icon-nav-menu" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-section wf-section">
                    <div data-delay="4000" data-animation="outin" className="banner-slider w-slider" data-autoplay="true" data-easing="ease-in-out" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit={0} data-nav-spacing={3} data-duration={1000} data-infinite="true">
                        <div className="banner-slider-mask w-slider-mask">
                            <div className="banner-slide w-slide">
                                <div className="banner-padding">
                                    <div className="banner-container w-container">
                                        <div className="w-layout-grid banner-grid">
                                            <div id="w-node-_61bcc8f5-85ee-68ad-9553-08cbf15e1c2f-a4908a08" className="banner-slide-01-text-box">
                                                <div className="banner-slide-01-text-wrapper">
                                                    <div className="banner-slide-01-text">Dünya fiyatlarıyla hızlı kripto para alıp satmak ister misiniz?</div>
                                                    <div className="banner-slide-01-button-wrapper">
                                                        <a href="https://pro.btcoley.com/register" target="_blank" className="white-blue-button w-inline-block">
                                                            <div>Hemen Kayıt Ol</div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="w-node-_942a8a5e-a3c1-1e34-51b9-09424de6702f-a4908a08" className="banner-slide-01-image-box">
                                                <div className="banner-slide-01-image-start">
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-01.png" alt="" className="banner-slide-01-image-01" /> <span style={{ color: "#5a6372", fontSize: `${window.innerWidth > 768 ? "25px" : "11px"}` }} className="price1">{cleanprice(data1["BTC/USDT"]?.prices.ask, 10, data1["BTC/USDT"]?.details.display_decimals).ori} USDT</span> </div>
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-16.png" alt="" className="banner-slide-01-image-02" /><span style={{ color: "white", fontSize: `${window.innerWidth > 768 ? "25px" : "11px"}` }} className="price2">{cleanprice(data1["FTVT/USDT"]?.prices.ask, 10, data1["FTVT/USDT"]?.details.display_decimals).ori} USDT</span> </div>
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-03.png" alt="" className="banner-slide-01-image-03" /><span style={{ color: "#5a6372", fontSize: `${window.innerWidth > 768 ? "25px" : "11px"}` }} className="price3">{cleanprice(data1["USDT/TRY"]?.prices.ask, 10, 2).ori} TRY</span></div>
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-06.png" alt="" className="banner-slide-01-image-04" /><span style={{ color: "#5a6372", fontSize: `${window.innerWidth > 768 ? "25px" : "11px"}` }} className="price4">{cleanprice(data1["DOGE/USDT"]?.prices.ask, 10, data1["DOGE/USDT"]?.details.display_decimals).ori} USDT</span></div>
                                                    {/* <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-02.png" alt="" className="banner-slide-01-image-05" /> <span className="price5">6456456</span> </div>
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-09.png" alt="" className="banner-slide-01-image-06" /> <span className="price6">6456456</span> </div> */}
                                                </div>
                                            </div>
                                            <div id="w-node-f8917c6c-ae44-d5eb-1989-8c7ca3611b2c-a4908a08" className="banner-slide-01-image-box">
                                                <div className="banner-slide-01-image-end">
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-07.png" alt="" className="banner-slide-01-image-07" /><span style={{ color: "#5a6372", fontSize: `${window.innerWidth > 768 ? "25px" : "11px"}` }} className="price3">{cleanprice(data1["AVAX/USDT"]?.prices.ask, 10, data1["AVAX/USDT"]?.details.display_decimals).ori} USDT</span></div>
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-16.png" alt="" className="banner-slide-01-image-10" /><span style={{ color: "white", fontSize: `${window.innerWidth > 768 ? "25px" : "11px"}` }} className="price7">{cleanprice(data1["FTVT/USDT"]?.prices.ask, 10, data1["FTVT/USDT"]?.details.display_decimals).ori} USDT</span> </div>
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-05.png" alt="" className="banner-slide-01-image-08" /><span style={{ color: "#5a6372", fontSize: `${window.innerWidth > 768 ? "25px" : "11px"}` }} className="price6">{cleanprice(data1["XRP/USDT"]?.prices.ask, 10, data1["XRP/USDT"]?.details.display_decimals).ori} USDT</span></div>
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-02.png" alt="" className="banner-slide-01-image-02" /><span style={{ color: "#5a6372", fontSize: `${window.innerWidth > 768 ? "25px" : "11px"}` }} className="price5">{cleanprice(data1["ETH/USDT"]?.prices.ask, 10, data1["ETH/USDT"]?.details.display_decimals).ori} USDT</span></div>

                                                    {/*                                                     <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-14.png" alt="" className="banner-slide-01-image-11" /><span className="price3">6456456</span></div>
                                                    <div className="banner-slide-01-image"><img loading="lazy" src="images/koin-banner-15.png" alt="" className="banner-slide-01-image-12" /><span className="price3">6456456</span></div> */}
                                                </div>
                                            </div>
                                            <div id="w-node-_958fd575-2e0e-a2f0-29c6-b547ad4f14b1-a4908a08" className="banner-gradient" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-slide w-slide">
                                <div className="banner-padding">
                                    <div className="banner-container w-container">
                                        <div className="w-layout-grid banner-grid">
                                            <div id="w-node-_1ffb171f-7e74-7049-ad66-e25f0010115b-a4908a08" className="banner-slide-02-text-box">
                                                <div className="banner-slide-02-text-wrapper">
                                                    <div className="banner-slide-02-text">BtcOley Mobile ile dilediğiniz yerden işlem yapın!</div>
                                                    <img style={{ maxWidth: "150px" }} src={qrmobile} />
                                                    <h3 style={{ color: "white" }}>BtcOley uygulamasını indirmek için QR kodunu tarayın.</h3>
                                                    <div className='footer-section__app-store'>
                                                        <Link className='footer-section__app-link' target='_blank' to={'https://play.google.com/store/apps/details?id=com.btcoleymobile2'}>
                                                            <img className='footer-section__app-image' style={{ maxWidth: "140px" }} src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" alt='' />
                                                        </Link>
                                                        <Link className='footer-section__app-link' to={''}>
                                                            <img className='footer-section__app-image' src='https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg' alt='' />
                                                        </Link>
                                                    </div>

                                                </div>
                                            </div>
                                            <div id="w-node-_5739c405-5bd0-1565-b3b1-c5e1fac975be-a4908a08" className="banner-slide-02-image-box">
                                                <div className="banner-slide-02-image-start">
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-01-p-500.png 500w, images/btc-mobile-gorsel-01.png 610w" loading="lazy" src="images/btc-mobile-gorsel-01.png" alt="" className="banner-slide-02-image-01" /></div>
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-02-p-500.png 500w, images/btc-mobile-gorsel-02.png 610w" loading="lazy" src="images/btc-mobile-gorsel-02.png" alt="" className="banner-slide-02-image-02" /></div>
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-03-p-500.png 500w, images/btc-mobile-gorsel-03.png 610w" loading="lazy" src="images/btc-mobile-gorsel-03.png" alt="" className="banner-slide-02-image-03" /></div>
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-04-p-500.png 500w, images/btc-mobile-gorsel-04.png 610w" loading="lazy" src="images/btc-mobile-gorsel-04.png" alt="" className="banner-slide-02-image-04" /></div>
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-05-p-500.png 500w, images/btc-mobile-gorsel-05.png 610w" loading="lazy" src="images/btc-mobile-gorsel-05.png" alt="" className="banner-slide-02-image-05" /></div>
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-01-p-500.png 500w, images/btc-mobile-gorsel-06.png 610w" loading="lazy" src="images/btc-mobile-gorsel-06.png" alt="" className="banner-slide-02-image-06" /></div>
                                                </div>
                                            </div>
                                            <div id="w-node-cd530bc8-4369-dab0-5a75-c8c0fd81b0e1-a4908a08" className="banner-slide-02-image-box">
                                                <div className="banner-slide-02-image-end">
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-01-p-500.png 500w, images/btc-mobile-gorsel-01.png 610w" loading="lazy" src="images/btc-mobile-gorsel-01.png" alt="" className="banner-slide-02-image-07" /></div>
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-02-p-500.png 500w, images/btc-mobile-gorsel-02.png 610w" loading="lazy" src="images/btc-mobile-gorsel-02.png" alt="" className="banner-slide-02-image-08" /></div>
                                                    <div className="banner-slide-01-image"><img sizes="(max-width: 479px) 81vw, (max-width: 767px) 88vw, 87vw" srcSet="images/btc-mobile-gorsel-03-p-500.png 500w, images/btc-mobile-gorsel-03.png 610w" loading="lazy" src="images/btc-mobile-gorsel-03.png" alt="" className="banner-slide-02-image-09" /></div>
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-04-p-500.png 500w, images/btc-mobile-gorsel-04.png 610w" loading="lazy" src="images/btc-mobile-gorsel-04.png" alt="" className="banner-slide-02-image-10" /></div>
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-06-p-500.png 500w, images/btc-mobile-gorsel-06.png 610w" loading="lazy" src="images/btc-mobile-gorsel-01.png" alt="" className="banner-slide-02-image-11" /></div>
                                                    <div className="banner-slide-02-image"><img sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, 93vw" srcSet="images/btc-mobile-gorsel-05-p-500.png 500w, images/btc-mobile-gorsel-05.png 610w" loading="lazy" src="images/btc-mobile-gorsel-06.png" alt="" className="banner-slide-02-image-12" /></div>
                                                </div>
                                            </div>
                                            <div id="w-node-_13e985be-861c-2ae7-daa2-0be572fd4216-a4908a08" className="banner-gradient" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="left-arrow-3 w-slider-arrow-left">
                            <div className="w-icon-slider-left" />
                        </div>
                        <div className="right-arrow-3 w-slider-arrow-right">
                            <div className="w-icon-slider-right" />
                        </div>
                        <div className="slide-nav-3 w-slider-nav w-round" />
                    </div>
                </div>
                <div className="information-section wf-section">
                    <div className="information-padding">
                        <div className="information-container w-container">
                            <div className="information-content">
                                <div className="w-layout-grid information-content-grid">
                                    <div id="w-node-_86252be6-8583-6979-ebed-e1c1d11ab7f0-a4908a08" className="information-content-background">
                                        <div className="information-content-padding">
                                            <div className="information-content-wrapper">
                                                <div className="information-content-icon-wrapper"><img src="images/yuksek-veri-guvenligi.png" loading="lazy" alt="" className="information-content-icon-01" /></div>
                                                <div className="information-content-heading"> Yüksek Veri Güvenliği</div>
                                                <div className="information-content-text">Kesintisiz erişim, eşsiz tesis yapısı&nbsp;ve &nbsp;tüm altyapısı Türkiye'de yer alan hizmet sağlayıcımız ile bilgilerinizi güvence altında tutuyoruz.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-_86252be6-8583-6979-ebed-e1c1d11ab7f9-a4908a08" className="information-content-background">
                                        <div className="information-content-padding">
                                            <div className="information-content-wrapper">
                                                <div className="information-content-icon-wrapper"><img src="images/surdurulebilir-is-modeli.png" loading="lazy" alt="" className="information-content-icon-02" /></div>
                                                <div className="information-content-heading">Sürdürülebilir İş Modeli</div>
                                                <div className="information-content-text">Belirli bir iş modeline sahip ve dahil olduğu ekosistem içerisinde hem yatırım yapıp hem de ödeme aracı olarak kullanabileceğiniz tokenlar.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-_86252be6-8583-6979-ebed-e1c1d11ab802-a4908a08" className="information-content-background">
                                        <div className="information-content-padding">
                                            <div className="information-content-wrapper">
                                                <div className="information-content-icon-wrapper"><img src="images/cok-katmanli-guvenlik.png" loading="lazy" alt="" className="information-content-icon-03" /></div>
                                                <div className="information-content-heading">Çok Katmanlı Güvenlik</div>
                                                <div className="information-content-text">Yatırımlarınızın %99'unun soğuk cüzdanlarda saklandığı, çok katmanlı cüzdan güvenliği ile &nbsp;yatırımlarınızı güvence altında tutuyoruz.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="markets-section wf-section">
                    <div className="markets-padding">
                        <div className="markets-container w-container">
                            <div className="markets-heading-wrapper">
                                <div data-w-id="58d75261-8c8b-a2b8-351d-b06331f0865d" className="orange-circle-wrapper">
                                    <div style={{ WebkitTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="orange-left-circle" />
                                    <div className="orange-middle-circle" />
                                    <div style={{ WebkitTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="orange-right-circle" />
                                </div>
                                <div className="markets-heading">Piyasalar</div>
                                <div className="markets-spot">Dünya fiyatlarında, hızlı kripto para alıp satmak için hemen kayıt olun...</div>
                            </div>
                            <div className="markets-table-content">
                                <div className="markets-table-background">
                                    <div className="w-layout-grid markets-table-heading-grid">
                                        <div id="w-node-_9ba01ad8-2b06-3db2-1db7-a19480feb677-a4908a08" className="markets-table-heading-box-01">
                                            <div className="markets-table-heading-text-01">İşlem Çifti</div>
                                        </div>
                                        <div id="w-node-_23c6307a-c16c-3957-2ece-50f7a90e6ca2-a4908a08" className="markets-table-heading-box-02">
                                            <div className="markets-table-heading-text-02">Son Fiyat</div>
                                        </div>
                                        <div id="w-node-_8d681311-3d42-2cf9-6047-7aa77ef5173f-a4908a08" className="markets-table-heading-box-03">
                                            <div className="markets-table-heading-text-03">Hacim</div>
                                        </div>
                                        <div id="w-node-_3e69bd5a-2430-e1d7-e0bb-e1d13ce2163a-a4908a08" className="markets-table-heading-box-04">
                                            <div className="markets-table-heading-text-04">Değişim</div>
                                        </div>
                                    </div>
                                    <div className="markets-table-line" />
                                    <a href="https://pro.btcoley.com/market/BTC_USDT" target="_blank" className="markets-table-content-link w-inline-block">
                                        <div className="w-layout-grid markets-table-content-grid">
                                            <div id="w-node-_8ddd5bc3-1da2-3262-5382-4549049de764-a4908a08" className="markets-table-content-box-01">
                                                <div className="markets-table-content-icon"><img src="images/koin-icon-01.svg" loading="lazy" alt="" className="image-4" /></div>
                                                <div className="markets-table-content-text-01">Bitcoin</div>
                                            </div>
                                            <div id="w-node-_8ddd5bc3-1da2-3262-5382-4549049de767-a4908a08" className="markets-table-content-box-02">
                                                <div className="markets-table-heading-mobile">Son Fiyat</div>
                                                <div className="markets-table-content-text-02 " id="btc">-</div>
                                            </div>
                                            <div id="w-node-_8ddd5bc3-1da2-3262-5382-4549049de76a-a4908a08" className="markets-table-content-box-03">
                                                <div className="markets-table-heading-mobile">Hacim</div>
                                                <div className="markets-table-content-text-03" id="btcV">-</div>
                                            </div>
                                            <div id="w-node-_8ddd5bc3-1da2-3262-5382-4549049de76d-a4908a08" className="markets-table-content-box-04">
                                                <div className="markets-table-content-change-background" id="btcBg">
                                                    <div className="markets-table-content-change-text" id="btcC">-</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="markets-table-line" />
                                    <a href="https://pro.btcoley.com/market/ETH_USDT" target="_blank" className="markets-table-content-link w-inline-block">
                                        <div className="w-layout-grid markets-table-content-grid">
                                            <div id="w-node-_12641f20-db50-2d61-3b24-913f4a01e2fb-a4908a08" className="markets-table-content-box-01">
                                                <div className="markets-table-content-icon"><img src="images/koin-icon-02.svg" loading="lazy" alt="" className="image-4" /></div>
                                                <div className="markets-table-content-text-01">Ethereum</div>
                                            </div>
                                            <div id="w-node-_12641f20-db50-2d61-3b24-913f4a01e300-a4908a08" className="markets-table-content-box-02">
                                                <div className="markets-table-heading-mobile">Son Fiyat</div>
                                                <div className="markets-table-content-text-02" id="eth">-</div>
                                            </div>
                                            <div id="w-node-_12641f20-db50-2d61-3b24-913f4a01e305-a4908a08" className="markets-table-content-box-03">
                                                <div className="markets-table-heading-mobile">Hacim</div>
                                                <div className="markets-table-content-text-03" id="ethV">-</div>
                                            </div>
                                            <div id="w-node-_12641f20-db50-2d61-3b24-913f4a01e30a-a4908a08" className="markets-table-content-box-04">
                                                <div className="markets-table-content-change-background" id="ethBg">
                                                    <div className="markets-table-content-change-text" id="ethC">-</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="markets-table-line" />
                                    {/*  <a href="#" class="markets-table-content-link w-inline-block">
                <div class="w-layout-grid markets-table-content-grid">
                  <div id="w-node-e054a05f-f922-552d-f0a7-641a3bba4186-a4908a08" class="markets-table-content-box-01">
                    <div class="markets-table-content-icon"><img src="images/koin-icon-03.svg" loading="lazy" alt="" class="image-4"></div>
                    <div class="markets-table-content-text-01">Tether</div>
                  </div>
                  <div id="w-node-e054a05f-f922-552d-f0a7-641a3bba418b-a4908a08" class="markets-table-content-box-02">
                    <div class="markets-table-heading-mobile">Son Fiyat</div>
                    <div class="markets-table-content-text-02">10,173377 USDT</div>
                  </div>
                  <div id="w-node-e054a05f-f922-552d-f0a7-641a3bba4190-a4908a08" class="markets-table-content-box-03">
                    <div class="markets-table-heading-mobile">Hacim</div>
                    <div class="markets-table-content-text-03">14.151.348,24</div>
                  </div>
                  <div id="w-node-e054a05f-f922-552d-f0a7-641a3bba4195-a4908a08" class="markets-table-content-box-04">
                    <div class="markets-table-content-change-background">
                      <div class="markets-table-content-change-text">%0.93</div>
                    </div>
                  </div>
                </div>
              </a>*/}
                                    <div className="markets-table-line" />
                                    <a href="https://pro.btcoley.com/market/ADA_USDT" target="_blank" className="markets-table-content-link w-inline-block">
                                        <div className="w-layout-grid markets-table-content-grid">
                                            <div id="w-node-_5547efeb-5feb-fc27-f852-935c4ae3f496-a4908a08" className="markets-table-content-box-01">
                                                <div className="markets-table-content-icon"><img src="images/koin-icon-05.svg" loading="lazy" alt="" className="image-4" /></div>
                                                <div className="markets-table-content-text-01">Cardano</div>
                                            </div>
                                            <div id="w-node-_5547efeb-5feb-fc27-f852-935c4ae3f49b-a4908a08" className="markets-table-content-box-02">
                                                <div className="markets-table-heading-mobile">Son Fiyat</div>
                                                <div className="markets-table-content-text-02" id="ada">-</div>
                                            </div>
                                            <div id="w-node-_5547efeb-5feb-fc27-f852-935c4ae3f4a0-a4908a08" className="markets-table-content-box-03">
                                                <div className="markets-table-heading-mobile">Hacim</div>
                                                <div className="markets-table-content-text-03" id="adaV">-</div>
                                            </div>
                                            <div id="w-node-_5547efeb-5feb-fc27-f852-935c4ae3f4a5-a4908a08" className="markets-table-content-box-04">
                                                <div className="markets-table-content-change-background" id="adaBg">
                                                    <div className="markets-table-content-change-text" id="adaC">-</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="markets-table-line" />
                                    <a href="https://pro.btcoley.com/market/XRP_USDT" target="_blank" className="markets-table-content-link w-inline-block">
                                        <div className="w-layout-grid markets-table-content-grid">
                                            <div id="w-node-_0128037b-7451-f033-c1f3-c5e206d66706-a4908a08" className="markets-table-content-box-01">
                                                <div className="markets-table-content-icon"><img src="images/koin-icon-06.svg" loading="lazy" alt="" className="image-4" /></div>
                                                <div className="markets-table-content-text-01">Ripple</div>
                                            </div>
                                            <div id="w-node-_0128037b-7451-f033-c1f3-c5e206d6670b-a4908a08" className="markets-table-content-box-02">
                                                <div className="markets-table-heading-mobile">Son Fiyat</div>
                                                <div className="markets-table-content-text-02" id="xrp">-</div>
                                            </div>
                                            <div id="w-node-_0128037b-7451-f033-c1f3-c5e206d66710-a4908a08" className="markets-table-content-box-03">
                                                <div className="markets-table-heading-mobile">Hacim</div>
                                                <div className="markets-table-content-text-03" id="xrpV">-</div>
                                            </div>
                                            <div id="w-node-_0128037b-7451-f033-c1f3-c5e206d66715-a4908a08" className="markets-table-content-box-04">
                                                <div className="markets-table-content-change-background" id="xrpBg">
                                                    <div className="markets-table-content-change-text" id="xrpC">-</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="markets-table-line" />
                                    <a href="https://pro.btcoley.com/market/FTVT_USDT" target="_blank" className="markets-table-content-link w-inline-block">
                                        <div className="w-layout-grid markets-table-content-grid">
                                            <div id="w-node-b20687a3-2571-ddf9-15cd-cb0d98e5cee4-a4908a08" className="markets-table-content-box-01">
                                                <div className="markets-table-content-icon"><img src="images/ftvt.png" loading="lazy" alt="" className="image-4" /></div>
                                                <div className="markets-table-content-text-01">FTVT Token</div>
                                            </div>
                                            <div id="w-node-b20687a3-2571-ddf9-15cd-cb0d98e5cee9-a4908a08" className="markets-table-content-box-02">
                                                <div className="markets-table-heading-mobile">Son Fiyat</div>
                                                <div className="markets-table-content-text-02" id="ftvt">-</div>
                                            </div>
                                            <div id="w-node-b20687a3-2571-ddf9-15cd-cb0d98e5ceee-a4908a08" className="markets-table-content-box-03">
                                                <div className="markets-table-heading-mobile">Hacim</div>
                                                <div className="markets-table-content-text-03" id="ftvtV">-</div>
                                            </div>
                                            <div id="w-node-b20687a3-2571-ddf9-15cd-cb0d98e5cef3-a4908a08" className="markets-table-content-box-04">
                                                <div className="markets-table-content-change-background" id="ftvtBg">
                                                    <div className="markets-table-content-change-text" id="ftvtC">-</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="markets-table-button-wrapper">
                                <a href="https://pro.btcoley.com/market" target="_blank" className="orange-white-button w-inline-block">
                                    <div>Hepsini Gör</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="coin-icon-section wf-section">
                    <div className="coin-icon-padding">
                        <div className="coin-icon-container w-container">
                            <div className="coin-icon-content">
                                <div className="w-layout-grid coin-icon-grid">
                                    <div id="w-node-cff235d4-f95c-09c6-1cfd-46e2e6aaa706-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _01"><img src="images/koin-icon-19.svg" loading="lazy" id="w-node-_12c88123-1941-07d2-ef70-7ed769b729b3-a4908a08" alt="" className="coin-icon-01" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-d6ed4479-ff96-8671-cff4-2cd32c6d44c1-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _02"><img src="images/koin-icon-18.svg" loading="lazy" id="w-node-d6ed4479-ff96-8671-cff4-2cd32c6d44c3-a4908a08" alt="" className="coin-icon-02" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-e8936b51-7187-be67-8175-e55824e58322-a4908a08" className="coin-icon-box ">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _03 bg-dark"><img src="images/ftvt.png" loading="lazy" id="w-node-e8936b51-7187-be67-8175-e55824e58324-a4908a08" alt="" className="coin-icon-03" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-b9cc788b-1e7e-31cb-cec2-bf124570535a-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _04"><img src="images/koin-icon-17.svg" loading="lazy" id="w-node-b9cc788b-1e7e-31cb-cec2-bf124570535c-a4908a08" alt="" className="coin-icon-04" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_40df0918-8d06-022d-490f-5100d033b035-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _05"><img src="images/koin-icon-13.svg" loading="lazy" id="w-node-_40df0918-8d06-022d-490f-5100d033b037-a4908a08" alt="" className="coin-icon-05" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_2959714d-a531-a041-f398-3f22e3b71a27-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _06"><img src="images/koin-icon-14.svg" loading="lazy" id="w-node-_2959714d-a531-a041-f398-3f22e3b71a29-a4908a08" alt="" className="coin-icon-06" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_88d3b34f-1848-ad4b-ffbd-add2004613af-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _07"><img src="images/koin-icon-12.svg" loading="lazy" id="w-node-_88d3b34f-1848-ad4b-ffbd-add2004613b1-a4908a08" alt="" className="coin-icon-07" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-ee833a47-fd65-9f2a-7fdd-5e8e590ef618-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _08"><img src="images/koin-icon-10.svg" loading="lazy" id="w-node-ee833a47-fd65-9f2a-7fdd-5e8e590ef61a-a4908a08" alt="" className="coin-icon-08" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-acbb1e19-0f86-adc2-4038-2b5a205e5360-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _09"><img src="images/koin-icon-02.svg" loading="lazy" id="w-node-acbb1e19-0f86-adc2-4038-2b5a205e5362-a4908a08" alt="" className="coin-icon-09" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_5f4bce61-9372-ffba-d2ab-b0f72741adad-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _10"><img src="images/koin-icon-15.svg" loading="lazy" id="w-node-_5f4bce61-9372-ffba-d2ab-b0f72741adaf-a4908a08" alt="" className="coin-icon-10" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_74422a25-e7c7-5247-ba5d-c5bb26872881-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _11"><img src="images/koin-icon-08.svg" loading="lazy" id="w-node-_74422a25-e7c7-5247-ba5d-c5bb26872883-a4908a08" alt="" className="coin-icon-11" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_39c1da00-d133-4bba-8078-c4a43382860f-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _12"><img src="images/koin-icon-04.svg" loading="lazy" id="w-node-_39c1da00-d133-4bba-8078-c4a433828611-a4908a08" alt="" className="coin-icon-12" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-b5d48e1a-39de-a3d2-8147-28b75d9a4f10-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _13"><img src="images/koin-icon-16.svg" loading="lazy" id="w-node-b5d48e1a-39de-a3d2-8147-28b75d9a4f12-a4908a08" alt="" className="coin-icon-13" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-e2dffc6b-7200-3417-45be-e6f2d66c603e-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _14"><img src="images/koin-icon-11.svg" loading="lazy" id="w-node-e2dffc6b-7200-3417-45be-e6f2d66c6040-a4908a08" alt="" className="coin-icon-14" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_939b3078-e774-6454-3812-cc6ddc2fa008-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _15"><img src="images/koin-icon-05.svg" loading="lazy" id="w-node-_939b3078-e774-6454-3812-cc6ddc2fa00a-a4908a08" alt="" className="coin-icon-15" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_8b88eb30-a5b1-ca47-0f75-384ba3c1121d-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _16"><img src="images/koin-icon-01.svg" loading="lazy" id="w-node-_8b88eb30-a5b1-ca47-0f75-384ba3c1121f-a4908a08" alt="" className="coin-icon-16" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_9e094e2f-2648-6b19-8199-a4b25794fef1-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _17"><img src="images/koin-icon-07.svg" loading="lazy" id="w-node-_9e094e2f-2648-6b19-8199-a4b25794fef3-a4908a08" alt="" className="coin-icon-17" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-bc718389-d2b9-cf5a-9d73-014cc1f78d15-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _18"><img src="images/koin-icon-03.svg" loading="lazy" id="w-node-bc718389-d2b9-cf5a-9d73-014cc1f78d17-a4908a08" alt="" className="coin-icon-18" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_3f89c4cc-f658-d478-c3d3-66de4f1c938c-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _19"><img src="images/koin-icon-09.svg" loading="lazy" id="w-node-_3f89c4cc-f658-d478-c3d3-66de4f1c938e-a4908a08" alt="" className="coin-icon-19" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                    <div id="w-node-_5d870c15-eeee-2625-1898-aec1813d76f9-a4908a08" className="coin-icon-box">
                                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="coin-icon _20"><img src="images/koin-icon-06.svg" loading="lazy" id="w-node-_5d870c15-eeee-2625-1898-aec1813d76fb-a4908a08" alt="" className="coin-icon-20" /></div>
                                        <div className="coin-icon-dot-wrapper">
                                            <div className="coin-icon-dot" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btcoley-section wf-section">
                    <div className="btcoley-padding">
                        <div className="btcoley-container w-container">
                            <div className="btcoley-heading-wrapper">
                                <div data-w-id="6ba8b396-d2ed-a487-7857-97f4e3352a02" className="orange-circle-wrapper">
                                    <div style={{ WebkitTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="orange-left-circle" />
                                    <div className="orange-middle-circle" />
                                    <div style={{ WebkitTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="orange-right-circle" />
                                </div>
                                <div className="btcoley-heading">Neden BtcOley?</div>
                                <div className="btcoley-spot">BtcOley ile listelenen ve devamlı artan tüm kripto para çiftlerinde yüksek likidite imkanı... </div>
                            </div>
                            <div className="btcoley-content">
                                <div className="w-layout-grid btcoley-content-grid">
                                    <div id="w-node-f7049173-9479-42d0-f52d-39b71ec25889-a4908a08" className="btcoley-content-background">
                                        <div className="btcoley-content-padding">
                                            <div className="btcoley-content-wrapper">
                                                <div className="btcoley-content-icon-wrapper"><img src="images/kolay-ve-hızlı.png" loading="lazy" alt="" className="btcoley-content-icon-01" /></div>
                                                <div className="btcoley-content-heading">Kolay ve Hızlı</div>
                                                <div className="btcoley-content-text">Sade ve kullanıcı dostu arayüzü ile kullanıcılarımız için kolay ve hızlı bir deneyim sunuyoruz.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-ddaeb38e-eada-7483-3b06-711d6a553a93-a4908a08" className="btcoley-content-background">
                                        <div className="w-layout-grid btcoley-content-grid-2">
                                            <div className="btcoley-content-padding">
                                                <div className="btcoley-content-wrapper">
                                                    <div className="btcoley-content-icon-wrapper"><img src="images/Btc-Oley-Visa-Kart.png" loading="lazy" alt="" className="btcoley-content-icon-02" /></div>
                                                    <div className="btcoley-content-heading">BtcOley Visa Kart</div>
                                                    <div className="btcoley-content-text">Günlük harcamalarınızı kripto para ile yapmanızı sağlayacak &nbsp;BtcOley Visa Kartımız çok yakında...</div>
                                                </div>
                                            </div>
                                            <div className="btcoley-content-image-size">
                                                <div className="btcoley-content-image-wrapper"><img src="images/btcoley-kredi-karti.jpg" loading="lazy" srcSet="images/ images/btcoley-kredi-karti-p-2000.jpg 2000w, images/btcoley-kredi-karti-p-2600.jpg 2600w, images/btcoley-kredi-karti.jpg 3000w" sizes="(max-width: 479px) 82vw, (max-width: 767px) 92vw, (max-width: 991px) 93vw, 90vw" alt="" className="image-14" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-a022ee2b-ee56-b566-d6b5-e9ca99fd55e6-a4908a08" className="btcoley-content-background">
                                        <div className="btcoley-content-padding">
                                            <div className="btcoley-content-wrapper">
                                                <div className="btcoley-content-icon-wrapper"><img src="images/cok-yonlu-finansman.png" loading="lazy" alt="" className="btcoley-content-icon-03" /></div>
                                                <div className="btcoley-content-heading">Çok Yönlü Finansman</div>
                                                <div className="btcoley-content-text">Yasal uyumluluk ve güvenlik standartlarında çok yönlü finansman seçenekleri sunuyoruz.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-_07a71055-7280-4194-2fb6-33947d066193-a4908a08" className="btcoley-content-background">
                                        <div className="btcoley-content-padding">
                                            <div className="btcoley-content-wrapper">
                                                <div className="btcoley-content-icon-wrapper"><img src="images/yuksek-likidite.png" loading="lazy" alt="" className="btcoley-content-icon-04" /></div>
                                                <div className="btcoley-content-heading">Yüksek Likidite</div>
                                                <div className="btcoley-content-text">Listelenen ve devamlı artan tüm kripto para çiftlerinde yüksek likidite imkanı sağlıyoruz.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-d465903a-6c1d-85f7-6767-ae9dc169c559-a4908a08" className="btcoley-content-background">
                                        <div className="btcoley-content-padding">
                                            <div className="btcoley-content-wrapper">
                                                <div className="btcoley-content-icon-wrapper"><img src="images/7-24-canlı-destek.png" loading="lazy" alt="" className="security-content-icon-05" /></div>
                                                <div className="btcoley-content-heading">7/24 Canlı Destek</div>
                                                <div className="btcoley-content-text">Yardıma ihtiyaç duyduğunuzda, 7/24 canlı destek hizmetimizle her an yanınızdayız.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-section wf-section">
                    <div className="mobile-padding">
                        <div className="mobile-container w-container">
                            <div className="mobile-content">
                                <div className="mobile-content-background">
                                    <div className="mobile-content-padding">
                                        <div className="w-layout-grid mobile-content-grid">
                                            <div id="w-node-_00b44874-82be-47f1-2066-5f5dd419a31a-a4908a08">
                                                <div className="mobile-content-card">
                                                    <div data-w-id="fd9f4a4b-7c4f-9bfb-598e-e25003f75ab5" className="white-circle-wrapper tablet">
                                                        <div style={{ WebkitTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="white-left-circle" />
                                                        <div className="white-middle-circle" />
                                                        <div style={{ WebkitTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="white-right-circle" />
                                                    </div>
                                                    <div data-w-id="3fec5dd5-5c74-ca82-c35b-f1d479c6932d" className="blue-circle-wrapper mobile">
                                                        <div style={{ WebkitTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(10) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="blue-left-circle" />
                                                        <div className="blue-middle-circle" />
                                                        <div style={{ WebkitTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="blue-right-circle" />
                                                    </div>
                                                    <div className="mobile-content-heading">BtcOley Mobile</div>
                                                    <div className="mobile-content-spot">Sade ve kullanıcı dostu arayüzüne sahip mobil uygulamamızla, dilediğiniz yerden, dilediğiniz zaman işlem yapın.</div>
                                                    <div className="mobile-content-card-button-wrapper">
                                                        <a href="https://apps.apple.com/tr/app/btcoley/id6451135431?l=tr" className="mobile-content-card-button-left w-inline-block" target="_blank">
                                                            <div>IOS</div>
                                                        </a>
                                                        <a href="https://play.google.com/store/apps/details?id=com.btcoleymobile2" className="mobile-content-card-button-right w-inline-block" target="_blank">
                                                            <div>Android</div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mobile-image-position">
                                        <div className="mobile-image-padding"><img src="images/mobile.jpg" loading="lazy" srcSet="images/mobile.jpg 500w, images/mobile.jpg 800w, images/mobile.jpg 1080w, images/mobile.jpg 1600w, images/mobile.jpg 2000w, images/mobile.jpg 2600w, images/mobile.jpg 3000w" sizes="(max-width: 767px) 100vw, 93vw" alt="" className="image-15" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="security-section wf-section">
                    <div className="security-padding">
                        <div className="security-container w-container">
                            <div className="security-heading-wrapper">
                                <div data-w-id="eb6c70bf-2ac7-6c13-a82c-d7f19aef90e4" className="white-circle-wrapper">
                                    <div style={{ WebkitTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="white-left-circle" />
                                    <div className="white-middle-circle" />
                                    <div style={{ WebkitTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="white-right-circle" />
                                </div>
                                <div className="security-heading">Gelişmiş Güvenlik</div>
                                <div className="security-spot">Bilgi güvenliğinden, yatırım güvenliğine uzanan süreçte size özel güvenlik çözümleri...</div>
                            </div>
                            <div className="security-content">
                                <div className="w-layout-grid security-content-grid">
                                    <div id="w-node-eb6c70bf-2ac7-6c13-a82c-d7f19aef90ee-a4908a08" className="security-content-background">
                                        <div className="security-content-padding">
                                            <div className="security-content-wrapper">
                                                <div className="security-content-icon-wrapper"><img src="images/bilgi-guvenligi.png" loading="lazy" alt="" className="security-content-icon-01" /></div>
                                                <div className="security-content-heading">Bilgi Güvenliği</div>
                                                <div className="security-content-text">6698 sayılı Kişisel Verilerin Korunması Kanununa tam uyumlu otomasyonu ve Veri saklama merkezi ile kişisel verileriniz güvende tutuyoruz.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-eb6c70bf-2ac7-6c13-a82c-d7f19aef9101-a4908a08" className="security-content-background">
                                        <div className="security-content-padding">
                                            <div className="security-content-wrapper">
                                                <div className="security-content-icon-wrapper"><img src="images/sistem-guvenligi.png" loading="lazy" alt="" className="security-content-icon-02" /></div>
                                                <div className="security-content-heading">Sistem Güvenliği</div>
                                                <div className="security-content-text">Gelişmiş güvenlik katmanı ve özel konfigüre edilmiş güvenlik duvarımız ile yatırımcılarımıza güven veren bir mimari yapıya sahibiz.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="security-content-background">
                                        <div className="security-content-padding">
                                            <div className="security-content-wrapper">
                                                <div className="security-content-icon-wrapper"><img src="images/yatirim-guvenligi.png" loading="lazy" alt="" className="security-content-icon-03" /></div>
                                                <div className="security-content-heading">Yatırım Güvenliği</div>
                                                <div className="security-content-text">Cüzdanlarınıza gelen ya da satın aldığınız kriptolarınızı yüksek güvenlikli cüzdan servislerimizde muhafaza ederek yatırımlarınızı güvende tutuyoruz.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="security-background"><img src="images/guvenlik-zemin.svg" loading="lazy" alt="" className="image-11" /></div>
                </div>
                <div className="wallet-services-section wf-section">
                    <div className="wallet-services-padding">
                        <div className="wallet-services-container w-container">
                            <div className="wallet-services-heading-wrapper">
                                <div data-w-id="6321eb05-e429-ac91-e3b5-388fbefe46d3" className="blue-circle-wrapper">
                                    <div style={{ WebkitTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="blue-left-circle" />
                                    <div className="blue-middle-circle" />
                                    <div style={{ WebkitTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(-5px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="blue-right-circle" />
                                </div>
                                <div className="wallet-services-heading">Cüzdan Servisleri</div>
                                <div className="wallet-services-spot">Tamamı Türk mühendisleri tarafından tasarlanan mimarisi ile BtcOley Cüzdan servislerimiz...</div>
                            </div>
                            <div className="wallet-services-content">
                                <div className="w-layout-grid wallet-services-content-grid">
                                    <div id="w-node-_6321eb05-e429-ac91-e3b5-388fbefe46dd-a4908a08" className="wallet-services-content-background">
                                        <div className="wallet-services-content-padding">
                                            <div className="wallet-services-content-wrapper">
                                                <div className="wallet-services-content-icon-wrapper"><img src="images/blockchain-destegi.png" loading="lazy" alt="" className="wallet-services-content-icon-01" /></div>
                                                <div className="wallet-services-content-heading">Blockchain ile Mutabakat </div>
                                                <div className="wallet-services-content-text">Blockchain ağı sisteminin nasıl çalışması gerektiğini belirleyen çözümler üretirken, sistemin tüm farklı parçalarının ve altta yatan protokol kurallarının ağdaki tüm katılımcıların takip etmesini sağlayan yapılar inşaa ediyoruz.</div>
                                            </div>
                                        </div>
                                        <div className="wallet-services-content-image-wrapper">
                                            <div className="wallet-services-content-image-radius"><img src="images/blockchain-ile-mutabakat.jpg" loading="lazy" srcSet="images/blockchain-ile-mutabakat-p-500.jpg 500w, images/blockchain-ile-mutabakat-p-800.jpg 800w, images/blockchain-ile-mutabakat-p-1080.jpg 1080w, images/blockchain-ile-mutabakat-p-1600.jpg 1600w, images/blockchain-ile-mutabakat-p-2000.jpg 2000w, images/blockchain-ile-mutabakat-p-2600.jpg 2600w, images/blockchain-ile-mutabakat.jpg 3000w" sizes="100vw" alt="" className="wallet-services-content-image-01" /></div>
                                        </div>
                                    </div>
                                    <div id="w-node-_6321eb05-e429-ac91-e3b5-388fbefe46e6-a4908a08" className="wallet-services-content-background">
                                        <div className="wallet-services-content-padding">
                                            <div className="wallet-services-content-wrapper">
                                                <div className="wallet-services-content-icon-wrapper"><img src="images/guvenlik-algoritmalari.png" loading="lazy" alt="" className="wallet-services-content-icon-02" /></div>
                                                <div className="wallet-services-content-heading">Blockchain ile Ödeme </div>
                                                <div className="wallet-services-content-text">Blockchain teknolojisi, muhasebe, denetim sistemleri, ödeme sistemleri ve kripto varlıkların kullanım alanlarına göre finansal tablolarda hangi hesap grubu altında raporlanması gerektiğine kadar detaylı çalışmalar sunmaktayız.</div>
                                            </div>
                                        </div>
                                        <div className="wallet-services-content-image-wrapper">
                                            <div className="wallet-services-content-image-radius"><img src="images/blockchain-ile-odeme.jpg" loading="lazy" srcSet="images/blockchain-ile-odeme-p-500.jpg 500w, images/blockchain-ile-odeme-p-800.jpg 800w, images/blockchain-ile-odeme-p-1080.jpg 1080w, images/blockchain-ile-odeme-p-1600.jpg 1600w, images/blockchain-ile-odeme-p-2000.jpg 2000w, images/blockchain-ile-odeme-p-2600.jpg 2600w, images/blockchain-ile-odeme.jpg 3005w" sizes="100vw" alt="" className="wallet-services-content-image-01" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {confirm === null ? <div className="navbar611 ">

                    <span style={{ padding: "10px", display: "flex", color: "white", fontSize: "15px" }}>
                        KVKK’nın Veri Sorumlusunun Aydınlatma Yükümlülüğü başlıklı 10. maddesi uyarınca, Çerez Politikamızda belirtilen amaçlarla sınırlı olarak, mevzuata uygun çerezler (cookies) kullanıyoruz.  <a style={{ color: "black" }} href="/cookies">  Daha Fazla Bilgi</a>

                    </span>
                    <span onClick={() => {

                        setConfirm(true);

                        localStorage.setItem("cookies", confirm);

                    }} style={{ cursor: "pointer", backgroundColor: "#4590ce", paddingTop: "3px", paddingLeft: "15px", paddingRight: "15px", borderRadius: "10px", marginTop: "10px", marginBottom: "10px", color: "white" }}>Anladım</span>

                </div> : null}
            </div>
        </>

    )
}

export default Home