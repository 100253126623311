
const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>Magician Of Meta Teknoloji A.Ş. Kara Para Aklamayı Önleme Politikası (AML)</h4>
                <p>Sözlük anlamı ile Kara Para, yasadışı yollardan sağlanan kazançtır. Söz konusu kazanç, nakdi olabileceği gibi meşru yatırımlara dönüştürme yoluyla da örtbas edilebilmektedir. Kara Para Aklamayı Önleme Politikası Şirket’ in faaliyetlerini 5549 sayılı Suç Gelirlerinin Aklanmasını Önlenmesi Hakkında Kanun ve 6415 Sayılı Terörizmi Finansmanının Önlenmesi Hakkında Kanun başta olmak üzere yürürlükteki mevzuat uyarınca uygulama yöntemlerini belirlemekte ve açıklamaktadır.</p>
                <p>İşbu politika metninde Kara Para Aklama Politikası bundan sonra AML Politikası olarak anılacaktır. AML politikasında Site’ ye üye olan kullanıcı/müşteriler sağlanan hizmet çerçevesinde tabii oldukları kurallar ve uygulamalar yer almaktadır.</p>
                <p>Kara Para Aklama Önleme Politikasının amacı ise ; Magician Of Meta Teknoloji A.Ş. bünyesinde kullanıcının/müşterinin yaptığı veya yapacağı işlemler, alacakları hizmetler açısından riski değerlendirmek, kontrol etmek veya minimalize etmek, suç gelirlerinin aklanmasının ve terörizmin finansmanının önlenmesi hakkında çalışmaların bilinçlendirilmesi ve bilgi edinilmesi ve bu kapsamda Şirket’in faaliyetlerini 5549 Sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun ve 6415 sayılı Terörizmin Finansmanının Önlenmesi Hakkında Kanun başta olmak üzere yürürlükteki mevzuat uyarınca gerçekleştirdiğini kullanıcılara/müşterilere bildirilmesidir.</p>
                <p>BtcOley olarak kullanıcılarımıza/müşterilerimize, kullandıkları hesapların güvenliği açısından en üst özen ve itinayı gösterme hassasiyetindeyiz. Bu bağlamda, kullanıcılarımıza/müşterilerimize sunulacak müşteri hizmetinde kaliteli hizmet prensiplerimizin başında yer almaktadır. Hiçbir nam altında kara aklanmasını sağlayacak faaliyete veya işleyişe müsaade edilmemesini prensip edinmiş olmakla beraber AML politikasını eksiksiz ve tam uygulanacağını ihbar ve ihtar ederiz.</p>
                <p>BtcOley, AML Politikası’ nı yürürlükteki mevzuata uygun olarak düzenlemiş olup uygulanması aşamasında, platformda yer alan kullanıcıların/müşterilerin tamamının kimliklerini makul düzeye kadar doğrulama, kullanıcıya/müşteriye ait işlemlerin izlenmesi ve işbu izlemenin risk analizinin ayrılmaz bir parçası olmasına, müşteriler tarafından gerçekleştirilen herhangi bir şüpheli işlemi yürürlükteki mevzuat çerçevesinde ilgili kurum ve kuruluşlara bildirme ve işlemleri kaydetme, Şirket nezdinde AML Politikası’ nın icra edilmesi ve uygulanabilirliğini koordine etmek için gerekli organizasyonu sağlama gibi birçok tedbir almıştır.</p>
                <p>İzah edilen çerçevede BtcOLey, suçlular veya teröristlerle iş ilişkilerine girmemek; suç veya terörist faaliyetlerinden kaynaklanan işlemleri işleme koymamak ve suç veya terörist faaliyetlerle ilgili herhangi bir işlemi kolaylaştırmamayı politikası olarak belirlemiş ve üst düzey bunu uygulamayı tüm çalışanları ile prensip edinmiştir.</p>
                <p>Risk Değerlendirilmesi ; BtcOley , Risk Analizi , İzleme ve Kontrol faaliyetleri ile kripto para alım satım Sitesi’ ni risklere karşı korur, izler ve kontrol eder.</p>
                <p>BtcOley, Kullanıcılarının/Müşterilerinin finansal faaliyetlerinin izlenmesinde riske dayalı bir yaklaşım benimseyip suç gelirlerinin aklanması ve terörizmin finansmanını önlemek amacıyla Şirketin kendi bünyesinde belirleyeceği yöntemlerle risk analizi yaparak bu analiz sonucu elde ettiği bilgiler çerçevesinde ilgili Kullanıcıları/Müşterileri izlemeye alabilmektedir.</p>
                <p>BtcOley, ulusal ve uluslararası gereklilikler doğrultusunda, kara para aklama ve terörün finansmanına yönelik risk temelli bir yaklaşım benimser. Böylece, kara para aklama ve terörün finansmanına karşı alınan önlemler, belirlenen risklerle orantılıdır ve kaynakların verimli bir şekilde tahsis edilmesine olanak tanır.</p>
                <p>Kullanıcı/Müşteri işlemlerinin izlenmesi ve elde edilen verilerin analiz edilmesi de risk değerlendirmesi ve şüpheli işlemlerin tespiti için önemli bir araçtır. BtcOley, kara para aklama şüphesi durumunda tüm işlemler için aşağıdaki haklara sahiptir.</p>
                <ol>
                    <li>Şüpheli işlemlerin tespiti halinde MASAK’ a bildirimde bulunulması ,</li>
                    <li>Kullanıcılarda yapılan değerlendirmeye göre yeni ve ekstra evrak, bilgi istenilmesi</li>
                    <li>Müşteri hesabının askıya alınması veya kapatılması ;</li>
                </ol>
                <p>Yukarıdaki sayılanlar yapılacak işlemlerin tamamını kapsamamakla beraber AML Politikası uygulanacak kullanıcıları/müşteriler açısından saatlik , günlük, haftalık ve aylık olarak yapılan müşteri takiplerinde kullanıcının/müşterinin raporlanıp raporlanmadığı noktasında kıstas olarak alınmaktadır.</p>
                <p>BtcOley, kara para aklamayı önleme standartlarını tanımlayarak bu bağlamda AML Politikasına göre kendi süreçlerini oluşturacaktır.</p>
                <p>BtcOley kullanıcıları/müşterileri bir doğrulama prosedürünü tamamlar. BtcOley AML politikasının amaçları için kullanıcılarının/müşterilerinin kimlik bilgilerini toplama hakkını saklı tutar. Bu bilgiler, BtcOley Gizlilik Politikası ve KVKK uyarınca işlenir ve güvenli bir şekilde saklanır. BtcOley , kullanıcı/müşteri kimlik belgesi isteme, herhangi bir şüphe veya tehlike ihtimali halinde yeni evrak isteme veya ek evrak isteme hakkına sahiptir.</p>
                <p>BtcOley’in sunduğu hizmetler kapsamında ,kara para aklama ve terörün finansmanı şüphesi bulunan durumlarda , gerekli incelemeler yapıldıktan sonra şüpheli görünen işlemler , miktarına bakılmaksızın yürürlükteki kanunda tanımlanan Mali Suçları Araştırma Komisyonu’na bildirilecektir. Şüpheli işlemi fiilen gerçekleştirenler ile kanuni temsilcileri, şüpheli işlemi bildirme yükümlülüğüne uymayan yönetici ve çalışanları hakkında her türlü adli, idari ve cezai işlem uygulanacaktır.</p>
                <p>Kara Parayı Aklamayı Önlemeye İlişkin BtcOley bünyesinde departman oluşturarak bu hususta takibi ve denetimi güçlendirmiştir. Kara Parayı Aklamayı Önleme Departmanı, kullanıcıların/müşterilerin kimlik bilgilerini toparlar, yürürlükte olan kanun ve yönetmelikleri göz önünde bulundurarak rapor oluşturur. Yönetime sunmak, istenmesi halinde resmi kurumlar için hazır etmek , müşterilerin olağan dışı işlem hareketlerini izlemek, takip etmek ve benzeri tanımlar görevleri arasında yer almaktadır.</p>
                <p>BtcOley, izah ettiği tüm hususlar çerçevesinde yükümlülüklerini olabilecek en iyi derecede yerine getirmektedir. Bu doğrultuda öncelikle Kara Para Aklanmasının Önlenmesi hususunda iülediği hassas politika gereği çalışanlarına eğitim vermekte olup, tüm bilgi ve eğitim dökumanlarının güncel tutulmasına özen göstermektedir.</p>
            </div>
        </div>
    )
}

export default TabActive;