import btcuzdan from '../images/btccuzdan.png';
const TabActive = () => {
    return (
        <div className="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div className="contents-rich-text-block w-richtext">
                <h4>Kripto Para Cüzdanları ve Türleri</h4>
                <h5>Kripto Para Cüzdanı Nedir?</h5>
                <p>Bir kripto para cüzdanı blockchain ağıyla etkileşim kurmanıza yardımcı olan bir araçtır.Farklı türdeki cüzdanlar üç gruba ayrılabilir: yazılım, donanım ve kağıt cüzdanlar. Çalışma mekanizmalarına bağlı olarak, soğuk ve sıcak cüzdanlar olarak da adlandırılabilirler.</p>
                <p>Kripto cüzdanı sağlayıcılarının büyük kısmı yazılım tabanlıdır, bu da yazılım cüzdanlarını donanım cüzdanlara kıyasla daha kolay kullanımlı hale getirir. Güvenlik açısından donanım cüzdanlarına alternatiftir. Diğer yandan kağıt cüzdanlar, bir kağıda basılmış bir cüzdandan oluşur, fakat artık bu tür cüzdanlar modası geçmiş ve güvenilmez olarak kabul edilmektedir.</p>

                <p>Kripto cüzdanları, Bitcoin’i ve diğer kripto paraları kullanmanın önemli bir parçasıdır. Bu cüzdanlar, blockchain ağından fon almayı ve göndermeyi mümkün kılan altyapının en temel ögelerinden biridir. Her bir cüzdan türünün kendine has avantajları ve dezavantajları vardır. Bu yüzden, fonlarınızı bir cüzdan türüne aktarmadan önce bu cüzdanın nasıl çalıştığını anlamanız önemlidir.</p>
                <h5>Sıcak Cüzdan ve Soğuk Cüzdan</h5>
                <p>Sıcak cüzdan, herhangi bir şekilde internete bağlı olan bir cüzdandır. Platform üzerinde bir hesap açtığınızda ve cüzdanlarınıza fon gönderdiğinizde, platformun sıcak cüzdanına fon yatırıyor olursunuz. Bu cüzdanların kurulumunun oldukça kolay olması ve fonlara hızla erişebilmesi, bu tip cüzdanları alım satım yapan kişiler veya çok fazla fon aktarımı yapan kişiler için oldukça kullanışlıdır.</p>
                <p>Diğer yandan soğuk cüzdanların internet bağlantısı yoktur. Bunun yerine, anahtarları çevrimdışı şekilde saklamak için fiziksel bir araç kullanırlar. Bu da onları çevrimiçi hackeleme girişimlerine karşı dayanıklı hale getirir. Bu yöntem soğuk depolama olarak da bilinir ve uzun vadeli yatırımcılar ya da uzun vadeli yatırımcılar (Hodler) için özellikle kullanışlıdır.</p>
                <h5>Yazılım Cüzdanlar</h5>
                <p>Yazılım cüzdanlarının birçok farklı türü vardır ve her biri de kendine has özelliklere sahiptir. Çoğu bir şekilde internete bağlıdır (sıcak cüzdanlar). En yaygın ve en önemli yazılım cüzdanı türleri; web, masaüstü ve mobil cüzdanlardır.</p>
                <p>Web cüzdanlar; Yeni bir cüzdan yaratabilir ve buna erişmek için kişisel bir parola oluşturabilirsiniz. Bazı hizmet sağlayıcılar sizin yerinize özel anahtarlarınızı tutar ve idare eder. Bu durum deneyimsiz kullanıcılar için daha kullanışlı olsa da, aslında tehlikeli bir uygulamadır. Eğer özel anahtarlarınızı kendi elinizde tutmuyorsanız, paranızı başkasına emanet ediyorsunuz demektir.
                </p>
                <p>Masaüstü cüzdanlar; İsminden de anlaşılacağı gibi bir masaüstü cüzdanı, indirebileceğiniz ve bilgisayarınız üzerinden kullanabileceğiniz bir yazılımdır. Web temelli versiyonların aksine, masaüstü cüzdanları size anahtarlarınızın ve fonlarınızın tüm kontrolünü verir. Yeni bir masaüstü cüzdanı yarattığınızda, "wallet.dat" isimli bir dosya bilgisayarınızın sabit diskine kaydedilir. Bu dosya, kripto para adresinize erişmeniz için gerekli özel anahtar bilgisini içerir, bu yüzden dosyayı kişisel bir parolayla şifrelemeniz gereklidir. Eğer masaüstü cüzdanınızı şifrelerseniz, yazılımı çalıştırdığınız her sefer wallet.dat dosyasının okunabilmesi için parolanızı girmeniz gerekir. Bu dosyayı kaybeder ya da parolanızı unutursanız, muhtemelen fonlarınıza erişiminizi de kaybedersiniz.</p>
                <p>Mobil cüzdanlar; Mobil cüzdanlar masaüstü cüzdanlarla benzer şekilde çalışır; ama akıllı telefon uygulamaları olarak tasarlanmışlardır. Bu cüzdanlar, QR kodları aracılığıyla kripto para göndermenize ve almanıza olanak sağladıkları için oldukça kullanışlıdır. Fakat aynı bilgisayarlar gibi mobil cihazlar da zararlı uygulamalara ve kötü niyetli yazılımlara karşı hassastır. Bu yüzden mobil cüzdanınızı bir parolayla şifrelemeniz ve akıllı telefonunuzun kaybolması ya da bozulması ihtimaline karşı özel anahtarlarınızı (ya da kurtarma cümlenizi) yedeklemeniz önerilir.</p>
                <h5>Donanım Cüzdanlar</h5>
                <p>Donanım cüzdanla, açık ve gizli anahtarları oluşturmak için rastgele sayı üreticileri (RNG) kullanan fiziksel, elektronik cihazlardır. Bu anahtarlar daha sonra internete bağlı olmayan cihazın kendisinde saklanır. Bu nedenle donanım cüzdanları, bir soğuk cüzdan türüdür ve en güvenli seçeneklerden biri olarak kabul edilir.</p>
                <p>Bu cüzdanlar çevrimiçi saldırılara karşı yüksek seviyede güvenlik sağlasa da, donanım yazılımının düzgün şekilde yapılmaması durumunda bazı riskler de barındırırlar. Ayrıca, donanım cüzdanları daha az kullanıcı dostudur ve sıcak cüzdanlara kıyasla fonlara erişim daha zordur.</p>
                <p>Eğer kripto paralarınızı uzun süre tutmayı planlıyorsanız ya da elinizde büyük miktarlarda kripto para tutuyorsanız, bir donanım cüzdanı kullanmayı düşünmelisiniz. Şu anda, donanım cüzdanlarının çoğu cihazınızı korumak için bir PIN kodu oluşturmanıza imkan sunar ve cüzdanınızın kaybolması durumunda kullanmanız için size bir kurtarma cümlesi verir.</p>
                <h5>Kağıt Cüzdanlar</h5>
                <p>Bir kağıt cüzdan, üzerinde bir açık adresin ve özel anahtarın QR kodu formatında fiziksel olarak basılı olduğu bir kağıt parçasıdır. Kripto para işlemlerini gerçekleştirmek için daha sonra bu kodlar taratılabilir.
                    Bazı kağıt cüzdan web siteleri çevrimdışı durumdayken yeni adresler ve anahtarlar oluşturabilmeniz için kodlarını indirmenize izin verir. Kağıt cüzdanlar çevrimiçi hacklenme saldırılarına karşı büyük oranda dayanıklı hale gelir ve soğuk depolamaya alternatif olarak değerlendirilebilir.</p>
                <p>Fakat çok sayıda kusuru nedeniyle kağıt cüzdanların kullanımı artık tehlikeli kabul edilir ve önerilmez. Bu cüzdanları yine de kullanmak istiyorsanız, risklerin farkında olmanız çok önemlidir. Kağıt cüzdanların başlıca sıkıntılarından biri fonların parça parça gönderilememesi ve tüm bakiyenin yalnızca tek seferde gönderilmesidir.</p>
                <img style={{ width: "450px" }} src={btcuzdan} alt="footer-images" />
            </div>
        </div>
    )
}

export default TabActive;