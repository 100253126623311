import { useState,useEffect } from "react";
import { Link } from 'react-router-dom';

import Tab1 from "../Pages/Tab1";
import Tab2 from "../Pages/Tab2";
import Tab3 from "../Pages/Tab3";
import Tab4 from "../Pages/Tab4";
import Tab5 from "../Pages/Tab5";
import Tab6 from "../Pages/Tab6";
import Tab7 from "../Pages/Tab7";
import Tab8 from "../Pages/Tab8";
import Tab9 from "../Pages/Tab9";
import Tab10 from "../Pages/Tab10";
import Tab11 from "../Pages/Tab11";
import Tab12 from "../Pages/Tab12";
import Tab13 from "../Pages/Tab13";
import Tab14 from "../Pages/Tab14";
import TabActive from "../Pages/tabActive1";
import TabActive2 from "../Pages/tabActive2";
import TabActive3 from "../Pages/tabActive3";
import TabActive4 from "../Pages/tabActive4";
import TabActive5 from "../Pages/tabActive5";
import TabActive7 from "../Pages/tabActive7";
import TabActive8 from "../Pages/tabActive8";
import TabActive9 from "../Pages/tabActive9";
import TabActive10 from "../Pages/tabActive10";
import TabActive11 from "../Pages/tabActive11";
import TabActive12 from "../Pages/tabActive12";
import TabActive13 from "../Pages/tabActive13";
import TabActive61 from "../Pages/tabActive19"


let data = [
  {
    id: 0,
    title: "Hakkımızda"
  },
  {
    id: 1,
    title: "Misyonumuz"
  },
  {
    id: 2,
    title: "Vizyonumuz"
  },
  {
    id: 3,
    title: "Neden BtcOley?"
  },
  {
    id: 4,
    title: "Kripto Para Nedir?"
  },
  {
    id: 5,
    title: "Bitcoin Nedir?"
  },
  {
    id: 6,
    title: "Yardım Merkezi"
  },
  {
    id: 7,
    title: "Güvenlik"
  },
  {
    id: 8,
    title: "Üyelik İşlemleri"
  },
  {
    id: 9,
    title: "Yatırma Çekme"
  },
  {
    id: 10,
    title: "Al Sat"
  },
  {
    id: 11,
    title: "Hizmet Sağlayıcılarımız"
  },
  {
    id:12,
    title: "Kurumsal Projeler"
  },  
  {
    id:13,
    title: "Kariyer"
  },
  {
    id: 14,
    title: "Kara Para Aklamayı..."
  },
  {
    id:15,
    title: "Açık Rıza Beyanı"
  },
  {
    id:16,
    title: "Gizlilik Protokolü"
  },
  {
    id:17,
    title: "KVKK Poli̇ti̇kası"
  },
  {
    id:18,
    title: "Kullanıcı Sözleşmesi"
  }, 
  {
    id:19,
    title: "Çerezler Politikası"
  },

  {
    id:20,
    title: "Ticari Elektronik İleti..."
  },
  {
    id:21,
    title: "Risk Bildirim Formu"
  },
  {
    id:22,
    title: "Blockchain"
  },
  {
    id:23,
    title: "Madencilik"
  },
  {
    id:24,
    title: "Kripto Paralar"
  },
  {
    id:25,
    title: "Kripto Para Cüzdanları..."
  },
  {
    id:26,
    title: "Teknik Analiz&Analiz..."
  }

]
const Wallets = () => {

  const [active, setActive] = useState("Madencilik");
  const [current, setCurrent] = useState(23);
  const [totalScroll, setTotalScroll] = useState(0);

  function indexActive(active, idx) {
    setActive(active);
    setCurrent(idx);
    if (totalScroll > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

  }
  
    const countScroll = () => {
      setTotalScroll(window.scrollY);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', countScroll);
      return () => {
        window.removeEventListener('scroll', countScroll);
      };
    }, []);


  return (
    <>
      <div data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
        <div className="navbar-container w-container col-sm-12">
          <div className="navbar-padding">
            <div className="navbar-wrapper">
              <div className="navbar-logo">
                <a href="/" className="brand w-nav-brand">
                  <img src="images/Logo-Color.svg" loading="lazy" alt="site-logo" className="image-7" />
                </a>
              </div>
              <div className="navbar-menu">
                <nav role="navigation" className="navbar-menu-wrapper w-nav-menu">
                  <a href="https://pro.btcoley.com/market" target="_blank" className="nav-link w-nav-link">Gelişmiş Al/Sat</a>
                  <a href="https://pro.btcoley.com/easy-buy-sell" target="_blank" className="nav-link w-nav-link">Kolay Al/Sat</a>
                  <a href="https://pro.btcoley.com/change" target="_blank" className="nav-link w-nav-link">Dönüştür</a>
                  <a href="https://pro.btcoley.com/ftvttoken" target="_blank" className="nav-link w-nav-link">FTVT Satın Al</a>
                  <a href="https://pro.btcoley.com/register" target="_blank" className="navbar-menu-register w-inline-block">
                    <div> Kayıt Ol</div>
                  </a>
                  <a href="https://pro.btcoley.com/login" target="_blank" className="navbar-menu-login w-inline-block">
                    <div>Giriş Yap</div>
                  </a>
                </nav>
                <div className="menu-button w-nav-button">
                  <div className="icon w-icon-nav-menu" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contents-section wf-section">
        <div className="contents-padding">
          <div className="contents-container w-container">
            <div className="contents">
              <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100"
                className="contents-tabs w-tabs">
                <ul className="contents-tabs-menu w-tab-menu">
                  {data.map((item, key) => (
                    <li onClick={() => indexActive(item.title, key)}
                      className={`contents-tab-link w-inline-block w-tab-link`}>
                      <Link key={key} className={`content-tab-link-item ${current === key ? "active-current" : ""}`}>
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className="tabs-content w-tab-content">

                {
  active === "Hakkımızda" ? <Tab1 /> :
  active === "Misyonumuz" ? <Tab2 /> :
  active === "Vizyonumuz" ? <Tab3 /> :
  active === "Neden BtcOley?" ? <Tab4 /> :
  active === "Kripto Para Nedir?" ? <Tab5 /> :
  active === "Bitcoin Nedir?" ? <Tab6 /> :
  active === "Yardım Merkezi" ? <Tab7 /> :
  active === "Güvenlik" ? <Tab8 /> :
  active === "Üyelik İşlemleri" ? <Tab9 /> :
  active === "Yatırma Çekme" ? <Tab10 /> :
  active === "Al Sat" ? <Tab11 /> :
  active === "Hizmet Sağlayıcılarımız" ? <Tab12 /> :
  active === "Kurumsal Projeler" ? <Tab13 /> : 
  active === "Kariyer" ? <Tab14 /> : 
  active === "Kara Para Aklamayı..." ? <TabActive /> : 
  active === "Açık Rıza Beyanı" ? <TabActive2 /> :
  active === "Gizlilik Protokolü" ? <TabActive3 /> :
  active === "KVKK Poli̇ti̇kası" ? <TabActive4 /> : 
  active === "Kullanıcı Sözleşmesi" ? <TabActive5 /> : 
  active === "Ticari Elektronik İleti..." ? <TabActive12 /> : 
  active === "Risk Bildirim Formu" ? <TabActive13 /> : 
  active === "Blockchain" ? <TabActive11 /> : 
  active === "Madencilik" ? <TabActive10 /> : 
  active === "Kripto Paralar" ? <TabActive9 /> : 
  active === "Kripto Para Cüzdanları..." ? <TabActive8 /> : 
  active === "Teknik Analiz&Analiz..." ? <TabActive7 /> : active === "Çerezler Politikası" ? <TabActive61 />: null
}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Wallets;