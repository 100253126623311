
const Tab8 = ()=> {

    return(
        <div data-w-tab="Tab 8" class="tab-pane-tab-1">
        <div class="contents-rich-text-block w-richtext">
          <h4>Güvenlik</h4>
          <ol>
            <li>Varlıklarınızın %95'i çevrimdışı soğuk cüzdanlarda güvenle saklanır.</li>
            <li>Hassas hesap bilgilerinizin tümü şifrelenmiş olarak kişisel veri yönetmeliklerine uygun olarak
              muhafaza edilir.</li>
            <li>2FA İki Aşamalı kimlik doğrulama ile hesabınızı güvenceye alın.</li>
            <li>2FA İki Aşamalı kimlik doğrulama ile hesabınızı güvende tutma olanağı</li>
            <li>Kullanıcı adı ve şifrenize ek İki Aşamalı kimlik doğrulama 2FA ile hesabınızın güvenliğini
              sağlar.</li>
            <li>Farklı ülkelerde özel güvenlikli .....yedekleme saklama vb..</li>
          </ol>
        </div>

      </div>
    )
}

export default Tab8;