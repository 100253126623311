import React, { useEffect } from 'react';
 
const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return 'Android';
  } else if (/iPad|iPhone|iPod/.test(ua)) {
    return 'iOS';
  }
  return 'Other';
};

const MobileAppRedirect = () => {

  useEffect(() => {
    let browserType = getMobileOS();

    if (browserType === 'iOS') {
      window.location.href =
        'https://apps.apple.com/tr/app/btcoley/id6451135431?l=tr';
    } else if (browserType === 'Android') {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.btcoleymobile2';
    } else {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.btcoleymobile2';
    }
  }, []);

  return <></>;
};

export default MobileAppRedirect;