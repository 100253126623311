import caizon from '../images/caizcoin.jpg'
import ftvt from '../images/ftvt.png'

const Tab13 = () => {

    return (

        <div className={`tab-pane-tab-1 w-tab-pane w--tab-active`}>
            <h4>Kurumsal Projeler</h4>
            <div style={{ display:"flex",justifyContent:"center"}} className="service-logo__wrapper">
              
                <img className="service-logo" src={"images/ftvt.png"} alt="brand-logos" />
            </div>
        </div>

    )
}

export default Tab13;