import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Tab7 = () => {


    React.useEffect(() => {
        const elements = document.getElementsByClassName('beforenone');
        console.log(elements.length)
        if (elements && elements.length > 0) {
            for (let i = 0; i < elements.length; i++)
                elements[i].classList.remove('css-1elwnq4-MuiPaper-root-MuiAccordion-root');
        }
    }, []);

    return (
        

        <div  style={{padding: '22px 30px 13px',borderRadius: "20px", background: 'white'}}>
            <Accordion style={{border: "none", borderRadius: "30px"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"  
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }}>
                    <Typography style={{color: "white"}}>Nasıl Hesap Açabilirim?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{margin: 0}}>
                    <div >
                        <p>BtcOley’e dakikalar içerisinde kolaylıkla üye olabilirsiniz.</p>
                        <p>Anasayfanın en üst kısmında yer alan “Yeni Üyelik” butonuna tıklayarak üyelik formuna
                            ulaşabilirsiniz.</p>
                        <p>Aşağıdaki bilgileri eksiksiz olarak doldurmanız gerekir.</p>
                        <ol>
                            <li>T.C. Kimlik Numarası</li>
                            <li>Ad, Soyad Bilgisi</li>
                            <li>Doğum Tarihi</li>
                            <li>E-Posta Adresi</li>
                            <li>Cep Telefonu Numarası</li>

                        </ol>
                        <p>KVKK Aydınlatma Metni, Kullanım Sözleşmesi, KVKK Açık Rıza Metni ve diğer hukuki belgeleri
                            incelemeniz sonrası “Üyelik Oluştur” adımı ile üyeliğinizi başlatabilirsiniz.</p>
                        <p>E-posta adresinize gelen “BtcOley Üyelik Onayı” e-postasını onayladıktan sonra BtcOley giriş
                            ekranı açılacaktır.</p>
                        <p>E-Postanız ve şifrenizi girdikten sonra üyelik esnasında belirtmiş olduğunuz telefon numarasına
                            gelen iki aşamalı doğrulama kodunu kullanarak hesabınıza erişim sağlayabilirsiniz.</p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop:'15px', borderRadius:"25px", }}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce", borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>BtcOley Üyelik Seviyesi Nedir?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{margin: 0}}>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                        <h4 style={{margin: 0}}>Seviye 1:</h4>
                        <p>Üyelik sırasında belirtilen kimlik bilgileri NVİ sistemi üzerinde doğrulanmış, BtcOley üyelik
                            onayı ve cep telefonu SMS Doğrulama adımlarını tamamlanmış olan kullanıcılar, Seviye 1
                            statüsündedir.</p>
                        <h4>Seviye 2:</h4>
                        <p>Sisteme yüklenen kimlik belgeleri, dilekçeli selfie fotoğrafı BtcOley ekibi tarafından kontrol
                            edilmiş ve doğrulanmış olan kullanıcılar ise Seviye 2 statüsündedir.</p>
                        <p>Bu seviyeye geçebilmek için aşağıdaki belgelerin Hesap (Onayı) Seviye Yükseltme sayfasından
                            paylaşılması gerekmektedir.</p>
                        <ul>
                            <li>T.C. Kimlik Kartı/Nüfus Cüzdanı, Sürücü Belgesi veya T.C. Pasaportu ön yüzü fotoğrafı</li>
                            <li>T.C. Kimlik Kartı/Nüfus Cüzdanı, Sürücü Belgesi veya T.C. Pasaportu arka yüzü fotoğrafı</li>
                            <li>Dilekçeli selfie belgesi</li>
                        </ul>
                        <p>Dilekçeli selfie için Beyaz bir kağıda;</p>
                        <p>“BtcOley’’ ve ‘’Bugünün Tarihini (gg/aa/2022)’’ okunaklı bir şekilde yazınız.
                            Dilekçeniz ve kimlik belgenizi elinizde tutarak, yüzünüzünde görüneceği şekilde fotoğraf
                            (selfie) çekiniz.</p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>BtcOley Seviye 1 Statüsünde Olan
                        Kullanıcılar Hangi İşlemleri Yapabilir? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                        <h4>Hesap Seviye 1 statüsünde;</h4>
                        <ul>
                            <li>Türk Lirası yatırma</li>
                            <li>Türk Lirası çekme</li>
                            <li>Kriptopara alış</li>
                            <li>Kriptopara satış</li>
                            <li>Kriptopara yatırma işlemlerinin tamamını seviye 1 statüsündeyken yapabilirsiniz.</li>
                        </ul>
                        <p>Ek olarak kriptopara çekme işlemi yapmak istiyorsanız hesap seviyenizi yükseltmeniz
                            gerekmektedir.</p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>BtcOley Seviye 2 Statüsünde Olan
                        Kullanıcılar Hangi İşlemleri Yapabilir?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                        <h4>Hesap Seviye 2 statüsünde;</h4>
                        <ul>
                            <li>Türk Lirası yatırma</li>
                            <li>Türk Lirası çekme</li>
                            <li>Kriptopara alış</li>
                            <li>Kriptopara satış</li>
                            <li>Kriptopara yatırma</li>
                            <li>Kriptopara çekme</li>
                        </ul>
                        <p>işlemlerinin tamamını gerçekleştirebilirsiniz.</p>
                        <p>Seviye 2 statüsünde Seviye 1'e ek olarak yalnızca kriptopara çekme işlemi yapılabilmektedir.
                        </p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>BtcOley Hesap (Onayı) Seviye Yükseltme
                        İşlemini Nasıl Yaparım?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                        <p>BtcOley web sitesinde yer alan Hesap Hesap (Onayı) Seviye Yükseltme sayfasına gelerek, talep
                            edilen 3 belgeyi eksiksiz ve istenilen şekilde paylaşmanız durumunda, hesap (onayı) seviye
                            yükseltme işleminiz BtcOley ekibi tarafından tamamlanacaktır.</p>
                        <p>Üyelik seviyenizi yükseltme sürecinde aşağıdaki işlemleri gerçekleştirmeniz gerekmektedir.</p>
                        <ul>
                            <li>Kimlik ön yüzü fotoğrafı</li>
                            <li>Kimlik arka yüzü fotoğrafı</li>
                            <li>Dilekçeli selfie belgesi</li>
                        </ul>
                        <p>Dilekçeli selfie için Beyaz bir kağıda;</p>
                        <p>"BtcOley" ve "Bugünün Tarihini (gg/aa/2022)" okunaklı bir şekilde yazınız. Dilekçeniz ve kimlik
                            belgenizi elinizde tutarak, yüzünüzünde görüneceği şekilde fotoğraf (selfie) çekiniz.</p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>BtcOley hesabıma hangi yöntemlerle Türk
                        Lirası yatırabilir ve çekebilirim?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                        <p>Türkiye’de faaliyet gösteren bankalar ile yalnızca kendi adınıza ait vadesiz bir banka hesabı
                            kullanarak, Havale veya EFT yoluyla Türk Lirası yatırabilir ve çekebilirsiniz.</p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>Nasıl Türk Lirası yatırabilirim? 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                        <p>BtcOley hesabınıza, Türk Lirası yatırmak için YATIR sayfasına geçiniz.</p>
                        <p>Türk Lirası yatırmak istediğiniz banka hesabımızı seçerek, ilgili bankaya ait IBAN ve Hesap Adı
                            bilgilerini, mobil bankacılık uygulamanız üzerinden QR kod okutarak veya banka bilgilerini
                            SMS’le al butonunu kullanarak alabilirsiniz.</p>
                        <p>Kendi adınıza ait vadesiz banka hesaplarınızdan, Havale veya EFT yoluyla Türk Lirası
                            yatırabilirsiniz.</p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>Nasıl Türk Lirası çekebilirim?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                        <p>BtcOley hesabınızdan, kendi banka hesabınıza Türk Lirası gönderebilmek için ÇEK sayfasına
                            geçiniz.</p>
                        <p>Türk Lirası göndermek istediğiniz banka hesap bilginizi seçerek, çekmek istediğiniz TL
                            miktarını belirtiniz.</p>
                        <p>"TALEP OLUŞTUR" butonuna basarak, işleminizi gerçekleştirebilirsiniz.</p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>Nasıl Kriptopara Alabilirim? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext  ">
                        <p>Market sayfasında işlem yapmak istediğiniz, kriptopara işlem çiftini seçiniz.</p>
                        <p>Piyasa Alış Emirleri için;</p>
                        <ol>
                            <li>Almak istediğiniz TL miktarını giriniz</li>
                            <li>AL butonuna tıklayınız</li>
                        </ol>
                        <p>Piyasa emri, işleminizin o anki en iyi fiyat üzerinden ve anında gerçekleşmesini sağlar.</p>
                        <p>Limit Alış Emirleri için;</p>
                        <ol>
                            <li>Almak istediğiniz birim fiyatı giriniz</li>
                            <li>Almak istediğiniz kriptopara miktarı giriniz</li>
                            <li>AL butonuna tıklayınız.</li>
                        </ol>
                        <p>Limit emir, alış yapmak istediğiniz fiyat ve miktarı belirterek, dilediğiniz fiyattan yapmanızı
                            sağlar.</p>
                        <p>Stop Alış Emirleri için;</p>
                        <ol>
                            <li>Belirlediğiniz tetikleme fiyatını giriniz</li>
                            <li>Almak istediğiniz birim fiyatını giriniz</li>
                            <li>Almak istediğiniz kriptopara miktarını yazınız</li>
                            <li>AL butonuna tıklayınız.</li>
                        </ol>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>Nasıl Kriptopara Satabilirim?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext  ">
                        <p>Market sayfasında işlem yapmak istediğiniz, kriptopara işlem çiftini seçiniz.</p>
                        <p>Piyasa, Limit veya Stop emir vererek, kriptopara satış işlemi gerçekleştirebilirsiniz.</p>
                        <p>Piyasa Satış Emirleri için;</p>
                        <ol>
                            <li>Satmak istediğiniz kriptopara miktarını giriniz</li>
                            <li>SAT butonuna tıklayınız</li>
                        </ol>
                        <p>Piyasa emri, işleminizin o anki en iyi fiyat üzerinden ve anında gerçekleşmesini sağlar.</p>
                        <p>Limit Satış Emirleri için;</p>
                        <ol>
                            <li>Satmak istediğiniz birim fiyatı giriniz</li>
                            <li>Almak istediğiniz kriptopara miktarı giriniz</li>
                            <li>SAT butonuna tıklayınız.</li>
                        </ol>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>İşlem Hacmi Nedir?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext">
                        <p>Kriptopara alım ve satım işlemleri kapsamında, miktarların belirli bir dönem içindeki
                            değerlerinin toplamına kriptopara işlem hacmi denir.</p>
                        <p>İşlem hacmi ‘’İşlem Miktarı * İşlem Fiyatı= İşlem Hacmi’’ formülü ile hesaplanır. Kriptopara
                            işlem hacmi 24 saatlik, haftalık, aylık veya daha uzun süreler için hesaplanabilir.</p>
                        <p>Günlük işlem hacmi olarak görütüleyeceğiniz miktar, son 24 saat içinde kullanıcılar arasında
                            kaç adet kriptoparanın el değiştirdiğini ifade eder.</p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={"beforenone"} style={{ marginTop: '15px',  borderRadius: "25px", }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header" 
                    style={{background: "#4590ce",margin: 0, borderRadius: '20px', }} >
                    <Typography style={{color: "white", margin: 0}}>Emir Çeşitleri Nelerdir? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext">
                        <p>Piyasa, Limit ve Stop Emir ile alım satım işlemi yapabilirsiniz.</p>
                        <p>Piyasa emri, derinlik tablosundaki en iyi fiyatlardan işlem yapmanızı sağlar.</p>
                        <p>Limit emir, işlem yapmak istediğiniz miktar ve belirlediğiniz fiyatı yazarak oluşturacağınız
                            emir tipidir.</p>
                        <p>Stop emri, piyasa sizin pozisyonunuzun tersine gittiğinde kârınızı korumak veya zararınızı
                            belli bir seviyede tutmak için kullanılan emir tipidir.</p>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default Tab7;