import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Tab9 = () => {

    React.useEffect(() => {
        const elements = document.getElementsByClassName('beforenone');
        console.log(elements.length)
        if (elements && elements.length > 0) {
            for (let i = 0; i < elements.length; i++)
                elements[i].classList.remove('css-1elwnq4-MuiPaper-root-MuiAccordion-root');
        }
    }, []);
    return (
        <>
            <div style={{ padding: '22px 30px 13px', borderRadius: "20px", background: 'white' }}>
                <Accordion style={{ border: "none", borderRadius: "30px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: "#4590ce", margin: 0, borderRadius: '20px', }}>
                        <Typography style={{ color: "white" }}>Nasıl Hesap Açabilirim?</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 0 }}>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                            <p>BtcOley’e dakikalar içerisinde kolaylıkla üye olabilirsiniz.</p>
                            <p>Anasayfanın en üst kısmında yer alan “Yeni Üyelik” butonuna tıklayarak üyelik formuna
                                ulaşabilirsiniz.</p>
                            <p>Aşağıdaki bilgileri eksiksiz olarak doldurmanız gerekir.</p>
                            <ol>
                                <li>T.C. Kimlik Numarası</li>
                                <li>Ad, Soyad Bilgisi</li>
                                <li>Doğum Tarihi</li>
                                <li>E-Posta Adresi</li>
                                <li>Cep Telefonu Numarası</li>
                            </ol>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={"beforenone"} style={{ marginTop: '15px', borderRadius: "25px", }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ background: "#4590ce", borderRadius: '20px', }} >
                        <Typography style={{ color: "white", margin: 0 }}>BtcOley Üyelik Seviyesi Nedir?</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 0 }}>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                            <h4>Seviye 1:</h4>
                            <p>Üyelik sırasında belirtilen kimlik bilgileri NVİ sistemi üzerinde doğrulanmış, BtcOley üyelik
                                onayı ve cep telefonu SMS Doğrulama adımlarını tamamlanmış olan kullanıcılar, Seviye 1
                                statüsündedir.</p>
                            <h4>Seviye 2:</h4>
                            <p>Sisteme yüklenen kimlik belgeleri, dilekçeli selfie fotoğrafı BtcOley ekibi tarafından kontrol
                                edilmiş ve doğrulanmış olan kullanıcılar ise Seviye 2 statüsündedir.</p>
                            <p>Bu seviyeye geçebilmek için aşağıdaki belgelerin Hesap (Onayı) Seviye Yükseltme sayfasından
                                paylaşılması gerekmektedir.</p>
                            <ul>
                                <li>T.C. Kimlik Kartı/Nüfus Cüzdanı, Sürücü Belgesi veya T.C. Pasaportu ön yüzü fotoğrafı</li>
                                <li>T.C. Kimlik Kartı/Nüfus Cüzdanı, Sürücü Belgesi veya T.C. Pasaportu arka yüzü fotoğrafı</li>
                                <li>Dilekçeli selfie belgesi</li>
                            </ul>
                            <p>Dilekçeli selfie için Beyaz bir kağıda;</p>
                            <p>“BtcOley’’ ve ‘’Bugünün Tarihini (gg/aa/2022)’’ okunaklı bir şekilde yazınız.
                                Dilekçeniz ve kimlik belgenizi elinizde tutarak, yüzünüzünde görüneceği şekilde fotoğraf
                                (selfie) çekiniz.</p>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={"beforenone"} style={{ marginTop: '15px', borderRadius: "25px", }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ background: "#4590ce", margin: 0, borderRadius: '20px', }} >
                        <Typography style={{ color: "white", margin: 0 }}>btcoley Seviye 1 Statüsünde Olan
                            Kullanıcılar Hangi İşlemleri Yapabilir?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                            <p>Hesap Seviye 1 statüsünde;</p>
                            <ul>
                                <li>Türk Lirası yatırma</li>
                                <li>Türk Lirası çekme</li>
                                <li>Kriptopara alış</li>
                                <li>Kriptopara satış</li>
                                <li>Kriptopara yatırma işlemlerinin tamamını seviye 1 statüsündeyken yapabilirsiniz.</li>
                            </ul>
                            <p>Ek olarak kriptopara çekme işlemi yapmak istiyorsanız hesap seviyenizi yükseltmeniz
                                gerekmektedir.</p>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={"beforenone"} style={{ marginTop: '15px', borderRadius: "25px", }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ background: "#4590ce", margin: 0, borderRadius: '20px', }} >
                        <Typography style={{ color: "white", margin: 0 }}>btcoley Seviye 2 Statüsünde Olan
                            Kullanıcılar Hangi İşlemleri Yapabilir?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                            <p>Hesap Seviye 2 statüsünde;</p>
                            <ul>
                                <li>Türk Lirası yatırma</li>
                                <li>Türk Lirası çekme</li>
                                <li>Kriptopara alış</li>
                                <li>Kriptopara satış</li>
                                <li>Kriptopara yatırma</li>
                                <li>Kriptopara çekme</li>
                                <p>işlemlerinin tamamını gerçekleştirebilirsiniz.</p>
                                <p>Seviye 2 statüsünde Seviye 1'e ek olarak yalnızca kriptopara çekme işlemi yapılabilmektedir.
                                </p>
                            </ul>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={"beforenone"} style={{ marginTop: '15px', borderRadius: "25px", }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ background: "#4590ce", margin: 0, borderRadius: '20px', }} >
                        <Typography style={{ color: "white", margin: 0 }}>BtcOley Hesap (Onayı) Seviye Yükseltme
                            İşlemini Nasıl Yaparım?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div class="tab-pane-tab-2 ">
                            <span class="accordion contents-rich-text-block1 w-richtext">BtcOley Hesap (Onayı) Seviye Yükseltme
                                İşlemini Nasıl Yaparım?<svg data-accordion-icon="true" class="w-6 h-6 shrink-0"
                                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg></span>
                            <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                                <p>BtcOley web sitesinde yer alan Hesap Hesap (Onayı) Seviye Yükseltme sayfasına gelerek, talep
                                    edilen 3 belgeyi eksiksiz ve istenilen şekilde paylaşmanız durumunda, hesap (onayı) seviye
                                    yükseltme işleminiz BtcOley ekibi tarafından tamamlanacaktır.</p>
                                <p>Üyelik seviyenizi yükseltme sürecinde aşağıdaki işlemleri gerçekleştirmeniz gerekmektedir.</p>
                                <ul>
                                    <li>Kimlik ön yüzü fotoğrafı</li>
                                    <li>Kimlik arka yüzü fotoğrafı</li>
                                    <li>Dilekçeli selfie belgesi</li>
                                </ul>
                                <p>Dilekçeli selfie için Beyaz bir kağıda;</p>
                                <p>"BtcOley" ve "Bugünün Tarihini (gg/aa/2022)" okunaklı bir şekilde yazınız. Dilekçeniz ve kimlik
                                    belgenizi elinizde tutarak, yüzünüzünde görüneceği şekilde fotoğraf (selfie) çekiniz.</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default Tab9;