
const Tab4 = () => {

    return (
        <div data-w-tab="Tab 4" class="tab-pane-tab-1">
            <div class="contents-rich-text-block w-richtext">

                <h4>Neden BtcOley?</h4>
                <ol class="role">
                    <li>
                        Yeni Üyelik formunu doldurarak kolayca hesap açabilirsiniz
                        <ul>
                            <li>Yeni Üyelik formunu doldurarak kolayca hesap aç</li>
                            <li>Hemen kolayca üyeliğini oluştur kripto para dünyasına hızlı giriş yap.</li>
                            <li>Kriptopara dünyasında yeriniz hazır hemen üye ol BtcOley’le keşfetmeye başla.</li>
                        </ul>
                    </li>
                    <li>
                        Listelenen tüm kriptopara çiftlerinde yüksek likitide imkanı
                        <ul>
                            <li>Listelediğimiz tüm kriptopara birimleri için yüksek işlem hacmi</li>
                            <li>Anlık işlem ve sınırsız (sınırlar ötesi) mülkiyet devri (havale) sağlar.</li>
                            <li>Listelenen ve devamlı artan tüm kriptopara çiftlerinde yüksek likitide imkanı</li>
                        </ul>
                    </li>
                    <li>
                        Sürekli artan kriptopara çifti
                        <ul>
                            <li>Devamlı artan kriptopara birimi</li>

                        </ul>
                    </li>
                    <li>Yasal uyumluluk ve güvenlik standartlarında çok yönlü finansman seçeneği</li>
                    <li>
                        İhtiyacın olduğu her an e-posta ve canlı destek ile 7/24 yardımcı olmak için hazırız.
                        <ul>
                            <li>7/24 Müşteri Hizmetleri desteği ile ihtiyaç duyduğun her an yanınızdayız.</li>
                            <li>Sorularınız Müşteri Hizmetlerimiz ile 7/24 yanıtlansın / yanıtlanır.</li>
                            <li>Müşteri Hizmetlerimiz yardımcı olabilmek için 7/24 hazır</li>
                            <li>Yardıma ihtiyacınız var mı? 7/24 Müşteri Hizmetleri desteği ile ihtiyaç duyduğun her an
                                yanınızdayız. </li>
                        </ul>
                    </li>
                    <li>Sade ve kolay yapısıyla kullanıcı dostu BtcOley.</li>
                    <li>Hızlı nakit işlemi ve kriptopara alış-satış ile sizde BtcOley’de yerinizi alın.
                    </li>
                </ol>

            </div>
        </div>
    )
}

export default Tab4;