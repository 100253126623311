import { formatMoney2 } from "./helper";
import TextCleaner from "text-cleaner";

const tofix = (text) => {
    let lengt = String(text).length;
  
    if(lengt<0){
        let newtext = text+'0';
        return tofix(newtext);
    }else{
        if(lengt>8){
            return String(text).substr(0,8)
        }else{
            return text;
        }
    }
}

const cleanprice = (item, decimal = 8,original_decimal,type, ret = 'array') => {
  try {
    if (isNaN(item)) {
      item = "0.0";
    }
    if(decimal===undefined){
      decimal = 10;
    }
    let nitem = TextCleaner(item).valueOf();

    // Eğer nitem tamsayı ise onu virgüllü bir sayıya dönüştürüyoruz
    if (!nitem.includes('.')) {
      nitem += '.0';
    }

    let text;
    let append;
/* console.log(original_decimal) */
    let appendDecimal = nitem.split('.')[1].substr(0, original_decimal );
    
    let numberOfZerosToAdd = Math.max(decimal - appendDecimal.length, 0);
    if (numberOfZerosToAdd > 0) {
      appendDecimal += "0".repeat(numberOfZerosToAdd);
    }

    let integerPart = nitem.split('.')[0];
    numberOfZerosToAdd = 3 - (integerPart.length % 3);
    if (numberOfZerosToAdd !== 3) {
      integerPart = "0".repeat(numberOfZerosToAdd) + integerPart;
    }

    text = formatMoney2(integerPart, decimal);

    let decimalSeparator = ",";
    let decimalPart =  appendDecimal.replace(/0+$/, '');
    if (decimalPart.length >= 0) {
      decimalPart = decimalSeparator + decimalPart;
    }

    text += decimalPart;

    if (decimalPart === ",") {
      text += "0";
    } else if (decimalPart === "") {
      text += ",0";
    }

    let ob = {};
    ob['ori'] = text;
    ob['sub'] = "";
    ob['zero'] = appendDecimal.slice(decimalPart.length - 1);

    numberOfZerosToAdd = decimal - (nitem.split('.')[1] || "").length + 1;
    let full = nitem + "0".repeat(numberOfZerosToAdd);

    ob['full'] = formatMoney2(full.split('.')[0], decimal) + "," + full.split('.')[1];

    if (ret === 'array') {
      return ob;
    } else {
      return text;
    }
  } catch (e) {
    let ob = {};
    ob['ori'] = item;
    ob['sub'] = "";
    ob['zero'] = "";
    ob['full'] = "";
  }
}


  

export {
    tofix,
    cleanprice
}