import img1 from '../images/ngn.png'
import avax from '../images/avax.png'
import turkcell from '../images/turkcell.png'
import zendesk from '../images/zendesk.png'

const Tab12 = () => {

    return (

        <div className={`tab-pane-tab-1 w-tab-pane w--tab-active`}>
            <h4>Hizmet Sağlayıcılarımız</h4>
            <div style={{gap:5}} className="service-logo__wrapper">
                <img className="service-logo" src={img1} alt="brand-logos" />
                <img className="service-logo" src={avax} alt="brand-logos" />
                <img className="service-logo" src={turkcell} alt="brand-logos" />
                <img style={{marginBottom:"13px"}} className="service-logo" src={zendesk} alt="brand-logos" />
            </div>
        </div>

    )
}

export default Tab12;