import FibaBanka from '../images/Fibabanka.png';
import zip from "../images/attachments_btcoley(1).zip"
import smile from "../images/smile_icon.png"





const Footer = () => {

    return (
        <div className="footer-section wf-section">
            <div className="footer-padding">
                <div className="footer-container w-container ">
                   
                    <div id="w-node-_9e6db563-ac2f-257d-93ed-e497f310231d-a4908a08">
                        <div className="footer-inner__wrapper" id="w-node-a3925f6e-90de-de90-ea4e-b2b654a83bb2-a4908a08">
                            <div >
                                <div className="footer-heading-line" />
                                <div style={{borderBottomWidth: "3px",borderBottomStyle: "solid"}} className="footer-heading">Kurumsal</div>
                                <a href="/about" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Hakkımızda</div>
                                </a>
                                <a href="/services" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Hizmet Sağlayıcılarımız</div>
                                </a>
                                <a href="/projects" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Kurumsal Projeler</div>
                                </a>
                            <a href={zip} className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Medya Materyalleri</div>
                                </a>
                                <a href="https://www.linkedin.com/company/btcoley/" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Kariyer</div>
                                </a>
                            </div>
                            <div className="footer-inner__child">
                                <div className="footer-heading-line" />
                                <div style={{borderBottomWidth: "3px",borderBottomStyle: "solid"}} className="footer-heading">Yasal Bilgiler</div>
                                <a href="/laundering" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Kara Para Aklamayı...</div>
                                </a>
                                <a href="/statement" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Açık Rıza Beyanı</div>
                                </a>
                                <a href="/protocol" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Gizlilik Protokolü</div>
                                </a>
                                <a href="/policy" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> KVKK Poli̇ti̇kası</div>
                                </a>
                                <a href="/agreement" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Kullanıcı Sözleşmesi</div>
                                </a>
                                <a href="/commercial" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Ticari Elektronik İleti...</div>
                                </a>
                                <a href="/riskNotification" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Risk Bildirim Formu</div>
                                </a>
                                <a href="/cookies" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Çerez Politikası</div>
                                </a>
                            </div>
                            <div >
                                <div className="footer-heading-line" />
                                <div style={{borderBottomWidth: "3px",borderBottomStyle: "solid"}} className="footer-heading">Yardım Merkezi</div>
                                <a href="/security" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Güvenlik</div>
                                </a>
                                <a href="/membershipTransactions" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Üyelik İşlemleri</div>
                                </a>
                                <a href="/depositWithdraw" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Yatırma Çekme</div>
                                </a>
                                <a href="/buyAndSell" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Al Sat</div>
                                </a>
                            </div>
                            <div className="footer-text-link-box">
                                <div  className="footer-heading-line" />
                                <div style={{borderBottomWidth: "3px",borderBottomStyle: "solid"}} className="footer-heading">Teknoloji</div>
                                <a href="/blockchain" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Blockchain</div>
                                </a>
                                <a href="/mining" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Madencilik</div>
                                </a>
                                <a href="/cripto_money" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Kripto Paralar</div>
                                </a>
                                <a href="/wallets" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Kripto Para Cüzdanları...</div>
                                </a>
                                <a href="/technical" className="footer-text-link w-inline-block">
                                    <div><img style={{width:"20px"}} src={smile}></img> Teknik Analiz&amp;Analiz...</div>
                                </a>
                            </div>
                            <div style={{maxWidth:"276px"}} className="footer-form__wrapper" id="w-node-_39ada34e-d3f9-3b37-c98a-33af7ca81bc4-a4908a08">
                                <div className="div-block-48">
                                    <div className="text-block-3">E-Bültenimize Kayıt Olun!</div>
                                    <div className="text-block-2-copy">Kayıt olarak güncel destek duyurularını elektronik posta ile alabilirsiniz.</div>
                                    <div className="form-block w-form">
                                        <form id="wf-form-Newsletter" name="wf-form-Newsletter" data-name="Newsletter" method="get" className="form"><input type="email" className="text-field w-input" maxLength={256} name="Newsletter" data-name="Newsletter" placeholder="Lütfen e-posta adresinizi yazınız..." id="Newsletter" required /><input type="submit" defaultValue="Kayıt Ol" data-wait="Please wait..." className="white-orange-button w-button" /></form>
                                        <div className="w-form-done">
                                            <div>Thank you! Your submission has been received!</div>
                                        </div>
                                        <div className="w-form-fail">
                                            <div>Oops! Something went wrong while submitting the form.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="div-block-49">
                                    <a href="https://www.facebook.com/btcoleyofficial" target="_blank" className="facebook-icon-link w-inline-block"><img src="images/facebook.svg" loading="lazy" id="w-node-d365f641-c26e-2c31-b486-7fe0ca41e419-a4908a08" alt="" className="facebook-icon" /></a>
                                    <a href="https://twitter.com/btcoleyofficial" target="_blank" className="twitter-icon-link w-inline-block"><img src="images/twitter.svg" loading="lazy" id="w-node-d365f641-c26e-2c31-b486-7fe0ca41e41b-a4908a08" alt="" className="twitter-icon" /></a>
                                    <a href="https://www.instagram.com/btcoleyofficial/" target="_blank" className="instagram-icon-link w-inline-block"><img src="images/instagram.svg" loading="lazy" id="w-node-d365f641-c26e-2c31-b486-7fe0ca41e41d-a4908a08" alt="" className="instagram-icon" /></a>
                                    <a href="https://www.linkedin.com/company/btcoley/" target="_blank" className="linkedin-icon-link w-inline-block"><img src="images/linkedin.svg" loading="lazy" id="w-node-d365f641-c26e-2c31-b486-7fe0ca41e41f-a4908a08" alt="" className="linkedin-icon" /></a>
                                    <a href="https://t.me/btcoley" target="_blank" className="telegram-icon-link w-inline-block"><img src="images/telegram.svg" loading="lazy" id="w-node-d365f641-c26e-2c31-b486-7fe0ca41e41f-a4908a08" alt="" className="telegram-icon" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bank-logo__wrapper'>
                        <img className='bank-logo__image' src={FibaBanka} alt="bank-logo" />
                        <img className='bank-logo__image' src="https://upload.wikimedia.org/wikipedia/commons/7/75/%C5%9Eekerbank_logo.png" alt="bank-logo" />
                        <img className='bank-logo__image' src="https://upload.wikimedia.org/wikipedia/commons/0/04/Vak%C4%B1fbank_logo.svg" alt="bank-logo" />
                        <img className='bank-logo__image' src="https://upload.wikimedia.org/wikipedia/commons/6/69/Ziraat_Bankas%C4%B1_logo.png" alt="bank-logo" />
                    </div>

                </div>
            </div>
            <div class="magician-of-meta-section wf-section">
                <div class="magician-of-meta-padding">
                    <div class="magician-of-meta-container w-container">
                        <div class="magician-of-meta-wrapper">
                            <div class="magician-of-meta-logo">
                                <img src="images/magician-of-meta-logo.png" loading="lazy"
                                    srcset="images/magician-of-meta-logo-p-500.png 500w, images/magician-of-meta-logo-p-800.png 800w, images/magician-of-meta-logo.png 1436w"
                                    sizes="100vw" alt="" className="image-9" />
                            </div>
                            <div>
                                <div class="magician-of-meta-text">
                                    <p>© 2022 Magician of Meta Teknoloji A.Ş.</p>
                                    <p>Mersis No: 0610137614100001</p>
                                    <p>Tic.Sic.No: 358514-5</p></div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>

    )
}

export default Footer;