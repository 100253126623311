

const Tab3 = () => {

    return (
        <div class="tab-pane-tab-1">
            <div class="contents-rich-text-block w-richtext">
                <h4>Vizyonumuz</h4>
                <p>Türkiye’de Blockchain teknolojilerine yatırım yaparak, yerel ve global kurumsal markaların
                    dijital ödeme sistemlerini geliştirmek ve adına üretilen tokenları başta BtcOley borsasında daha
                    sonra diğer yerel ve global borsalarda listelenmesine aracılık etmek suretiyle dijital varlık
                    ekosisteminde öncü ve lider olmak.
                </p>
            </div>
        </div>
    )
}

export default Tab3