
const Tab5 = () => {

    return (
        <div data-w-tab="Tab 5" class="tab-pane-tab-1">
            <div class="contents-rich-text-block w-richtext">
                <h4>Kripto Para Nedir?</h4>
                <p>Kripto para, takas işlemlerinde kullanılabilen, tamamen dijital, şifrelenmiş, sanal para birimi.
                    Kimilerine göreyse geleceğin para birimi.</p>
                <p>Günümüzde kripto para birimleri pek çok kişi tarafından duyulmuş, devlet başkanlarından dev
                    şirketlere kadar herkesin çok fazla dikkatini çekmiş durumda olan bilinmeyeni çok olan bir
                    olgudur.
                    Takas işlemlerini doğrulamak ve güvenli şekilde gerçekleştirmek için kriptografi kullanılır.
                    Herhangi bir fiziksel karşılığa sahip değildir.</p>
                <p>Aslen banka kartları ve kredi kartlar ile harcanılan ve aktarılan paralar da sanal paralardır,
                    çünkü bu işlemler sonucunda sadece sistem verilerinde değişiklik meydana gelir. Kripto paralar da
                    aynı mantıkta çalışma prensibine sahip. Ancak herhangi bir otorite ya da hükümet tarafından
                    yönetilmeyen, yani merkezileştirilmemiş (decentralized) bir sistem mevcut, bu da sistemin daha
                    güvenli olmasını sağlıyor.</p>

            </div>
        </div>
    )
}

export default Tab5;