import btcuzdan from '../images/btccuzdan.png';
const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>Ticari Elektronik İleti Gönderimi Hakkında Bilgilendirme</h4>
                <p>6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve sair mevzuatlar uyarınca ticari elektronik iletiler; ticari amaçla gönderilen ve telefon, santral, faks, otomatik çevirici, kayıt sistemleri, akıllı tonlar, e-posta hizmetleri, kısa mesajlar gibi olanaklar kullanılarak elektronik ortamda gerçekleştirilen veri, ses ve video içerikli mesajdır.</p>
                <p>Ticari elektronik iletiler, alıcılara sadece öncesinden onayları alınmak kaydıyla gönderilebilir. Ticari Elektronik İleti Yönetim Sistemi (“İYS”) üzerinden onayı bulunmayan alıcılara ticari elektronik mesaj gönderilemez Bu onay, yazılı olarak ya da her türlü elektronik yazışma araçlarıyla veya İYS üzerinden alınabilir. İYS üzerinden alınan onaylarda ise müspet irade beyanı ve elektronik yazışma adresi yer alır. Alıcılar diledikleri zaman, hiçbir gerekçe belirtmeksizin ticari elektronik iletileri almayı reddedebilirler. Alıcının ret bildiriminde bulunması, bildirimin yapıldığı yazışma kanalına ait onayı geçersiz kılar. Alıcı reddetme hakkını İYS üzerinden de kullanabilir.</p>
                <p>Alıcının kendisiyle iletişime geçilmesi amacıyla iletişim bilgilerini vermesi hâlinde, temin edilen mal veya hizmetlere ilişkin değişiklik, kullanım ve bakıma yönelik ticari elektronik iletiler için ayrıca onay alınmaz.</p>
                <p>Esnaf veya tacirlerin elektronik bağlantılarına gönderilen ticari elektronik iletiler için önceden izin alınması gerekmez. Ancak, tacir ve esnafların ilgili kanun uyarınca ret hakkını kullandıkları durumlarda, tacir ve esnaflara, rızaları olmaksızın ticari elektronik ileti gönderilemez. Bu kapsamda, hizmet sağlayıcı tarafından mesaj gönderilmeden önce esnaf veya tacirlerin elektronik iletişim bilgileri İYS'ye kaydedilir ve alıcının İYS üzerinden vazgeçme hakkını kullanıp kullanmadığı kontrol edilir.
                </p>
                <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve ilgili mevzuat uyarınca, BtcOley tarafından verilmekte olan hizmetler kapsamında, www.btcoley.com web sitesi üzerinden temin edilen bilgileriniz doğrultusunda rıza vermeniz halinde herhangi bir yazışma aracı kullanarak (SMS, e- posta, telefon, faks, çağrı merkezi, v.b. gibi) tarafınıza her türlü bilgilendirme, tanıtım, reklam, ürün teklifleri, promosyonlar, kampanyalar, memnuniyet değerlendirme ve duyuruların iletilmesi amacıyla tarafınızla iletişime geçilebilecektir.
                </p>
                <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve ilgili mevzuat uyarınca ticari elektronik ileti gönderimine dair onay verseniz dahi istediğiniz zaman, hiçbir gerekçe belirtmeksizin ticari elektronik iletileri almayı reddedebilirsiniz. Ticari elektronik ileti hangi iletişim yoluyla gönderildiyse ret bildirimini de aynı iletişim yoluyla verebilirsiniz. Reddetme hakkınızı İYS üzerinden de kullanabilirsiniz.</p>
                <h4>TİCARİ ELEKTRONİK İLETİ GÖNDERİMİ RIZA BEYANI</h4>
                <p>İşbu beyanı onaylayarak, BtcOley ’in, 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve ilgili mevzuat uyarınca, BtcOley tarafınca, tarafıma verilmekte olan hizmetler kapsamında , www.btcoley.com web sitesine sağladığım ad, soyad ve iletişim bilgilerim yoluyla, herhangi bir iletişim aracı kullanarak (SMS, e-posta, telefon, faks, çağrı merkezi, v.b. gibi) tarafıma her türlü bilgilendirme, tanıtım, reklam, ürün teklifleri, promosyonlar, kampanyalar, memnuniyet değerlendirme çalışmaları ve duyuruların iletilmesi amacıyla tarafımla iletişime geçilmesine onay verdiğimi, bu kapsamda bu bilgilerimde herhangi bir değişiklik olması halinde bu hususu BtcOley’e yazılı olarak bildireceğimi ve güncel bilgilerimi içeren yeni bir beyannameyi imzalayarak BtcOley’e teslim edeceğimi,</p>
                <p><strong>Tarafımca verilmiş işbu rızanın geri alınabilir olduğu konusunda bilgim olduğunu ve bu doğrultuda BtcOley’e, ticari elektronik ileti hangi iletişim kanalıyla gönderildiyse ret bildirimi de aynı iletişim kanalıyla verebileceğimi, reddetme hakkımı İYS üzerinden de kullanabileceğimi bildiğimi KABUL, BEYAN VE TAAHHÜT EDERİM.</strong></p>
            </div>
        </div>
    )
}

export default TabActive;