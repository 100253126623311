const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>İnternet Sitesi Çerez Aydınlatma Metni</h4>
                <p><strong>Magician of Meta Teknoloji Anonim Şirketi (“BtcOley” olarak anılacaktır)</strong> olarak, çevrimiçi mecralarımızı ziyaretleriniz esnasında sizlerin deneyiminizi geliştirmek için çerezler, pikseller, GIF’ler gibi bazı teknolojilerden (“Çerezler”) faydalanmaktayız. </p>
                <p>Bu teknolojilerin kullanımı başta 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) olmak üzere tabi olduğumuz mevzuata uygun şekilde gerçekleştirilmektedir.</p>
                <p>İşbu Çerez Aydınlatma metninin amacı, tarafımızca işletilmekte olan pro.btcoley.com internet sitesinin (“Site”) işletilmesi sırasında Site ziyaretçileri (“Kişisel Veri Sahibi”) tarafından çerezlerin kullanımı ile elde edilen kişisel verilerin işlenmesine ilişkin olarak sizlere bilgi vermektir. İşbu belgede sitemizde hangi amaçlarla hangi tür çerezleri kullandığımızı ve bu çerezleri nasıl kontrol edebileceğinizi sizlere açıklamayı amaçlanmaktadır.</p>
                <p><strong>Magician of Meta Teknoloji Anonim Şirketi</strong> olarak, sitemizde yer alan çerezleri kullanmaktan vazgeçilebilecek, çerezlerin türleri veya fonksiyonları değiştirilebilecek veya sitemize yeni çerezler eklenebilecektir. Dolayısıyla işbu aydınlatma metninin hükümlerini dilediğimiz zaman değiştirme hakkımızı saklı tutmaktayız. Güncel aydınlatma metni üzerinde gerçekleştirilmiş olan her türlü değişiklik sitede veya herhangi bir kamuya açık mecrada yayınlanmakla birlikte yürürlük kazanacaktır. Son güncelleme tarihi metnin başında yer almaktadır.
                </p>
                <p>Kişisel verilerinizin <strong>Magician of Meta Teknoloji Anonim Şirketi</strong> tarafından işlenmesi hakkında daha detaylı bilgi için <a href="/policy">https://www.btcoley.com/policy</a> adresinde yer alan Magician of Meta Teknoloji Anonim Şirketi Kişisel Verilerin Korunması ve İşlenmesi Politikası’nı okumanızı tavsiye ederiz.</p>

                 <h4>Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h4> 
                <p>Kişisel verileriniz, internet sitemizi ziyaretiniz esnasında veya mobil uygulamamızı kullanmanız dolayısıyla elektronik ortamda çerezler yoluyla Şirketimizin meşru menfaatine yönelik hukuki sebebe dayalı olarak toplanmaktadır. Toplanan kişisel verileriniz KVKK’nın 4., 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında işbu Çerez Aydınlatma Metni’nde belirtilen amaçlarla da işlenebilmektedir.</p>
                <h4>Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h4> 
                <p><strong>BtcOley</strong> olarak, Çerez Aydınlatma Metni kapsamındaki kişisel verilerinizi yukarıda belirtilen amaçların gerçekleştirilebilmesi ile sınırlı olarak ve mevzuata uygun olmak üzere iş ortaklarımızla, kanunen yetkili kamu kurum ve kuruluşlarıyla ve özel kişilerle paylaşılabilecektir.</p>
                <h4>Çerez (“Cookie”) Nedir?</h4>
                <p>Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla cihazınıza veya ağ sunucusuna depolanan küçük metin dosyalarıdır. Çerezler, ziyaret ettiğiniz web sitesiyle ilişkili sunucular tarafından oluşturulurlar. Böylelikle ziyaretçi aynı siteyi ziyaret ettiğinde sunucu bunu anlayabilmektedir.
Çerezler, ziyaretçilere ilişkin isim, cinsiyet veya adres gibi kişisel verileri içermezler.
</p>
<h4>Hangi Çerezler Hangi Amaçlarla Kullanılmaktadır?</h4>
                <p><strong>Magician of Meta Teknoloji Anonim Şirketi</strong> olarak sitemizde çeşitli amaçlarla çerezler kullanmakta ve bu çerezler vasıtasıyla kişisel verilerinizi işlemekteyiz. Bu amaçlar başlıca şunlardır:</p>
                
                <ul>
                    <li>Site’nin çalışması için gerekli temel fonksiyonları gerçekleştirmek,</li>
                    <li>Site’yi analiz etmek ve Site’nin performansını arttırmak,</li>
                    <li>Site’nin işlevselliğini arttırmak ve kullanım kolaylığı sağlamak,</li>
                    <li>Online İşlemler kısmında oturum açtığınızda oturum bilgilerinizin işlemleriniz devam ettiği sürece saklanmasını sağlamak,</li>
                    <li>İnternet sitesini iyileştirmek ve internet sitesi üzerinden yeni özellikler sunmak, sunulan özellikleri sizlerin tercihine göre kişiselleştirmek,</li>
                    <li>İnternet sitesinin, kullanıcılarının ve BtcOley’in hukuki ve ticari güvenliğinin teminini sağlamak.</li>
                    
                </ul>
                
                <p><strong>BtcOley</strong> tarafından kullanılan çerezler bilgisayarınıza zarar vermemekte ve virüs içermemektedir.</p>
                <p><strong>Magician of Meta Teknoloji Anonim Şirketi</strong> olarak, aydınlatma metni kapsamındaki kişisel verilerinizi yukarıda belirtilen amaçların gerçekleştirilebilmesi ile sınırlı olarak ve mevzuata uygun şekilde tedarikçilerimize, kanunen yetkili kamu kurumlarına ve özel kişilere paylaşabiliriz. Kişisel Verilerin aktarıldığı tarafların kişisel verilerinizi dünyanın her yerindeki sunucularında saklayabileceğini belirtmek isteriz.</p>
                
                <h4>Sitemizde Kullanılan Çerezler</h4>
                <p>Sitemizde kullandığımız farklı türdeki çerezleri aşağıda bulabilirsiniz.</p>
                <p>Çerezi yerleştiren tarafa göre, Site çerezleri ve üçüncü taraf çerezler kullanılmaktadır. Site çerezleri,<strong>BtcOley</strong> tarafından oluşturulurken, üçüncü taraf çerezlerini ise site haricindeki sunucular yönetmektedir.</p>
               
                <p>Aktif olduğu süreye göre, oturum çerezleri ve kalıcı çerezler kullanılmaktadır. Oturum çerezleri ziyaretçinin Site’yi terk etmesiyle birlikte silinirken, kalıcı çerezler ise kullanım alanına bağlı olarak çeşitli sürelerle ziyaretçilerin cihazlarında kalabilmektedir.</p>
                <p>Kullanım amaçlarına göre, Site’de teknik çerezler, doğrulama çerezleri, hedefleme/reklam çerezleri, kişiselleştirme çerezleri ve analitik çerezler kullanılmaktadır.</p>
           
              <h4>Zorunlu Çerezler</h4>
              <p>Belli çerezlerin kullanımı sitemizin doğru biçimde çalışması için zorunludur. Örneğin sitemizde oturum açtığınızda devreye giren kimlik doğrulama çerezleri, sitemizde bir sayfadan diğerine geçişinizde etkin olan oturumunuzun devam etmesini sağlamaktadır.</p>
              <p><strong>client_device_information : </strong>BtcOley tarafından güvenlik amaçlı girilen cihaz değişikliğini tespit etmek amaçlı kullanılır.</p>
              <p><strong>AWSALBCORS :</strong>Bu tanımlama bilgisi AWS tarafından yönetilir ve yük dengeleme için kullanılır.</p>
              <p><strong>Bu tanımlama bilgisi AWS tarafından yönetilir ve yük dengeleme için kullanılır.</strong>Sayfa yükleme sürelerini hızlandırmak için kullanılan, CloudFlare kullanan sitelerle ilişkili çerez. CloudFlare'ye göre, ziyaretçinin geldiği IP adresine bağlı olarak herhangi bir güvenlik kısıtlamasını geçersiz kılmak için kullanılır. Herhangi bir kullanıcı kimlik bilgisi içermez.</p>
               <h4>Performans ve Analiz Çerezleri</h4>
               <p>Bu çerezler sayesinde sitemizi kullanımınızı ve sitemizin performansını analiz ederek sizlere verdiğimiz hizmetleri daha iyi hale getirebiliyoruz.</p>
              <p><strong>_gid : </strong>Ziyaret edilen her sayfa için benzersiz bir değer depoluyor ve güncelliyor.</p>
              <p><strong>AWSALB :</strong>Bu çerezler, kullanıcı deneyimini olabildiğince sorunsuz hale getirmek için sunucu trafiğini tahsis etmemizi sağlar. Hangi sunucunun şu anda en iyi kullanılabilirliğe sahip olduğunu belirlemek için bir yük dengeleyici kullanılır. Herhangi bir kullanıcı kimlik bilgisi içermez.</p>
              <p><strong>_ga : </strong>Rasgele oluşturulmuş bir sayı ile kullanıcıları ayırt etmek için kullanılır.</p>
              <p><strong>__cfduid : </strong>Sayfa yükleme sürelerini hızlandırmak için kullanılan, CloudFlare kullanan sitelerle ilişkili çerez. CloudFlare'ye göre, ziyaretçinin geldiği IP adresine bağlı olarak herhangi bir güvenlik kısıtlamasını geçersiz kılmak için kullanılır. Herhangi bir kullanıcı kimlik bilgisi içermez.</p>
              <h4>Çerezlerin Kullanımını Nasıl Kontrol Edebilirim?</h4>
              <p>Tarayıcı ayarları değiştirilerek çerezlere ilişkin tercihler düzenlenebilmektedir. En çok kullanılan tarayıcılar bakımından kullanılabilecek link’ler aşağıda sunulmaktadır;</p>
              
              <p><strong>Adobe Analytics : </strong> <a href="http://www.adobe.com/uk/privacy/opt-out.html" target="_blank">http://www.adobe.com/uk/privacy/opt-out.html</a></p>
              <p><strong>Google Analytics : </strong> <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a></p>
              <p><strong>Google Chrome : </strong> <a href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647" target="_blank"> http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647</a></p>
              <p><strong>Internet Explorer :</strong> <a href="https://support.microsoft.com/en-us/help/17442/windowsinternetexplorerdelete-manage-cookies" target="_blank">https://support.microsoft.com/en-us/help/17442/windowsinternetexplorerdelete-manage-cookies</a></p>
              <p><strong>Mozilla Firefox : </strong> <a href="http://support.mozilla.com/en-US/kb/Cookies" target="_blank">http://support.mozilla.com/en-US/kb/Cookies</a></p>
              <p><strong>Safari :</strong> <a href="https://support.apple.com/kb/ph19214?locale=tr_TR" target="_blank">https://support.apple.com/kb/ph19214?locale=tr_TR</a></p>
             <h4>Kişisel Veri Sahibi Olarak Haklarınız Nelerdir?</h4>
             <p>6698 Sayılı KVKK’nın 11. maddesi uyarınca kişisel veri sahipleri kendileriyle ilgili,</p>
            
             <p>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
             <p>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</p>
             <p>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</p>
             <p>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
             <p>KVKK ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
            <p>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</p>
            <p>KVKK’ya aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahiptir.</p>
            <p>Söz konusu haklarınıza ilişkin taleplerinizi, Site içerisindeki Başvuru Formu'nu doldurarak <strong> info@btcoley.com</strong> e-posta adresinden tarafımıza iletmeniz halinde başvurularınız en kısa sürede ve en geç 30 (otuz) gün içerisinde değerlendirilerek sonuçlandırılacaktır. Taleplere ilişkin olarak herhangi bir ücret talep edilmemesi esas olmakla birlikte, Şirket’in Kişisel Verileri Koruma Kurulu tarafından belirlenen ücret tarifesi üzerinden ücret talep etme hakkı saklıdır.</p>
            <p>Kişisel Veri Sahibi, herhangi bir kişisel verisinin Şirket tarafından kullanılamaması ile sonuçlanacak bir talepte bulunması halinde Site’nin işleyişinden tam olarak faydalanamayabileceğini kabul ile bu kapsamda doğacak her türlü sorumluluğun kendisine ait olacağını kabul, taahhüt ve beyan eder.</p>
           
           
            </div>
        </div>
    )
}

export default TabActive;