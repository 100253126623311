
const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>Ki̇şi̇sel Veri̇leri̇n Kaydedi̇lmesi̇ ve Korunması ve İşlenmesi̇ Poli̇ti̇kası</h4>
                <p><strong>(AYDINLATMA METNİ)</strong></p>
                <p>Kişisel verilerin gizliliği Magician of Meta Teknoloji A.Ş. (“BtcOley”) için önem arz etmekte olup, kişisel verilerin kaydedilmesi süreci tarafımızca hassasiyetle yürütülmektedir.
                </p>
                <p>BtcOley Web Sitesi KVK Aydınlatma Metni, BtcOley olarak veri sorumlusu sıfatıyla KVKK’dan kaynaklanan aydınlatma yükümlülüğünün yerine getirilmesi amacıyla hazırlanmıştır.
                </p>
                <p>Kişisel verilerin nasıl kaydedileceği ve korunacağını işbu Kişisel Verilerin Kaydedilmesi ve Korunması Politikası (“Politika”) ile açıklamaktadır. İşbu Politika kullanıcılar ve müşteriler açısından gerekli ve yasal bir zorunluluk olması sebebiyle dikkatli şekilde okunmalı ve gizlilik kontrol edilmelidir.</p>

                <p>BtcOley, resmi web sitesi www.btcoley.com adresi üzerinden elde edilen ve Kullanıcılara/müşterilere ait kişisel verilerin 6698 sayılı Kişisel Verilerin Korunması Kanunu (<strong>"KVKK"</strong> ) ve ikincil düzenlemelerine uygun olarak kaydedilmesi ve korunması için azami duyarlılığı göstermektedir.</p>
                <p>Politika, BtcOley e www.btcoley.com adresi veya BtcOley tarafından ilan edilen mobil uygulamalar üzerinden üye olan gerçek kişi veya tüzel kişi kullanıcı/müşteri ile BtcOley arasında düzenlenen kullanım sözleşmesinin de ayrılmaz bir parçasıdır.</p>
                <p>Öncelikle bazı tanımların bilinmesi gerekmektedir. Bunlar;</p>
                <p><strong>Kişisel Veri:</strong>  Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi</p>
                <p><strong>Özel Nitelikli Kişisel Veri:</strong> Irk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler ile biyometrik ve genetik veriler özel nitelikli verileri</p>
                <p><strong>Kişisel Veri Sahibi:</strong> Kişisel verisi kaydedilen gerçek kişiyi (tüzel kişi yetkilileri dahil)</p>
                <p><strong>Açık Rıza:</strong> Belirli bir konuya ilişkin, bilgilendirmeye dayanan ve özgür iradeyle açıklanan rızayı</p>
                <p><strong>Kişisel Verilerin İşlenmesi:</strong> Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hale getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi</p>
                <p><strong>Veri Sorumlusu:</strong> Kişisel verilerin kaydetme amaçlarını ve vasıtalarını belirleyen, verilerin sistematik bir şekilde tutulduğu yeri (veri kayıt sistemi) yöneten veri sorumlusunu</p>
                <p><strong>Veri İşleyen:</strong> Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel veri kaydeden gerçek ve tüzel kişiyi</p>
                <p><strong>Veri Kayıt Sistemi:</strong> Kişisel verilerin belirli kriterlere göre yapılandırılarak kaydedildiği kayıt sistemini</p>
                <p><strong>Kanun:</strong> 6698 sayılı Kişisel Verilerin Korunması Kanunu’nu</p>
                <p><strong>Anonim Hale Getirme:</strong> Daha öncesinde bir kişiyle ilişkilendirilmiş olan verilerin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir gerçek bir kişiyle ilişkilendirilemeyecek hale getirilmesini ifade eder.</p>
                <p>BtcOley tarafından www.btcoley.com adresini ziyaret etmenizle birlikte başta trafik verileriniz olmak üzere aşağıda belirtilen kişisel verileriniz, beraberinde açıklanan amaçlarla bağlantılı, sınırlı ve ölçülü bir şekilde elektronik ve fiziki ortamlarda yazılı olarak, otomatik veya otomatik olmayan yöntemlerle KVKK’ya uygun şekilde BtcOley tarafından kaydedilmektedir. Kişisel verilerin kaydedilmesi süreci, Anayasa başta olmak üzere tüm mevzuata uygun olarak belirlenmiş ve benimsenmiş olan esaslara uygun olarak hareket etmektedir.</p>
                <p>BtcOley, kişisel verileri, 5237 sayılı Türk Ceza Kanunu, 5271 sayılı Ceza Muhakemesi Kanunu, 5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun, 4857 sayılı İş Kanunu ve 6102 sayılı Türk Ticaret Kanunu başta olmak üzere, ilgili tüm ulusal/uluslararası mevzuat ve ulusal/uluslararası yetkili otoritelerce ve bunlara dayanarak yayımlanmış olan ikincil düzenlemelerden ve ayrıca taraf olduğu tüm sözleşmelerden doğan yükümlülüklerin yerine getirilmesi için yasal dayanaklara bağlı olarak kaydetmektedir.</p>
                <p>BtcOley, kişisel veri paylaşımı yapılması hususunda aksi öngörülmedikçe paylaşım amacıyla bağlı ve sınırlı olarak kişisel verileri kaydetmek ve korumakla sözleşme ilişkisi kapsamında yükümlüdür.
                </p>
                <p>Kişisel veriler, sayılan hizmetler dahilinde Kanun’un 4 maddesindeki ilkelere uygun olarak, 5 ve 6 maddelerinde düzenlenen kaydetme şartları dahilinde kaydedilmektedir.
                </p>
                <p>BtcOley Aydınlatma Metni'nde ve Açık Rıza Metnin’ de sayılan kişisel veriler, yine bu metinde belirtilen amaçların yanı sıra BtcOley’ in ticari faaliyetlerini yürütülebilmesi amacıyla bağlantılı, sınırlı ve ölçülü olarak, yurt içi merkezli dış hizmet sağlayıcıları ve yurt dışı merkezli dış hizmet sağlayıcıları (IT uzmanları, veri tabanı sağlayıcıları, yedekleme ve kurtarma uzmanları, bankalar, yatırım kuruluşları, özel finans kurumları, ödeme sistemleri kuruluşları, kart kuruluşları, elektronik para kuruluşları, diğer finansal kuruluşlarla vb.) ile KVKK’ya uygun olarak paylaşılmaktadır. Bununla birlikte ilgili veriler, dış hizmet sağlayıcılarında güvenli sunucularında muhafaza edilmek üzere paylaşılır. Kişisel veriler, hukuki sorumluluk ve yükümlülüklerin yerine getirilmesi amacıyla resmi kurum, kuruluşlar ve yargı makamları ile KVKK’ya uygun olarak paylaşmaktayız.</p>
                <p>Kişisel Verilerin Korunması Kanununun 6 maddesinde, “özel nitelikli” olarak belirlenen veriler; ırk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık ve kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verilerdir.</p>
                <p>Kişisel Verilerin Korunması Kanununa uygun bir biçimde BtcOley tarafından; özel nitelikli kişisel veriler, Kişisel Verilerin Korunması Kurulu tarafından belirlenecek olan yeterli önlemlerin alınması kaydıyla kişisel veri sahibinin açık rızası üzerine ve açık rıza yok ise kanunlarda öngörülen hallerde, kaydetmektedir.
                </p>
                <p>Kişisel verilere yetkisiz üçüncü kişiler tarafından erişilmesini, kişisel verilerin hatalı kaydedilmesini, ifşa edilmesini, hukuka uygun olmayan sebeplerle değiştirilmesini/silinmesini önlemek, korunmasını ve güvenliğini sağlamak amacıyla BtcOley KVKK uyarınca gerekli özen ve itinayı gösterecektir.</p>
                <p>Kişisel veriler, KVKK ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde re’sen veya ilgili kişinin talebi üzerine (KVKK madde 7 kapsamında ) veri sorumlusu tarafından silinir, yok edilir veya anonim hale getirilir.</p>
                <p>BtcOley’in ilgili mevzuat hükümleri gereğince kişisel verileri muhafaza etme hak ve/veya yükümlülüğü olan durumlarda veri sahibinin talebini yerine getirmeme hakkı saklıdır.
                </p>
                <p>Kişisel veri hakkında her türlü başvurular www.btcoley.com adresine yazılı olarak, kimlik teyit şartının yerine getirilmesi koşuluyla bizzat veya noter kanalı ile yapılabilir. Ayrıca, kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza yoluyla ya da ilgili kişi tarafından BtcOley’ e daha önce bildirilen web site sisteminde kayıtlı bulunan e-posta adresini kullanmak suretiyle destek@btcoley.com adresine başvuru yapmak mümkündür. Yapılacak başvurularda KVKK'nın 13 maddesi ve 30356 sayılı ve 10.03.2018 tarihli Resmi Gazete'de yayımlanan Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ'in başvuru usulünü düzenleyen 5.maddesine uygun şekilde yapılması gerektiği hatırlatılmaktadır.</p>
                <p>BtcOley, ilgili mevzuatta öngörülen veya işleme amacının gerekli kıldığı saklama süreleri sona erdiğinde, periyodik imha için öngörülen süre zarfında; kaydettiği kişisel verileri KVK Kurulu tarafından yayımlanan Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesine ilişkin rehberde belirtilen silme ve anonim hale getirme yöntemlerinden, iş süreçleri ve faaliyetlerine en uygun olan bir veya birkaç tekniği kullanarak siler veya anonimleştirerek kullanmaya devam eder.</p>
                <p>BtcOley fiziki arşivine ve/veya sunucularına ve/veya diğer sistemlerine yapılan saldırılar sonucunda kişisel verilerinizin zarar görmesi ve/veya üçüncü kişiler tarafından ele geçirilmesi/ifşası durumunda, Kişisel Verileri Koruma Kurulu'nu olaydan haberdar olduktan sonra 72 saat içerisinde bilgilendirir.</p>
                <p>BtcOley, Kişisel Verilerin Korunması Kanunu 10 Maddesi gereği, kişisel veri sahiplerine kişisel verilerinin elde edilmesi sırasında, kişisel verilerin hangi amaçla işleneceği, işlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği, kişisel veri toplamanın yöntemi ve hukuki sebebi hakkında veri sahibinin talebi üzerine veri sahibine bilgi vermekle yükümlüdür. Kişisel veri sahibinin KVKK’nın 11 maddesi kapsamında BtcOley‘e başvurarak kişisel verilerinin; işlenip işlenmediğini öğrenme; işlenmişse buna ilişkin bilgi talep etme, işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme gibi kanundan doğan hakları mevcuttur.</p>
                <p>Usulüne uygun olan başvuru talepleri, talebin niteliğine göre ve en geç 30 (otuz) gün içinde sonuçlandırılır. İşlemin maliyet gerektirmesi halinde, KVK Kurulu tarafından belirlenen tarife uygulanacaktır. Talebiniz kabul edilecek veya gerekçesi açıklanarak reddedilebilecek olup, cevaplar yazılı bildirilir. E-posta yolu ile yapılan cevaplamalar ve sair bilgilendirmelerde e-postanın sahibi tarafından okunduğu kabul edilir.</p>
                <p>BtcOley, üyelik işlemi gerçekleşirken pop-up ile çıkardığı Aydınlatma Metni ile kullanıcılarını/müşterilerini bilgilendirmekte olup, web sitesinde yer alan Kullanım Sözleşmesini kabul eden ve siteye üye olan her kişi bu aydınlatmanın kendisine yapıldığını kabul etmektedir. İşbu Aydınlatma Metni, Kullanıcı Sözleşmesinin ayrılmaz parçasıdır. Ayrıca, kişisel verilerin işlenebilmesi için ilgili kişilerin açık rızasının gerekli olduğu durumlarda, veri sahiplerinin belirli bir konuya ilişkin ve özgür iradeyle açıklanan rızalarının alınması adına kapsamlı aydınlatmalar yapılacaktır.</p>
                <p>Politika’da düzenlenen hususlar, Kanun’a ve ikincil mevzuata tabi olup, Politika ile mevzuat hükümleri arasında çelişki olması halinde, mevzuat hükümleri uygulanacaktır.
                    İşbu Aydınlatma Metni, değişen süreçler yaşanması sebebiyle KVKK’ya uyum sağlamak amacıyla BtcOley tarafından güncellenebilir.</p>
            </div>
        </div>
    )
}

export default TabActive;