import btcuzdan from '../images/btccuzdan.png';
const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>Madencilik</h4>
                <p>Kripto para madenciliği, temel anlamda, özel yazılımlara ve donanımlara sahip cihazların, karmaşık matematiksel problemleri çözerek kripto para üretimini yapması ve transfer işlemlerini onaylaması sonucunda belirlenen miktarda yeni üretilen kripto para ile ödüllendirilmesidir. Yeterli bilgisayar donanımına ve internete sahip olan herkes madencilik yapabilmektedir.</p>
                <p>Cüzdanlar arasında yapılan transfer işlemleri, blok-zincirde onaylanmadan önce bir işlem havuzuna (mempool) aktarılır. Daha sonra bu işlemler bir araya getirilerek bir blok oluşturulur. Bu bloklar, ağa bağlı cihazlar tarafından onaylandıktan sonra blok-zincire eklenir.</p>
                <p>Bitcoin bir merkezden üretilmez, Bitcoin arzı, merkezi olmayan küresel ağdaki gönüllü bilgisayarların işlemci güçleriyle yapılır. Açık kaynak kodlu madenci yazılımını çalıştırarak, Bitcoin ağına dahil olan herkes, isterse madenci olabilir ve Bitcoin üretebilir. Bitcoinler, madencilik adı verilen, transfer işlemleriyle uğraşırken karmaşık bir matematik problemini, birbirleriyle yarışarak çözen, madenciler aracılığıyla arz edilir. Problemi çözen madenci belli miktar Bitcoin ile ödüllendirilir. Problem sürekli olarak zorlaşır ve madencilere verilen ödül yaklaşık her dört yılda bir yarıya iner.</p>
                <p>Madencilikte işlemin zorluk derecesi sistemin toplam kazılma işlemi, yani madencilerin tamamının oluşturduğu toplam kazış gücüdür. Bu kazış gücü madencilerin yoğunluğuna göre artıp azalmaktadır.
                    Ethereum madenciliğinde Bitcoin madenciliğine göre farklı bir algoritma kullanılır. Ethereum blockchain üzerinden Ether elde etmek için Ethash olarak adlandırılan kendine özgü bir algoritmaya uygun işlemler yapılması gerekir.</p>
                <p>Madencilik CPU, GPU, ASIC ve bulut madenciliği gibi çeşitli yöntemlerle yapılabilir.
                    CPU madenciliği donanımlı ve yüksek işlemcisi olan bilgisayarlarla yapılabilir. En eski madencilik yöntemidir. Çok fazla enerji tüketir, makinelerin ömrüyle orantılı olarak risk taşır.
                    GPU madenciliği, CPU’nun daha geliştirilmiştir halidir. Daha verimli ve ekonomik bir madencilik çeşididir. En çok tercih edilen madencilik türüdür. Birden fazla ekran kartı, donanımlı bir işlemci kullanmak gerekir.
                    ASIC madenciliği, en güçlü madencilik yöntemidir. Bu yöntemi uygulayan herkes çok yüksek miktarda kripto para üretebilir fakat adil olmayan dağılım sistemi nedeniyle tercih edilmez. Yasaklanması yönünde öneriler de vardır.
                    Bulut madenciliği, ulaşılan son teknoloji olarak tanımlanabilir. GPU madenciliği yapan kişi ya da kuruluşlarla anlaşma yöntemiyle, onların madencilik sistemleri kiralanır. Belirli zaman dilimi için anlaşma yapılır. Kiralanan madencilik sisteminde, süre sonunda sistem masrafları düşürülerek, belli miktarda kripto para elde edilir.</p>
            </div>
        </div>
    )
}

export default TabActive;