import { Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import "./App.css"
import Home from "./Pages/home/Home";

import About from "./components/About/About";

import Services from "./components/Services";
import Projects from "./components/Projects";
import Laundering from "./components/laundering";
import Statement from "./components/statement";
import Protocol from "./components/protocol";
import Policy from "./components/policy";
import Agreement from "./components/userAgreement";
import Commercial from "./components/commercialElectronic";
import Footer from "./components/Footer";
import Technical from './components/technical';
import Wallets from './components/wallets';
import CriptoMoney from './components/criptoMoney';
import Mining from './components/mining';
import Blockchain from './components/blockchain'
import BuyAndSell from './components/buyAndSell';
import Deposit from './components/DepositWithdraw';
import Membership from './components/membership';
import Security from './components/Security'
import RiskNotification from './components/RiskNotification';
import Coocies from "./components/Cookies";
import QrPage from "./Pages/QrPage/QrPage"
function App() {

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'))
  })
  return (
    <>
      {/* <Home /> */}
      <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/laundering" element={<Laundering />} />
          <Route path="/statement" element={<Statement />} />
          <Route path="/protocol" element={<Protocol />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/commercial" element={<Commercial />} />
          <Route path="/technical" element={<Technical  />} />
          <Route path="/wallets" element={<Wallets  />} />
          <Route path="/cripto_money" element={<CriptoMoney  />} />
          <Route path="/mining" element={<Mining  />} />
          <Route path="/blockchain" element={<Blockchain  />} />
          <Route path="/buyAndSell" element={<BuyAndSell />} />
          <Route path="/depositWithdraw" element={<Deposit />} />
          <Route path="/membershipTransactions" element={<Membership />} />
          <Route path="/security" element={<Security />} />
          <Route path="/riskNotification" element={<RiskNotification />} />
          <Route path="/cookies" element={<Coocies />} />
          <Route path="/downloadapp" element={<QrPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
