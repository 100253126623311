import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Tab11 = () => {


    React.useEffect(() => {
        const elements = document.getElementsByClassName('beforenone');
        console.log(elements.length)
        if (elements && elements.length > 0) {
            for (let i = 0; i < elements.length; i++)
                elements[i].classList.remove('css-1elwnq4-MuiPaper-root-MuiAccordion-root');
        }
    }, []);
    return (
        <>

            <div style={{ padding: '22px 30px 13px', borderRadius: "20px", background: 'white' }}>
                <Accordion style={{ border: "none", borderRadius: "30px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: "#4590ce", margin: 0, borderRadius: '20px', }}>
                        <Typography style={{ color: "white" }}>Nasıl Kriptopara Alabilirim?</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 0 }}>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                            <p>Market sayfasında işlem yapmak istediğiniz, kriptopara işlem çiftini seçiniz.</p>
                            <p>Piyasa Alış Emirleri için;</p>
                            <ol>
                                <li>Almak istediğiniz TL miktarını giriniz</li>
                                <li>AL butonuna tıklayınız</li>
                            </ol>
                            <p>Piyasa emri, işleminizin o anki en iyi fiyat üzerinden ve anında gerçekleşmesini sağlar.</p>
                            <p>Limit Alış Emirleri için;</p>
                            <ol>
                                <li>Almak istediğiniz birim fiyatı giriniz</li>
                                <li>Almak istediğiniz kriptopara miktarı giriniz</li>
                                <li>AL butonuna tıklayınız.</li>
                            </ol>
                            <p>Limit emir, alış yapmak istediğiniz fiyat ve miktarı belirterek, dilediğiniz fiyattan yapmanızı
                                sağlar.</p>
                            <p>Stop Alış Emirleri için;</p>
                            <ol>
                                <li>Belirlediğiniz tetikleme fiyatını giriniz</li>
                                <li>Almak istediğiniz birim fiyatını giriniz</li>
                                <li>Almak istediğiniz kriptopara miktarını yazınız</li>
                                <li>AL butonuna tıklayınız.</li>
                            </ol>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={"beforenone"} style={{ marginTop: '15px', borderRadius: "25px", }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ background: "#4590ce", borderRadius: '20px', }} >
                        <Typography style={{ color: "white", margin: 0 }}>Nasıl Kriptopara Satabilirim?</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 0 }}>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                            <p>Market sayfasında işlem yapmak istediğiniz, kriptopara işlem çiftini seçiniz.</p>
                            <p>Piyasa, Limit veya Stop emir vererek, kriptopara satış işlemi gerçekleştirebilirsiniz.</p>

                            <p>Piyasa Satış Emirleri için;</p>
                            <ol>
                                <li>Satmak istediğiniz kriptopara miktarını giriniz</li>
                                <li>SAT butonuna tıklayınız</li>
                            </ol>

                            <p>Limit emir, satış yapmak istediğiniz fiyat ve miktarı belirterek, dilediğiniz fiyattan
                                yapmanızı sağlar.</p>
                            <p>Stop Alış Emirleri için;</p>
                            <ol>
                                <li>Belirlediğiniz tetikleme fiyatını giriniz</li>
                                <li>Satmak istediğiniz birim fiyatını giriniz</li>
                                <li>Satmak istediğiniz kriptopara miktarını yazınız</li>
                                <li>SAT butonuna tıklayınız.</li>
                            </ol>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={"beforenone"} style={{ marginTop: '15px', borderRadius: "25px", }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ background: "#4590ce", margin: 0, borderRadius: '20px', }} >
                        <Typography style={{ color: "white", margin: 0 }}>İşlem Hacmi Nedir?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext">
                            <p>Kriptopara alım ve satım işlemleri kapsamında, miktarların belirli bir dönem içindeki
                                değerlerinin toplamına kriptopara işlem hacmi denir.</p>
                            <p>İşlem hacmi ‘’İşlem Miktarı * İşlem Fiyatı= İşlem Hacmi’’ formülü ile hesaplanır. Kriptopara
                                işlem hacmi 24 saatlik, haftalık, aylık veya daha uzun süreler için hesaplanabilir.</p>
                            <p>Günlük işlem hacmi olarak görütüleyeceğiniz miktar, son 24 saat içinde kullanıcılar arasında
                                kaç adet kriptoparanın el değiştirdiğini ifade eder.</p>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={"beforenone"} style={{ marginTop: '15px', borderRadius: "25px", }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ background: "#4590ce", margin: 0, borderRadius: '20px', }} >
                        <Typography style={{ color: "white", margin: 0 }}>Emir Çeşitleri Nelerdir? </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext">
                            <p>Piyasa, Limit ve Stop Emir ile alım satım işlemi yapabilirsiniz.</p>
                            <p>Piyasa emri, derinlik tablosundaki en iyi fiyatlardan işlem yapmanızı sağlar.</p>
                            <p>Limit emir, işlem yapmak istediğiniz miktar ve belirlediğiniz fiyatı yazarak oluşturacağınız
                                emir tipidir.</p>
                            <p>Stop emri, piyasa sizin pozisyonunuzun tersine gittiğinde kârınızı korumak veya zararınızı
                                belli bir seviyede tutmak için kullanılan emir tipidir.</p>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default Tab11;