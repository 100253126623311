
const Tab6 = ()=> {

    return(
        <div data-w-tab="Tab 6" class="tab-pane-tab-1">
        <div class="contents-rich-text-block w-richtext">
          <h4>Bitcoin Nedir?</h4>
          <p>2008 krizi sonrası Satoshi Nakamato adlı kişi ya da kişiler uçtan uca elektronik ödeme sistemi
            olan Bitcoin'e dair teknik yazılarını yayınladılar. Böylece Bitcoin merkezsizleştirilmiş, üçüncül
            müdahalelere karşı korumalı kripto para birimi olarak ortaya çıktı. 2009 yılında halka açık ağ
            olarak kullanıma girdi. Sonrasında Bitcoin, ilk başarılı kripto para olarak "1. nesil blockchain"
            olarak adlandırıldı.</p>
          <p>Dağıtık yapısı sayesinde çok kısa bir sürede günümüz finansal düzenine karşı yükselişe geçti.
            Bitcoin ağına giren işlemlerin takip edilmesi mümkün iken, işlemi gerçekleştiren kişinin kim
            olduğunu bulmak olanaksızdır. Bitcoin Blockchain ağında onaylanan işlemler, zincir yapısından
            dolayı geri döndürülemez ve bu işlemler değiştirilemez.
          </p>
          <p>Düzenlemeye ya da kontrole tabii tutulamadığı için Bitcoin'in değeri sıfır noktasından, binlerce
            dolara kadar yükselmiştir. Bitcoin'in yükselişinin ardından birçok kripto para birimi daha ortaya
            çıkmıştır. Bu para birimlerine "alternative coin", bir diğer değişle "alt coin" denmektedir.
            Alternatif kripto para birimleri yaratılırken çeşitli noktalarda farklı özelliklere sahip olarak
            rekabet avantajından yararlanılmış ve yeni market tipleri ortaya çıkmıştır. Bu farklılıklara
            üretilebilecek maksimum para miktarı, algoritma, blockchain alt türleri (özel/paylaşımlı,
            izinli/izinsiz mutabakat) örnek olarak verilebilir.</p>
          <p>Bitcoin blockchain platformunda üretilebilecek maksimum Bitcoin miktarı 21 milyon adettir.
            Bitcoin uçtan uca, adresten adrese transfer imkanı sağlamaktadır, ayrıca blok üretim süresi
            yaklaşık 10 dakikadır.
          </p>
          <p>Bitcoin adresleri kullanıcıların platform üzerindeki kimlikleridir. İşlemi yapan şahısla
            ilişkilendirilemezler ve bu adreslerin anahtarları kaybedildiğinde adresler üzerinde hak iddia
            edilemez.</p>
        </div>

      </div>
    )
}

export default Tab6;