const ibanRegex = /^TR\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?\d{2}$/;

function formatMoney2(value, scale = 2) {
  const v = new Intl.NumberFormat("tr-TR", {}).format(Number(value ?? 0).toFixed(scale));
  return v;
}

function formatMoney(number, decPlaces, decSep, thouSep) {
  decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? "-" : "";
  var i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))));
  var j = (j = i.length) > 3 ? j % 3 : 0;
  return sign + (j ? i.substr(0, j) + thouSep : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSep) + (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}

export { formatMoney, formatMoney2, ibanRegex };
