
const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>Gizlilik Protokolü</h4>
                <p>Magician of Meta Teknoloji A.Ş. , ("BtcOley veya Şirket") olarak, web servis ve dijital kaynaklarımızda yer alan hizmetlerden faydalanan veya herhangi birini kullanan müşterilerimizin/kullanıcıların kişisel verilerinin korunması, bu konularda gerekli özeni göstermek ve karşılıklı güven ilişkisi kurmak BtcOley olarak sorumluluğumuzdur.</p>
                <p>www.btcoley.com web sitemizi, uzantılarını, sosyal medya sitelerimizi, dijital kanallarımızı ziyaret ettiğinizde veya çevrimiçi ve dijital kanallarımız aracılığıyla BtcOley'e otomatik olarak eriştiğinizde, hizmetlerimizi ve ürünlerimizi kullandığınızda veya yararlandığınızda otomatik olarak Şirket’imiz tarafından kaydedilen ve bizimle paylaşılan Kişisel Verileriniz, öncelikle talebinizin karşılanmasını sağlamak ve ardından müşterilerimize ve diğer kişilere daha iyi hizmet verebilmek için kullanılacaktır.
                </p>
                <p>Onaylı veri tabanımıza dahil olmanız durumunda Şirket’imiz tarafından sunulan ürün ve hizmetlerle ilgili tanıtım, pazarlama ve promosyon faaliyetleri için müşterilerimize/kullanıcılarımıza ticari ileti gönderilebilir. Vazgeçme hakkınızı kullanarak şirketimiz tarafından gönderilen mesajları almak istemiyorsanız; "BtcOley'den yeni ürün ve hizmetleri tanıtan e-postalar almak istemiyorsanız, lütfen buraya tıklayın." Linkine tıklayarak ilgili kanal listemizden çıkabilirsiniz.</p>
                <h4>Sorumluluğun Sınırlandırılması</h4>
                <p>Web sitesinde sağlanan bilgiler "olduğu gibi", "mevcut olduğu şekilde" sağlanmaktadır. BtcOley, bu bilgilerin doğruluğunu, eksiksizliğini veya yeterliliğini garanti etmez ve bu bilgilerdeki hatalar veya eksiklikler için herhangi bir sorumluluk kabul etmez. Bu bilgiler ile üçüncü şahısların haklarının ihlal edilmemesi, belirli bir amaç için uygunluk ve/veya bilgisayar virüsü bulunmamasına ilişkin garantiler dahil ancak bunlarla sınırlı kalmamak kaydıyla, zımnen, açıkça ya da yasal olarak hiçbir garanti verilmemektedir.</p>
                <p>Bu web sitesinin kullanımı sırasında herhangi bir performans arızası, hata, eksiklik, kesinti, kusur, nakilde gecikme, bilgisayar virüsü ve/veya hat sistem arızası sonucu ortaya çıkan doğrudan ya da dolaylı zarar, ziyan ve masraflar da dahil ancak bunlarla sınırlı olmamak üzere hiçbir zarar ve ziyandan, BtcOley ve/veya çalışanları, bu tür bir zarar ve ziyan olasılığından haberdar edilmiş olsalar dahi, sorumlu olmazlar.</p>
                <p>Web sitesinden bağlantı verilen diğer sitelerdeki bağlantıların riski, kullanıcıya/müşteriye aittir. Bu sitelerde verilen bilgilerin doğruluğu ve bu sitelerden verilen diğer bağlantıların nitelikleri BtcOley tarafından araştırılmamış ve doğrulanmamıştır. Web sitemiz vasıtasıyla bağlanılan diğer internet sitelerinden doğan oluşabilecek zararlardan BtcOley veya çalışanları sorumlu değildir.</p>
                <p>Kişiler verilerin korunması ilgili kanun kapsamında yapılmaktadır. Detaylı bilgiye Kişisel Verilerinin Korunması'ndan ve Çerez Politikası'ndan ulaşabilirsiniz.</p>
                <p>BtcOley, bu web sitesinde yer alan bütün hizmet ve ürünleri, sayfaları, bilgileri, görsel unsurları ve sair verileri önceden bildirimde bulunmadan değiştirme hakkını saklı tutar.</p>

            </div>
        </div>
    )
}

export default TabActive;