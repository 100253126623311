
const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>MAGICIAN OF META TEKNOLOJİ A.Ş. Kişisel Verilerin Korunması Kanunu Kapsamında Açık Rıza Beyanı</h4>
                <p>Magician of Meta Teknoloji A.Ş. (“BtcOley veya Şirket”) tarafından, 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) ilgili hükümlerine uygun olarak bilginize sunulan Kişisel Verilerin Korunması Kanunu Kapmasında Genel <strong>Aydınlatma Metni</strong>  ve Şirket Kişisel Verilerin Korunması ve İşlenmesi çerçevesinde,</p>
                <p>Kişisel verilerin <strong>Veri Sorumlusu</strong>  sıfatıyla, Şirket veya gerekli güvenlik tedbirlerini aldırmak suretiyle yetkilendirildiği veri işleyenler tarafından; Şirket’in müşterilerine ve kullanıcılarına sunmuş olduğu ürün ve hizmetlerini en iyi koşullar altında sağlayabilmesi, ürün veya hizmetlerin güvenilir ve kesintisiz bir şekilde temin edilmesi, müşteri memnuniyetinin en üst seviyeye çıkarılması, ödemelerin yapılması, mezkûr hizmetlere ilişkin çeşitli işlemlerin yerine getirilmesi, operasyonların yürütülmesi ve geliştirilmesi, mezkûr ürün ve hizmetlerin veya farklı ürün ve hizmetlerin tanıtım, pazarlama, reklam ve kampanya faaliyetlerinin yapılması, müşterilerin/kullanıcıların fırsatlardan, kampanyalardan ve hizmetlerden haberdar edilmesi ve müşterilerle/kullanıcılarla akdedilen sözleşmelerin gereklerinin yerine getirilmesi amaçlarıyla doğrudan veya dolaylı olarak ilgili olan kimlik bilgilerinin, adres bilgilerinin, iletişim bilgilerinin ve sair kişisel verilerin; başta mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme ilkesi olmak üzere 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 4 maddesinde ifade edilen genel ilkelere uygun şekilde işlenebileceğini; elde edilebileceğini, kaydedilebileceğini, işlenme amacıyla uygun süre zarfında fiziksel veya elektronik ortamda güvenli bir şekilde depolanabileceğini, muhafaza edilebileceğini, değiştirilebileceğini, yeniden düzenlenebileceğini, mevzuata uygun biçimde açıklanabileceğini ve aktarılabileceğini, devralınabileceğini, sınıflandırılabileceğini, işlenebileceğini ya da verilerin kullanılmasının engellenebileceğini; yukarıda belirtilen hususlarla ilgili olarak Şirket tarafından bilgilendirildiğimi ve KVKK çerçevesinde açık rızam bulunduğunu kabul ve beyan ederim.</p>
                <p>İşbu kişisel verilerimin, yukarıda belirtilen amaçlarla bağlı kalmak kaydıyla, Şirket tarafından; Şirket çalışanlarına, görevlilerine, grup şirketlerine (Şirket ve/veya iş ortaklarına, hissedarlarına), kanunen yetkili kamu kurum ve kuruluşlarına, faaliyetlerini yürütebilmek amacıyla, hukuki zorunluluklar ve yasal sınırlamalar çerçevesinde bağımsız denetim şirketlerine, anket şirketlerine, tarafıma verilecek hizmetlerin ve/veya faaliyetlerin yürütülmesi için Şirket’in hizmet aldığı veya birlikte çalıştığı iş ortaklarına ve hizmet sağlayıcılarına aktarılabileceğini ve bu hususta açık rızam olduğunu kabul ve beyan ederim.</p>
                <p>Bununla birlikte, KVKK’nın 11.maddesi ve ilgili mevzuat uyarınca Şirket’e başvurarak kendimle ilgili; kişisel veri işlenip işlenmediğini öğrenme, kişisel verilerim işlenmişse buna ilişkin bilgi talep etme, kişisel verilerimin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, işbu verilerin işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerimin silinmesini veya yok edilmesini isteme, bu düzeltme ve silinme taleplerinin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendi aleyhime bir sonucun ortaya çıkmasına itiraz etme, kişisel verilerimin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarımın olduğunu ve bu hakları kullanmak için kimliğimi tespit edici gerekli bilgiler ile kullanmayı talep ettiğim hakkıma yönelik açıklamaları da içeren talebimi www.btcoley.com adresindeki formu doldurarak ve formun imzalı bir nüshasını Gülbahar Mah. Cemal Sahir Sk. Urfalı İşhanı No: 10-B Kat :4 Daire :8 Şişli/İstanbul adresinde bulunan Şirket Müdürlüğü’ne kimliğimi tespit edici belgeler ile bizzat elden iletme yahut noter kanalıyla veya KVKK’da belirtilen diğer yöntemler ile iletme hakkına sahip olduğumu kabul ediyorum.</p>
                <p>Ayrıca, Şirket ile paylaşmış olduğum kişisel verilerin doğru ve güncel olduğunu; işbu bilgilerde değişiklik olması halinde değişiklikleri Şirket’e bildireceğimi kabul ve beyan ederim.</p>
                <p>KVKK’da tanımlanan özel nitelikli kişisel verilerim de dahil olmak üzere ilgili kişisel verilerimin işlenmesine, ilgili süreç kapsamında işlenme amacı ile sınırlı olmak üzere kullanılmasına ve paylaşılmasına, gereken süre zarfında saklanmasına açık rızam olduğunu ve bu hususta tarafıma gerekli aydınlatmanın yapıldığını; işbu metni, Şirket Kişisel Verilerin Korunması ve İşlenmesi Yönetmeliğini ve Aydınlatma Metnini OKUDUĞUMU ve ANLADIĞIMI ; Kabul Ediyorum/Kabul Etmiyorum.</p>
            </div>
        </div>
    )
}

export default TabActive;