import blockchain from '../images/blockchain.png';
import blockchain2 from '../images/blockchain2.png';
import merkle from '../images/merkle.png';
import hashparmakizi from '../images/hashparmakizi.png';
import dijitalimza from '../images/dijitalimza.png';

const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>Blockchain</h4>
                <p>Blok-zincir (blockchain) basit tanımı ile bir veri saklama yöntemidir. Herkese açık, şeffaf,
                    dağıtık, sıralı ve zaman damgalı, şifrelenmiş şekilde veri yönetimi sağlayan, dijital küresel
                    hesap defteridir, düz bir veritabanıdır.</p>
                <p>Bu veri tabanlarının bazı özellikleri vardır. İlki, blockchainlerin salt eklenir oluşudur. Bu,
                    bilgileri yalnızca ekleyebileceğiniz anlamına gelir. Eklediğiniz bir şeyin bulunduğu hücreyi seçip
                    öylece silemez veya herhangi bir şekilde değiştiremezsiniz. Örnek verecek olursak günümüzde sosyal
                    medyada paylaştığınız herhangi bir bilgiyi, sosyal medya tek bir kuruma bağlı olduğu için
                    silebilirsiniz; ancak blockchain tabanlı herhangi bir sosyal medya kanalında yer alan bir bilgi
                    silinemez ya da değiştirilemez.</p>
                <p>İkincisi, veri tabanındaki her bir girişin (blok olarak adlandırılır) kriptografik olarak en son
                    girişe bağlanmasıdır. Daha basit ifade etmek gerekirse, her yeni giriş en sonuncunun bir tür
                    dijital parmak izini (Hash) içermelidir.
                    Her parmak izi bir öncekini işaret ettiğinden, bloklardan oluşan bir zincir elde etmiş olursunuz
                    ya da diğer tabiri ile bir blok zinciri (blockchain) elde edersiniz.
                    Blockchain’in ortaya çıkması, yeni bir dijital varlık olarak Bitcoin ve diğer kripto paralar için
                    kesinlikle çok önemlidir.
                </p>
                <p>Blockchain tarihi oldukça eskilere dayanır. Kriptograf David Chaum ilk olarak 1982 tarihli
                    "Karşılıklı Şüpheli Gruplar Tarafından Kurulan, Sürdürülen ve Güvenilen Bilgisayar Sistemleri"
                    adlı tezinde blok zinciri benzeri bir protokol önerdi. Scott Stornetta belge zaman damgalarının
                    değiştirilemeyeceği bir sistem uygulamak istediler. 1992'de Haber, Stornetta ve Dave Bayer, Merkle
                    ağaçlarını tasarıma dahil etti ve bu, birkaç belge sertifikasının tek bir blokta toplanmasına izin
                    vererek verimliliğini artırdı.</p>
                <p>Blok-zincir değerli varlıkların kayıt altına alınarak saklanmasından, nüfus,doğum ve ölüm
                    kayıtlarının tutulmasına, akıllı kontratlardan mali dokümanların kayıt altına alınmasına ve
                    yönetimine kadar çok farklı alanda kullanılmaktadır.</p>
                <p>Örnek vermek gerekirse;</p>
                <p>Bankacılık ve finans</p>
                <p>Finansal teknolojiler (Fintech)</p>
                <p>Para transferleri</p>
                <p>Değerli belgelerin oluşturulması ve muhafaza edilmesi</p>
                <p>Sağlık ve ilaç sektörü</p>
                <p>Hisse senetleri ve borsalar</p>
                <p>Elektronik noter (Akıllı sözleşmeler)</p>
                <p>Kişiden kişiye borçlanma ve dağıtık yapılı kredi yöntemleri</p>
                <p>Bağış sistemleri</p>
                <p>Mikro ödemeler</p>
                <p>Bulut bilişim</p>
                <p>Güvenli bulut depolama.</p>
                <p>Temel olarak, blockchain yapısı genel bir kayıt defteri ya da veritabanı gibi bir kayıtlar
                    bütünüdür. Blokchain’in eşsizliği bu kayıtları doğrulamak ve korumak için kullandığı mekanizmada
                    gizlidir.
                </p>
                <img style={{ width: "550px" }} src={blockchain} alt="blockchain-images" />
                <img style={{ width: "450px" }} src={blockchain2} alt="blockchain-images" />
                <p style={{ fontStyle: "italic" }}>Görseller örnektir.</p>
                <h4>Hash (Dijital parmak izi)</h4>
                <p>Hash, farklı uzunluktaki dijital mesajlardan, sabit uzunlukta bir mesaj özeti çıkartır. Özet
                    fonksiyonu, dijital parmak izi olarak da isimlendirilen kodlardır. SHA-256 (Secure Hash
                    Algorithm), SHA-2 altındaki 6 kriptografik hash algoritma setinden bir tanesidir. ABD’nin
                    kriptoloji üzerine uzman Ulusal Güvenlik Ajansı (NSA) tarafından geliştirilmiştir. SHA-256,
                    bitcoin madenciliğindeki proof-of-work hesaplamalarında ve bitcoin adresi oluşturma işlemlerinde
                    kullanılır. Bilinen kriptolama fonksiyonları arasında en yüksek güvenliğe sahip olanlardan
                    biridir.</p>
                <p>Bitcoin işlemlerinde SHA-256 isimli özet fonksiyonu kullanılır. SHA-256, girilen mesajın
                    uzunluğundan bağımsız, 256-bit (32 byte) 64 karakter mesaj özeti oluşturur.</p>
                <p>Her bloğun içinde bulunan üçüncü unsur önceki bloğun hashıdır. Biribirlerine bağlı olarak blok
                    zinciri oluştururlar.
                    Herhangi iki farklı girdinin aynı hashı çıktı olarak üretmesi mümkün değildir. Çakışmaya izin
                    vermez.
                    Hash fonksiyonu geri döndürülemez. Verilen bir çıktıdan girdiyi bulmak mümkün değildir.</p>
                <p>Birbirine çok benzer verilerin sonuçları birbirinden tamamen farklıdır. Yani hash değerlerinin
                    önceden tahmin edilmesi imkansızdır.</p>

                <p>Aynı veri üzerindeki hash değerlerinin hesaplaması her zaman aynı sonucu verecektir. Örnekleri
                    inceleyelim;</p>
                <img style={{ width: "550px" }} src={hashparmakizi} alt="hashparmakizi" />
                <h4>Merkle Ağacı (Merkle Tree) - Merkle Kökü (Merkle Root)</h4>
                <p>Merkle ağacı, açık anahtar kriptografisi üzerine çalışmalarıyla tanınan bilgisayar mühendisi
                    Ralph Merkle tarafından 1980'lerin başında ortaya atılmıştır.</p>
                <p>Merkle ağaçları bilgisayarlar arası transfer edilen ya da bilgisayarlarda saklanan, işlenen her
                    türlü verinin doğrulanmasında kullanılabilir. Eşler arası (P2P) ağlarda alınan veri bloklarının
                    hasarsız, değiştirilmemiş ve hatta sahte olup olmadığını anlamamıza yardımcı olurlar. Hash
                    fonksiyonları, Merkle ağacı yapılarının merkezinde yer alır.
                    Bir blok içerisindeki tüm işlemler ikişerli gruplar halinde özetlenir, ortaya çıkan özetler yine
                    kendi aralarında ikişerli gruplar halinde tekrar özetlenir. Bu işlem tek bir özet elde edene kadar
                    devam ettirilir. İşlemlerin ikişerli özetlenmelerinden oluşan ağaç yapısına Merkle Ağacı, sonuçta
                    ulaşılan tek özete ise Merkle Kökü denir.</p>
                <p>Merkle ağacı sistemi, veri depolamada hız, verimlilik ve güvenlik açısından oldukça yüksek
                    avantaj sağlar. Veriler, Merkle ağacının kökü ile eşleştiğinde gizliliği korunarak oldukça hızlı
                    bir şekilde kontrol edilebilir. Blockchainin merkeziyetsiz olma özelliği sayesinde de bu verilerin
                    değiştirilmesi ya da veri ekleme, çıkarma olasılığı imkansız hale gelir. Bu yüzden blok-zincir
                    ağında önemli bir görevi üstlenir.</p>
                <img style={{ width: "650px" }} src={merkle} alt="merkle-image" />
                <p style={{ fontStyle: "italic " }}>Görseller örnektir. </p>
                <h4>Dijital İmza - Private Key(Gizli Anahtar) - Public Key(Açık Anahtar)</h4>
                <p>Dijital imza, gizli anahtar (private key) ve açık anahtar (public key) ikilisiyle çalışan,
                    matematiksel olarak güvenilirliği ispatlanmış şifreleme yöntemidir. Dijital imza atmak isteyen
                    herkesin kendine ait, gizli ve açık anahtarı olması gereklidir. Gizli anahtarla şifrelenen bir
                    mesaj, sadece şifreleyene ait açık anahtarla çözülebilir. Gizli anahtar adı üzerinde gizlidir,
                    sadece imzalayan kişide bulunur, kimseyle paylaşılmaması gerekir. Açık anahtarın dağıtılmasında,
                    hatta imzalı mesaja ek olarak gönderilmesinde hiç bir mahsur yoktur.</p>
                <p>Açık anahtar ile şifrelenen veri gizli anahtar ile, gizli anahtar ile şifrelenen veri ise açık
                    anahtar ile deşifre edilebilir. İletişime geçilmek istenen kişinin açık anahtarı ile şifrelenen
                    veri sadece ilgili kişinin gizli anahtarı ile deşifre edilebileceği için güvenli bir şekilde
                    ilgili kişiye iletilebilir.</p>
                <p>Gizli anahtarla yapılan şifreleme işlemine imzalama denir. Hızlı olması ve fazla yer kaplamasının
                    önüne geçilmesi için genellikle, mesajın önce özeti çıkartılır, özet(hash) imzalanır, yani
                    şifrelenir.</p>
                <img style={{ width: "550px" }} src={dijitalimza} alt="dijitalimza-images" />
                <h4>Proof of Work / Proof of Stake</h4>
                <p>Proof of Work (İş İspatı); Kısaca, bir ağdaki madenciler karışık sayısal bulmacaları çözme
                    konusunda birbirleriyle yarışırlar.
                    Bu bulmacaların çözümleri zordur fakat doğru çözümlerin doğruluğunu onaylamak kolaydır. Madenci
                    bulmacanın çözümünü bulduğunda, bloğu ağa yayınlayabilir hale gelir ve ağdaki diğer tüm madenciler
                    çözümün doğru olduğunu onaylarlar.</p>
                <p>2009 yılında Bitcoin, Proof of work’u işlemlerin doğrulanması ve blockchaine yeni bloklar
                    eklenmesinde bir mutabakat algoritması olarak kullanmaya başladı. Proof of work sistemi yeni,
                    inovatif bir kullanım alanı başlatmış oldu.</p>
                <p>Proof of Stake (Hisse İspatı); Proof of stake algoritması, sıradaki bloğun onaylayıcısı olacak
                    düğümü (node) belirlemek için hisse yaşı, rastlantısallık ve düğümün sahip olduğu coin miktarı
                    gibi faktörlerin kombinasyonlarından oluşan sözde-rastgele bir eleme süreci kullanır.</p>
                <p>Proof of Stake algoritmasının en temel avantajları enerji verimliliği ve güvenliktir. Daha fazla
                    sayıda kullanıcı, kolay ve düşük maliyetli olduğu için node olmaya teşvik edilmektedir. Bununla
                    birlikte, sürecin rastgele hale getirilmesi blokların kazılması için maden havuzlarını gereksiz
                    kıldığından ağı daha dağınık (merkeziyetsiz) bir hale getirmektedir. Ödül olarak verilmek üzere
                    yeni coin oluşturulmaya daha az ihtiyaç duyulması da söz konusu coinin fiyatının daha sabit
                    kalmasına yardımcı olmaktadır.</p>
                <h4>Fork (Çatal)</h4>
                <h4>Hard Fork(Sert Çatallanma)- Soft Fork (Yumuşak Çatallanma)</h4>
                <p>Fork; Blok-zincir kaynak kodunda bir iyileştirme yapılması ve bu gelişmenin iki farklı koldan
                    devam ettirilmesinde ortaya çıkan çatallanmadır. İşlemler farklı bloklardan devam ettirilir.</p>
                <p>Hard Fork; Daha önce geçersiz olan blokları veya işlemleri geçerli kılan (ya da tam tersi)
                    protokolde gerçekleşen değişikliğe verilen isimdir. Böyle bir durumda tüm düğümlerin (node) veya
                    kullanıcıların protokol yazılımının en güncel sürümüne geçmesi gerekmektedir. Başka bir deyişle
                    hard fork, bloğun önceki sürümünden kalıcı bir şekilde ayrılmasıdır. (Örnek: Bitcoin'den hard
                    forkla ayrılan Bitcoin Cash ve Bitcoin Gold)</p>
                <p>Soft Fork; yeni sürümleri yükseltmek ve kabul etmek için tüm düğümlerin kullanılmasını gerektiren
                    hard forkun aksine, yeni kuralların yürürlüğe girmesini sağlamak için yalnızca madencilerin
                    çoğunluğunun onayını gerektirir. Blok-zincir üzerindeki oluşan farklılık önceki bloklarla uyumlu
                    ise mutabakat sağlanabilmesi için %50+1 oy gerekir.</p>

            </div>
        </div>
    )
}

export default TabActive;