import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Tab10 = () => {

    React.useEffect(() => {
        const elements = document.getElementsByClassName('beforenone');
        console.log(elements.length)
        if (elements && elements.length > 0) {
            for (let i = 0; i < elements.length; i++)
                elements[i].classList.remove('css-1elwnq4-MuiPaper-root-MuiAccordion-root');
        }
    }, []);

    return (
        <>
            {/* <div data-w-tab="Tab 10" class="tab-pane-tab-1">
            <div class="tab-pane-tab-2 ">
                <span class="accordion contents-rich-text-block1 w-richtext">BtcOley hesabıma hangi yöntemlerle Türk
                    Lirası yatırabilir ve çekebilirim?<svg data-accordion-icon="true" class="w-6 h-6 shrink-0"
                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"></path>
                    </svg></span>
                <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                    <p>Türkiye’de faaliyet gösteren bankalar ile yalnızca kendi adınıza ait vadesiz bir banka hesabı
                        kullanarak, Havale veya EFT yoluyla Türk Lirası yatırabilir ve çekebilirsiniz.</p>

                </div>
            </div>
            <div class="tab-pane-tab-2 ">
                <span class="accordion contents-rich-text-block1 w-richtext">Nasıl Türk Lirası yatırabilirim?<svg
                    data-accordion-icon="true" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg></span>
                <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                    <p>BtcOley hesabınıza, Türk Lirası yatırmak için YATIR sayfasına geçiniz.</p>
                    <p>Türk Lirası yatırmak istediğiniz banka hesabımızı seçerek, ilgili bankaya ait IBAN ve Hesap Adı
                        bilgilerini, mobil bankacılık uygulamanız üzerinden QR kod okutarak veya banka bilgilerini
                        SMS’le al butonunu kullanarak alabilirsiniz.</p>

                    <p>Kendi adınıza ait vadesiz banka hesaplarınızdan, Havale veya EFT yoluyla Türk Lirası
                        yatırabilirsiniz.</p>

                </div>
            </div>
            <div class="tab-pane-tab-2 ">
                <span class="accordion contents-rich-text-block1 w-richtext">Nasıl Türk Lirası çekebilirim?<svg
                    data-accordion-icon="true" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg></span>
                <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                    <p>BtcOley hesabınızdan, kendi banka hesabınıza Türk Lirası gönderebilmek için ÇEK sayfasına
                        geçiniz.</p>
                    <p>Türk Lirası göndermek istediğiniz banka hesap bilginizi seçerek, çekmek istediğiniz TL
                        miktarını belirtiniz.</p>

                    <p>"TALEP OLUŞTUR" butonuna basarak, işleminizi gerçekleştirebilirsiniz.</p>

                </div>
            </div>
        </div> */}
            <div style={{ padding: '22px 30px 13px', borderRadius: "20px", background: 'white' }}>
                <Accordion style={{ border: "none", borderRadius: "30px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon  style={{color: "#fff"}}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: "#4590ce", margin: 0, borderRadius: '20px', }}>
                        <Typography style={{ color: "white" }}>BtcOley hesabıma hangi yöntemlerle Türk
                            Lirası yatırabilir ve çekebilirim?</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 0 }}>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                            <p style={{color: '#4590ce', marginTop: "10px"}}>Türkiye’de faaliyet gösteren bankalar ile yalnızca kendi adınıza ait vadesiz bir banka hesabı
                                kullanarak, Havale veya EFT yoluyla Türk Lirası yatırabilir ve çekebilirsiniz.</p>

                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={"beforenone"} style={{ marginTop: '15px', borderRadius: "25px", }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ background: "#4590ce", borderRadius: '20px', }} >
                        <Typography style={{ color: "white", margin: 0 }}>Nasıl Türk Lirası yatırabilirim?</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 0 }}>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                            <p style={{color: '#4590ce', marginTop: "10px"}}>BtcOley hesabınıza, Türk Lirası yatırmak için YATIR sayfasına geçiniz.</p>
                            <p style={{color: '#4590ce',}}>Türk Lirası yatırmak istediğiniz banka hesabımızı seçerek, ilgili bankaya ait IBAN ve Hesap Adı
                                bilgilerini, mobil bankacılık uygulamanız üzerinden QR kod okutarak veya banka bilgilerini
                                SMS’le al butonunu kullanarak alabilirsiniz.</p>

                            <p style={{color: '#4590ce',}}>Kendi adınıza ait vadesiz banka hesaplarınızdan, Havale veya EFT yoluyla Türk Lirası
                                yatırabilirsiniz.</p>

                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={"beforenone"} style={{ marginTop: '15px', borderRadius: "25px", }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "#fff"}} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ background: "#4590ce", margin: 0, borderRadius: '20px' }} >
                        <Typography style={{ color: "white", margin: 0 }}>Nasıl Türk Lirası çekebilirim?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div class="panel tab-pane-tab-12 contents-rich-text-block1 w-richtext ">
                            <p style={{color: '#4590ce', marginTop: "10px"}}>BtcOley hesabınızdan, kendi banka hesabınıza Türk Lirası gönderebilmek için ÇEK sayfasına
                                geçiniz.</p>
                            <p style={{color: '#4590ce'}}>Türk Lirası göndermek istediğiniz banka hesap bilginizi seçerek, çekmek istediğiniz TL
                                miktarını belirtiniz.</p>
                            <p style={{color: '#4590ce'}}>"TALEP OLUŞTUR" butonuna basarak, işleminizi gerçekleştirebilirsiniz.</p>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default Tab10;