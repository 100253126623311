const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>Risk Bildirim Formu</h4>
                <p><strong>BtcOley,</strong>Kripto Varlık alım-satım platformunu işletir. Kripto Varlık alım-satım işlemlerinize aracılık eder. Herhangi bir fiyat garantisi veya taahhüdünde bulunmaz. BtcOley, kendi bünyesinde geliştirilen Kripto Varlıklar hariç herhangi bir Kripto Varlığın hak sahibi değildir.</p>
                <p><strong>BtcOley,</strong>nezdinde açtıracağınız hesap ve bu hesap üzerinden gerçekleştirilecek tüm işlemler için yasal düzenleme hükümleri uygulanacaktır. Mevzuat, düzenleme ve uygulamalarda zaman zaman değişiklik yapılması mümkün olup, meydana gelebilecek değişiklikleri takip etmeniz önemlidir.</p>
                <p>Kripto Varlık piyasalarında yapacağınız işlemler sonucunda kâr elde edebileceğiniz gibi zarar etme riskiniz de bulunmaktadır. Bu nedenle, işlem yapmaya karar vermeden önce, piyasada karşılaşabileceğiniz riskleri anlamanız, mali durumunuzu ve sınırlamalarınızı dikkate alarak karar vermeniz gerekmektedir.</p>
                <p>Piyasada oluşacak fiyat hareketleri sonucunda yatırdığınız paranın tümünü kaybedebilirsiniz.
                </p>
                <p>Kripto Varlıklara ilişkin olarak, siber saldırı düzenlenmesi, çalınma, çeşitli güvenlik ihlallerinin yaşanması, piyasaya erişimin kısıtlanması, elektronik veya teknolojik arızalar yaşanması, network programının son bulması, geliştiricilerin projeden çekilmesi, kripto varlıkların değer kaybetmesi riskleri bulunmaktadır. Bu nedenle, işlem yapmaya karar vermeden önce, kripto para piyasasında karşılaşabileceğiniz riskleri anlamalı, mali durumunuzu ve sınırlamalarınızı dikkate alarak karar vermelisiniz.</p>

                <p>Bu Form sizi genel bazı riskler hakkında bilgilendirmeyi amaçlamakta olup, Kripto Varlıkların alım-satımı ve transferi, Piyasa Riski, Likidite Riski, Yasal Risk, Cüzdan Riskleri, işlem yapacağınız Kripto Varlığa ilişkin özel riskler, uygulamadan kaynaklı riskler ile Kullanım Sözleşmesi'nde tanımlanan hizmetlerden kaynaklanabilecek tüm riskleri kapsamayabilir. İşlem yapacağınız Kripto Varlığa ilişkin riskleri araştırılarak öğrenmenizi, ilgili Kripto Varlığın “whitepaper” adı verilen tanıtım dokümanını incelemenizi ve devamında uygun bulmanız halinde işlemler yapmanızı öneririz.</p>
                <p>Kripto Varlıklar, ulusal para birimleri, emtia, menkul kıymetler veya diğer finansal enstrümanlara ilişkin geleneksel olarak bilinen risklerden farklılık arzederek kendilerine has özel riskler barındırırlar. Ulusal para birimlerinden farklı olarak devletler veya resmi kurum/kuruluşlar tarafından tedavüle çıkartılmamakta, menkul kıymetlerden farklı olarak devletler veya resmi/özel kurum/kuruluşlar tarafından ihraç edilmemekte ve aksi bildirilmediği sürece arkasında dayanak varlık bulunmamakta, emtialardan farklı olarak fiziksel varlığı bulunmamaktadır.
                    Kripto Varlık işlemlerinizde likidite garantisi yoktur. İlgili Kripto Varlığın cinsine, ilgili Kripto Varlığın işlem gördüğü ağa (network) veya <strong>BtcOley</strong> platformunda oluşan piyasa koşullarına göre ilgili Kripto Varlık nezdinde likidite artabileceği gibi düşebilir veya Kripto Varlığınızı satabileceğiniz likit bir piyasa bulunmayabilir.
                    Kripto Varlıkların işlem gördüğü ağın son bulması, geliştiricilerinin projeden çekilmesi, onaylayıcıların ağdan çekilmesi, Kripto Varlıkların ve/veya bunlara bağlı olarak yapılan işlemlerin herhangi bir devlet/resmi/idari otoriteler veya yasal merciler tarafından yasaklanması, tedbir uygulanması, engellenmesi gibi riskler bulunmaktadır.
                    Şarta bağlı emirlerinizin “Limit Emir” veya “Stop Emir” gibi piyasada oluşacak koşullara göre gerçekleşmeme olasılığı bulunmakta olup, aynı zamanda bu emir tiplerinin kayıplarınızı sınırlama/limitleme olasılığı veya garantisi de bulunmamaktadır.</p>
                <p>Yabancı para cinsine dayalı Kripto Varlıklarda, yukarıda sayılan risklere ek olarak kur riskinin olduğunu, kur dalgalanmaları nedeniyle değer kaybı olabileceği, devletlerin yabancı sermaye ve döviz hareketlerini kısıtlayabileceği, yeni vergiler getirebileceği bilinmelidir.</p>
                <p><strong>BtcOley,</strong>işlem yaptığınız Kripto Varlıkların her zaman piyasada işlem görmeye devam edeceği konusunda herhangi bir beyan ve taahhütte bulunmaz. Listelenen herhangi bir Kripto Varlık, bildirimde bulunmaksızın listeden çıkarılabilir veya işleme kapatılabilir.</p>
                <p><strong>BtcOley,</strong>herhangi bir Kripto Varlık için aktif ve likit olacağına dair bir garanti vermemekte veya taahhütte bulunmamaktadır. Kripto Varlıkların yasalara uygunluğuna ilişkin herhangi bir tavsiyede bulunmaz, yasalara ilişkin uygulamayı bilmek ve anlamak Sizin yükümlülüğünüzdedir.</p>
                <p><strong>BtcOley,</strong>nezdinde tutulan Kripto Varlıklarınız platformda diğer kullanıcılarının Kripto Varlıkları ve/veya BtcOley 'ın kendi Kripto Varlıkları ile birlikte tutulabilir, karışabilir ve mislen iade edilebilir.</p>
                <p><strong>BtcOley,</strong>Kripto Varlıkların kesintisiz ve her an ulaşılabilir şekilde sunulacağına, Kullanıcı'nın herhangi bir sistem ve donanımı ile uyumlu olduğuna veya uyumlu kalacağına veya kesintisiz çalışacağına ilişkin herhangi bir taahhüt ve garantide bulunmamakta, listelenen Kripto Varlığa ilişkin piyasa performansını kısmen ve/veya tamamen engelleyecek veya önleyecek nitelikte tavan-taban fiyat uygulaması, zorunlu limit emri uygulaması, piyasa emirlerinin kapatılması, maksimum emir değeri sınırı gibi tedbirler alma ve uygulama hakkına sahiptir.</p>
                <p><strong>BtcOley,</strong>cüzdan hizmeti vermemekte olup, işlem yaptığınız (Alım,Satım,Transfer) Kripto Varlıklara ilişkin hakların korunması için gerekli bütün işlemlerin tarafınızca yapılması gerekmektedir.</p>
                <p><strong>BtcOley,</strong>şüpheli bir faaliyette bulunduğunuza, yasalara veya Kullanım Sözleşmesi'ne aykırı davrandığınız yönünde makul bir şüphe duyması halinde veya meşru nedenlerle; belirli bir süreyle veya süresiz şekilde kısmen veya tamamen hesabınızı dondurabilir, hesabınızda transferleri kısmen ve/veya tamamen engelleyebilir, hesabınızı kapatabilir, hesabınızda bulunan nakit, veya kripto varlıklarınıza resmi kuruluşlarca yapılacak bildirim üzerine tedbir uygulamak suretiyle kullanımını engelleyebilir. Bu halde, hesabınızda işlem gerçekleştiremez ve hesabınızdan transfer yapamazsınız.</p>
                <p>Yazılım ve internet bağlantı hatası dahil, ancak bunlarla sınırlı olmamak kaydıyla, internet tabanlı bir alım satım sisteminin kullanılmasıyla ilişkili riskler mevcut olup, platformu kullanırken sebebi ne olursa olsun karşılaşabileceğiniz iletişim arızalarından, kesintilerinden, hatalardan, aksaklıklardan veya gecikmelerden <strong>BtcOley</strong> sorumlu değildir.</p>
                <p>Kripto Varlık transfer işlemleriniz gerçekleştirildikten sonra iade edilemez ve geri döndürülemez. Bu nedenle, transferi gerçekleştireceğiniz cüzdan adresinin doğruluğunu kontrol etmek Sizin yükümlülüğünüzdedir.
                    İşlemlerinize başlamadan önce, ödeyeceğiniz tüm komisyonlar, diğer ücretler ve masraflar hakkında bilgi almanız ve mutabık olmanız gerekmektedir. Ücretler ve masraflar parasal olarak ifade edilmiyorsa, söz konusu ücretlerin ve masrafların parasal olarak size nasıl yansıtılacağını gösteren anlaşılır örnekler içeren yazılı bir açıklama veya beyan talep edebilirsiniz.</p>
                <p>şbu Risk Bilgilendirme Formu, <strong>BtcOley</strong> (Magician of Meta TEKNOLOJİ A.Ş.) ile imzaladığınız/onayladığınız Kullanım Sözleşmesi'nin eki ve ayrılmaz bir parçasıdır.</p>
            </div>
        </div>
    )
}

export default TabActive;