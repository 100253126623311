

const Tab2 = () => {

    return (
        <div class="tab-pane-tab-1 ">
            <div class="contents-rich-text-block w-richtext">
                <h4>Misyonumuz</h4>
                <p>Türkiye’de Blockchain ile Ödeme Sistemlerini bir arada uygulayan ilk ve tek Kripto Varlık
                    Platformu olarak, milisaniyeler seviyesinde işlem yapan Takas Merkezimiz sayesinde müşterilerine
                    ve yatırımcılara en ileri seviyedeki teknolojik altyapımızla hızlı, güvenli ve kesintisiz hizmet
                    vermek.</p>
            </div>
        </div>
    )
}

export default Tab2;