
const TabActive = () => {
    return (
        <div className="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div className="contents-rich-text-block w-richtext">
                <h4>Teknik Analiz & Analiz Yöntemleri</h4>
                <h5>Analiz Nedir?
                </h5>
                <h5>Temel Analiz</h5>
                <p>Temel analiz gerçek rakamlar üzerinden yapılan yorumlara dayanır. Öncelikle dünyanın ve ülkelerin ekonomik durumunu inceler, ardından hangi sektör ile ilgileniliyorsa o sektörün analizini yapar.
                    Sektördeki şirketler ve pazar payları, yatırım ve karlılık durumları, satış potansiyeli gibi unsurlar dikkate alınır ve incelenir. Genelden özele giden bir araştırma metodu ile temel analiz yapılmış olur. Temel analiz, şirketi ve sektörü tanımak için önemlidir.</p>
                <p>Şirket yönetimi ve itibarı, pazar rekabeti, büyüme oranları ve endüstri sağlığı gibi çok çeşitli mikro ve makro ekonomik koşullara dayalı olarak bir şirketin, işletmenin veya varlığın gerçek değerini tahmin etmek için kullanılan bir yöntemdir.</p>
                <p>Teknik Analizin aksine, Temel Analiz bir varlığın içeriğine ve potansiyeline göre aşırı değerlenip değerlenmeyeceğini belirlemek için bir yöntem olarak kullanılır.
                </p>
                <h5>Teknik analiz;</h5>
                <p>Teknik analiz temel olarak bir varlığın cari ve önceki fiyatlarının incelemesidir. Teknik analizin altında yatan ana varsayım, bir varlığın fiyatındaki dalgalanmaların rastgele olmadığı ve genellikle zaman içinde tanımlanabilir eğilimlere dönüşeceğidir.</p>

                <p>Esasen, teknik analiz, genel piyasa hassasiyetinin bir temsili olan arz-talep gibi piyasa güçlerinin analizidir. Başka bir deyişle, varlığın fiyatı, karşıt satış ve alım güçlerinin yansımasıdır ve bu güçler, al-sat yapan kişilerin duygusal davranma eğilimleri ile yakından ilgilidir.
                </p>
                <p>Teknik analiz çoğunlukla kısa vadeli yatırımcılar tarafından yapılırken, temel analiz fon yöneticileri ve uzun vadeli yatırımcılar tarafından tercih edilmektedir. Temel ve teknik analiz birbirlerinin karşıtı değil, tamamlayıcısıdır.</p>
            </div>
        </div>
    )
}

export default TabActive;