
const TabActive = () => {
    return (
        <div class="tab-pane-tab-1 w-tab-pane w--tab-active">
            <div class="contents-rich-text-block w-richtext">
                <h4>Kullanıcı Sözleşmesi</h4>
                <h5>MADDE 1 : TARAFLAR</h5>
                <ol>
                    <li>İşbu Kullanıcı Sözleşmesi (<strong>“Sözleşme”</strong> )  Magician of Meta Teknoloji A.Ş. (<strong>“BtcOley”</strong> ) ile www.btcoley.com web sitesine (<strong>“Site”</strong> ) üye olan <strong>Kullanıcı</strong> arasında , Site’ nin bulunduğu elektronik ortamda, Kullanıcı   tarafından  üye olunması anında ve sonrasında, Site' de sunulan hizmetleri kullanırken karşılıklı hak ve yükümlülükleri belirtmek amacı ile düzenlenmiştir.</li>
                    <li>Site’ ye üye olarak, Sözleşme’nin tamamını okuduğunuzu, içeriğini bütünü ile anladığınızı ve tüm hükümlerini onayladığınızı kabul, beyan ve taahhüt etmektesiniz.</li>
                </ol>

                <h5>MADDE 2- TANIMLAR</h5>
                <ol>
                    <li><strong>Site :</strong>BtcOley alan adından ve bu alan adına bağlı alt alan adlarından oluşan web sitesi.</li>
                    <li><strong>Kullanıcı: </strong>Sözleşme’yi onaylayarak Site’ye üye olan ve Site ’nin sunduğu hizmetlerden yararlanan gerçek ve tüzel kişi veya kişilerdir.</li>
                    <li><strong>Kripto Para:</strong>Herhangi bir fiziksel karşılığa sahip olmayan, kriptografi kullanılarak güvenliği sağlanan, takas işlemlerinde kullanılabilen, tamamen dijital, şifrelenmiş sanal para birimidir. Sanal para arzına olanak sağlayan, alternatif para birimidir. Merkezi olmayan bu alternatif para birimi merkezi olmamasından dolayı kontrolü Blok-Zincir (BlockChain) işlem veri tabanları tarafından gerçekleştirilir.</li>
                    <li><strong>BtcOley :</strong>www.btcoley.com adresli Magician of Meta Teknoloji A.Ş.</li>
                </ol>
                <h5>MADDE 3-SÖZLEŞMENİN KONUSU VE KAPSAMI</h5>
                <ol>
                    <li>Sözleşme’nin konusu;  Site ’de sunulan hizmetlerin, bu hizmetlerden yararlanma şartlarının ve tarafların hak ve yükümlülüklerinin tespitinden ibarettir.</li>
                    <li>Sözleşme’nin kapsamı; Kullanıcı, Kişisel Verilerin Korunması, Çerez Politikası ile Site içerisinde yer alan kullanıma, üyeliğe veya hizmetlere ilişkin olarak BtcOley tarafından açıklanan veya açıklanacak olan her türlü beyanı kabul etmiş olmaktadır. Ayrıca bu tür belgelerin tamamı da bu Sözleşme’nin ayrılmaz birer parçası olarak kabul edilmektedir.</li>
                </ol>
                <h5>MADDE 4- ÜYELİK, HİZMET KULLANIMI ŞARTLARI</h5>
                <ol>
                    <li>Kullanıcı sıfatı, Site’ nin ilgili bölümünden üye olmak isteyen Kullanıcı tarafından Site’ den faydalanabilmek adına ve üye olmak için gerekli olan Kullanıcı formunun doldurulması ( ad, soyad, telefon numarası, tc numarası, …vs )ve verdiği bilgilerin doğruluğunun onaylanması ile kazanılır. Kullanıcı formu tamamlanmadan gerçek veya tüzel kişi işbu Sözleşme’de tanımlanan Kullanıcı olma hak ve yetkisini haiz olamaz. Kullanıcı, Site’de hesap oluştururken verdiği kişisel, iletişim ve sair bilgilerinin doğruluğu ile bu bilgilerin yalnızca kendisine ait olmasından bizzat sorumlu olup, bu bilgilerin doğru olmamasından ve/veya başkasına ait olmasından ötürü doğacak tüm zararlardan bizzat kendisi sorumlu olacaktır.</li>
                    <li>Kullanıcı tarafından, Site’de hesap sahibi olmak için bir üye formu doldurulur. İşlem yapmak isteyen Kullanıcı, kimlik bilgilerini belgelemek zorundadır. BtcOley’in talep etmesi halinde Kullanıcı, adres bilgilerini aynı şekilde belgelendirecektir. Aksi takdirde, BtcOley’in işlem yapmamak ya da hesabı durdurma hakkı tek taraflı olarak saklıdır.</li>
                    <li>Kullanıcı’nın üye olurken vereceği bilgilerin doğru olmaması ve/veya başkasına ait olması sebebi ile BtcOley’in uğrayacağı her türlü zarar için BtcOley’in, bu zararları Kullanıcı’ ya her zaman rücu etme ve uğradığı zararın tazminini isteme hakkı vardır. Site’nin bu yönde herhangi bir hakkını kullanmaması, bu hakkın kullanılmasından feragat edildiği şeklinde yorumlanamaz.</li>
                    <li>Kullanıcı’nın Site’ye erişebilmesi ve Site’de sunulan hizmetlerden faydalanabilmesi için her bir Kullanıcı’nın ayrı bir kullanıcı adı ve şifresi olacaktır. Bu kullanıcı ad ve şifresi kullanılarak Site’ye girilmesi halinde BtcOley, Site’ye giriş yapan kişilerin işbu Sözleşme kapsamında Kullanıcı sıfatındaki gerçek veya tüzel kişi olduklarını varsaymaktadır. BtcOley’in yapılan girişlerin kaynağını ve yetkisini araştırma yükümlülüğü bulunmamaktadır. Bu bilgilerin yetkisiz kişiler tarafından ele geçirilmesi suretiyle BtcOley servislerinin kullanılması halinde BtcOley hiçbir sorumluluk kabul etmemektedir.</li>
                    <li>Para yatırma işlemlerinin, Kullanıcı ile aynı ismin altına kayıtlı olan banka hesabından yapılması zorunludur. Aksi koşullarda yapılmak istenen işlemler Site tarafından kabul edilmeyecektir.</li>
                    <li>Herhangi bir banka ATM’si kullanılarak kartsız bankacılık yöntemleri ile transfer yapılması ve bu işlemin tespit edilmesi halinde, BtcOley söz konusu transferleri iade etme hakkına sahiptir.</li>
                    <li>Her Kullanıcı, sadece bir Kullanıcı hesabına sahip olabilir. Kullanıcı, Site'yi sadece işbu Sözleşme' de tanımlanan hizmetlerden faydalanılması amacı ile kullanabilir. BtcOley’in,  aynı kişi tarafından kullanılan birden fazla Kullanıcı hesabının bulunduğunu tespit etmesi halinde  ilgili Kullanıcı hesaplarını ihbarsız olarak sonlandırma/askıya alma hakkı saklıdır.</li>
                </ol>
                <h5>MADDE 5- KULLANICI HAK VE YÜKÜMLÜLÜKLERi</h5>
                <ol>
                    <li>Kullanıcı, üyelik prosedürlerini yerine getirirken ,Site ’nin hizmetlerinden faydalanırken ve Site’deki hizmetlerle ilgili herhangi bir işlemi yerine getirirken, Sözleşme’ de yer alan tüm şartlara, Site’nin ilgili yerlerinde belirtilen kurallara ve yürürlükteki tüm mevzuatlara uygun hareket edeceğini, yukarıda belirtilen tüm şart ve kuralları anladığını ve onayladığını kabul, beyan ve taahhüt eder.</li>
                    <li>Kullanıcı, BtcOley’ın yürürlükteki emredici mevzuat hükümleri gereğince ve resmi makamlara bildirim yapmakla yükümlü olduğu haller çerçevesinde, BtcOley’in kendisinde veya Site’sinde  kayıtlı olan bilgileri resmi yetkili kurum, makam ve otoritelere açıklamaya yetkili olacağını ve bu nedenle BtcOley’den her ne ad altında olursa olsun tazminat talep edilemeyeceğini, kabul, beyan ve taahhüt eder.</li>
                    <li>Kullanıcı’nın Site tarafından sunulan hizmetten yararlanmak amacıyla Site’ye girişte kullandığı “kullanıcı adı” ve “şifre” nin güvenliğini sağlaması, münhasıran ve müstakilen kendisi tarafından kullanılmasını temin etmesi, üçüncü kişilerin bilgisinden uzak tutularak saklanmasını sağlaması tamamen Kullanıcı ’nın sorumluluğundadır. Kullanıcı, kullanıcı adının ve şifresinin güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması, münhasıran ve müstakilen kendisi tarafından kullanılması gibi hususlardaki tüm ihmal ve kusurlarından dolayı diğer Kullanıcılar’ın ve Site’nin  ve/veya üçüncü kişilerin uğradığı veya uğrayabileceği zararlardan sorumludur.</li>
                    <li>Kullanıcı; hesabını ve haklarını üçüncü şahıslara devredemez, satamaz, kendi üyeliğini her ne nam altında olursa olsun başkasına kullandıramaz. Bu durumda BtcOley, Kullanıcı’nın hesabını iptal etme, durdurma, askıya alma haklarına sahiptir. Kullanıcı’nın kripto para varlıkları bu durumdan etkilenmez.</li>
                    <li>Kullanıcı yalnızca kanunlar çerçevesinde, yasal kullanıma uygun amaçlarla Site üzerinde işlem yapabilir. Kullanıcının Site üzerinde aldığı hizmetleri kullanarak yaptığı her işlem ve eylemdeki hukuki ve cezai sorumluluğu kendisine aittir. BtcOley’in hiçbir hukuki, cezai ya da idari sorumluluğu bulunmamaktadır. Kullanıcı’nın hukuka aykırı işlem yapmasının fark edilmesi halinde, BtcOley, her türlü hukuki yola başvurma ve gerekli güvenlik tedbirlerini alma hakkına sahiptir.</li>
                    <li>Her Kullanıcı, başka bir üçüncü şahsın ayni veya şahsi haklarına veya malvarlığına tecavüz teşkil edecek şekilde, Site dahilinde eylemde bulunmayacağını,  gerek eylemleri ile gerekse de başka yollarla Site ile doğrudan ve/veya dolaylı olarak rekabete girmeyeceğini kabul, beyan ve taahhüt eder.</li>
                    <li>Kullanıcı, Site’ye üyelik esnasında verdiği kişisel bilgilerinin işbu Sözleşme ve KVKK kapsamında BtcOley tarafından kullanılacağını bildiğini ve buna muvafakat  ettiğini kabul, beyan ve taahhüt eder.</li>
                    <li>Kullanıcı, Site’de oluşturacağı hesabını, herhangi bir koşulda (para veya hediye karşılığı) başka bir Kullanıcı’ya veya Kullanıcı olmayan biri adına devretmeyeceğini veya satmayacağını kabul, beyan ve taahhüt eder.</li>
                    <li>Kripto para birimlerine yatırım yapmanın getirdiği belli başlı riskleri Kullanıcı bildiğini kabul, beyan ve taahhüt eder. Kullanıcı, işbu Sözleşmenin kabul edilip onaylanması ile kripto para birimlerine yatırımının risklerini bildiğini ve yatırımın sebep olduğu ya da olabileceği zarar ve ziyanlardan BtcOley’i sorumlu tutmayacağını kabul, beyan ve taahhüt eder. Fiyat değişimlerinden oluşan kâr ve zararlardan Kullanıcı sorumludur. Kullanıcı, bir kayıp ya da zarar yaşaması durumunda BtcOley’ den hiçbir hak talep edemez.</li>
                    <li>Kullanıcı, kripto para kullanımı ile ilgili yasal sorumluluklar ve vergi mükellefiyetlerinden  bizzat sorumludur. BtcOley, Kullanıcı’nın kazanç ve kayıplarından oluşan vergisel mükellefiyetlerinden sorumlu tutulamaz.</li>
                    <li>Kullanıcı’ nın 18 yaşından büyük olması yasal bir zorunluluktur. 18 yaş,  Türkiye Cumhuriyeti Merkezi Nüfus İdaresi Sistemine göre belirlenmektedir. Kullanıcı, işbu Sözleşme kapsamında 18 yaşından büyük olduğunu kabul, beyan ve taahhüt etmektedir. BtcOley, herhangi bir nedenle Kullanıcı’ nın 18 yaşından küçük olduğunu tespit eder veya 18 yaşından küçüklerce kullanıldığından şüphe ederse, Kullanıcı hesabını ihbarsız olarak sonlandırma/askıya alma hakkına sahiptir. 18 yaşından büyük olduğunu taahhüt eden Kullanıcı, bildirdiği tüm bilgilerin doğruluğundan re'sen sorumludur. BtcOley, Kullanıcı ile ilgili tüm işlemlerde bu beyana itimat eder ve buna göre davranır.</li>
                    <li>Kullanıcı, BtcOley ile herhangi bir platformda sözlü veya yazılı iletişimde, saygısızlık ve hakaret içeren tavırlarda, yasalara aykırılık teşkil edecek ve suç sayılacak eylem ve söylemlerde bulunamaz. BtcOley’in işbu eylemlerle karşı karşıya kalması halinde Kullanıcı  hesabını dondurmaya, kapatmaya ve hukuki yollara başvurmaya dair hakkı saklıdır.</li>
                    <li>Kullanıcı, BtcOley’in talep ettiği değişiklik ve/veya düzeltmeleri ivedi olarak yerine getirmek zorundadır. BtcOley tarafından talep edilen değişiklik ve/veya düzeltme istekleri, gerekli görüldüğü takdirde, BtcOley tarafından yapılabilir. BtcOley tarafından talep edilen değişiklik ve/veya düzeltme taleplerinin Kullanıcı tarafından zamanında yerine getirilmemesi sebebiyle doğan veya doğabilecek zararlar, hukuki ve cezai sorumluluklar tamamen Kullanıcı ’ya aittir.</li>
                </ol>

                <h5> MADDE 6- SİTE HAK VE YÜKÜMLÜKLERİ</h5>
                <ol>
                    <li>BtcOley, Site’de sunulan hizmetleri ve içerikleri her zaman değiştirebilme, kapatabilme ve silmebilme haklarını saklı tutmaktadır. BtcOley, bu hakkını, hiçbir bildirimde bulunmadan ve önel vermeden kullanabilir.</li>
                    <li>Kullanıcı ve BtcOley hukuken bağımsız taraflardır. Aralarında ortaklık, temsilcilik veya işçi-işveren ilişkisi yoktur. Sözleşme’ nin onaylanması ve uygulanması sonucunda, ortaklık, temsilcilik veya işçi-işveren ilişkisi doğmaz.</li>
                    <li>BtcOley, işbu Sözleşme’de bahsi geçen hizmetlerin sunumuyla ilgili Site üyelik hesabı içerisinde belirtilen açıklamalar ve Sözleşme’de belirtilen koşullar dahilinde yerine getirmeyi, işbu Sözleşme kapsamında belirtilen hizmetlerin sunulabilmesi için Sözleşme’nin 4. Ve 5. Maddesi ile belirlenen hükümler saklı kalmak kaydıyla gerekli olan teknolojik altyapıyı tesis etmeyi ve işletmeyi kabul, beyan ve taahhüt eder. İşbu madde içerisinde belirtilen teknolojik altyapıyı tesis etme yükümlülüğü, sınırsız ve eksiksiz bir hizmet taahhüdü anlamına gelmemektedir. BtcOley, her zaman herhangi bir bildirimde bulunmadan işbu Sözleşme ile belirlenen hizmetlerini ve teknolojik altyapıyı durdurabilir veya son verebilir.</li>
                    <li>BtcOley, Site’ye yapılabilecek fiziksel ve sanal saldırılar sonucu üyelik bilgilerinin kötü amaçlı kişilerin eline geçmesi ve bunların kötü niyetli kullanılması halinde doğabilecek sonuçlardan dolayı sorumluluk kabul etmez.</li>
                    <li>Site; Kullanıcı’ nın üyelik sırasında verdiği iletişim araçlarına bilgi ve reklam iletme hakkına sahip olup, aksi bildirilmedikçe Kullanıcı,  üye olarak, bildirdiği cep telefonuna, e-mail adresine , posta adresine BtcOley’in Site hizmetleri ve kapsamında ulaşılabileceğini kabul etmektedir.</li>
                    <li>BtcOley reddettiği ve gerçekleştirmediği transferlerin iadesi için kimlik ibrazı ve transfer gerçekleştiren kişinin banka hesabı bilgisini isteme hakkına sahiptir. Bu maddede yer alan yükümlülük yerine getirilmedikçe BtcOley’in herhangi bir nam altında hukuki, cezai ve idari sorumluluğu doğmayacaktır.</li>
                    <li>BtcOley, para yatırma ve çekme kurallarında değişiklik yapma hakkına tek taraflı olarak sahiptir. Para yatırma ve çekme kuralları, Site üzerindeki yardım sayfalarında ve diğer sayfalarda bulunan uyarılar ve kuralların bütünüdür. Kripto paraların yanlış bir adrese transferi halinde Kullanıcı’nın sorumluluğu söz konusudur. Yanlış gönderim hususunda Kullanıcı BtcOley’den herhangi bir hak talep edemez.</li>
                    <li>BtcOley, bir kripto para alım-satım platformudur. Site üzerinden Kullanıcı’lar birbirlerine açık arttırma veya eksiltme yöntemi ile kripto para birimlerini alır veya satarlar. BtcOley, söz konusu alım ve satımlar açısından bir aracı Site’dir. Site’de oluşan fiyatları BtcOley belirlemez. Fiyatlarda olan değişikliklerden dolayı Kullanıcı’ların zarar veya kayıplara uğramasından ve doğan ya da doğacak zararlardan BtcOley sorumlu tutulamaz.</li>
                    <li>BtcOley, benzer kripto para  alım satım platformlarından bağımsız bir şirkettir ve herhangi bir kuruluşun temsilcisi değildir. Kullanıcı tarafından BtcOley haricinde bir alım satım platformunda yapılan alım satım işlemlerinden ve bu işlemlerden doğan ya da doğacak zararlardan BtcOley hiçbir şekilde sorumlu tutulamaz.</li>
                    <li>BtcOley üzerinden satın alınan kripto para değerlerinin nasıl kullanıldığının, nereye ve ne amaçla transfer edildiğinin, bir suçta kullanılıp kullanılmadığının, teknik olarak takibi mümkün olamaz. Kripto para değerlerinin kötüye kullanılmasından doğan ya da doğacak, Kullanıcı ya da üçüncü kişilerin zarar ve kayıplarından, menfi veya müspet zararlarından BtcOley sorumlu tutulamaz.</li>
                    <li>BtcOley, Kullanıcı adına muhafaza edilen hesaplarda bulunan kripto paraların güvenliği için basiretli bir tüccar olarak azami özeni göstermekle yükümlüdür. Kullanıcı’ya ait kripto paralar herhangi bir siber saldırıya karşı internet bağlantısı olmayan ortamlarda (cold storage) saklanır. Ancak BtcOley’in tüm bu çabalarına rağmen yine de oluşabilecek hırsızlık/dolandırıcılık ve benzeri olaylarından dolayı BtcOley sorumlu tutulamaz.</li>
                    <li>BtcOley, işlemlerin teknik hatalardan dolayı, gerçekçi olmayan fiyatlardan işlem görmesi gibi durumlarda, sistemi düzeltmek ve doğru çalışmasını sağlamak adına, bu işlemleri iptal edebilir veya geri alabilir. İptal ve geri alma gerekli olduğu hallerde bu Kullanıcı hesapları dondurulabilir, eğer herhangi bir ödeme yapıldı ise haksız yapılmış ödemenin iadesi istenebilir, iade edilmemesi halinde BtcOley her türlü hukuki yola başvurma hakkına sahiptir. Bu tarz işlemlerden dolayı BtcOley sorumlu tutulamaz ve hak talep edilemez.</li>
                    <li>BtcOley, Site’de oluşabilecek teknik arızalardan dolayı hiçbir nam altında sorumlu tutulamaz. Kısa süreli yada uzun süreli teknik arızalardan dolayı doğrudan veya dolaylı olarak doğan ve doğabilecek hiçbir zarardan BtcOley’in sorumlu olmayacağı Kullanıcı tarafından kabul , beyan ve taahhüt edilmektedir.</li>
                    <li>Site'nin, Kullanıcı’nın hatasından doğan veya doğacak, her türlü zarar ve/veya kayıplardan dolayı BtcOley yönetim kurulu üyeleri, yöneticileri, çalışanları ve bu Site’de yer alan bilgileri hazırlayan kişilerin hiçbir hukuki ve cezai sorumluluğu bulunmamaktadır.</li>
                    <li>BtcOley, Kullanıcı tarafından gerçekleştirilen işlemlerin şüpheli görülmesi halinde, Kullanıcı’ların yaptıkları para transferlerini iade etme hakkına sahiptir.</li>
                    <li>BtcOley destek hizmetlerini yalnızca destek@btcoley.com e-posta adresi üzerinden sağlamaktadır. BtcOley, bu e-posta adresi dışında bir yöntem ile Kullanıcı’larına destek hizmeti sağlamaz, destek yazışmalarında şifre sormaz,  kripto para göndermeleri için bir kripto para adresi bildirmez. Bu husus bir tebliğ niteliğinde olup BtcOley, bahsi geçen e-posta adresi dışındaki bir adresten gönderilmiş bir e-posta sebebiyle Kullanıcı’larının uğradığı ya da uğraması muhtemel zarar ve kayıplardan sorumlu tutulamaz.</li>
                    <li>Sayılanlarla sınırlı olmamak üzere elektrik ve şebeke hatlarında, uydu ve yer bağlantılarında, haberleşme alt yapı şebekesinde, sunucu veya cihazlarda olabilecek teknik arızalar, internet trafiğinde tıkanmalar, yığılmalar ve benzerleri nedeniyle Site’ nin çalışmaması halinde, arıza Site tarafından en kısa sürede giderilmeye çalışılacaktır. Bu süre zarfında hizmetin sağlanamamasından doğabilecek olan tüm zararlardan,  Kullanıcı’ nın hiçbir şekilde herhangi bir talep hakkı bulunmamaktadır.</li>
                    <li>Para yatırma ve çekme işlemleri sürelerinde beyan edilen sürelerin dışında işlem yapılması durumunda BtcOley doğacak veya doğabilecek zararlar veya kayıplardan sorumlu değildir.</li>
                    <li>www.btcoley.com web sitesi üzerinde sunulan her tür içerik, 3. Partilerden ve kamuya açık kaynaklardan toplanan verilerden oluşmaktadır. Tüm veriler, analizler, raporlar, istatistikler herhangi bir düzenleme veya yönlendirme olmadan bilgileri otomatik işleme tabi tutulmuş bir yazılım tarafından işlenmekte ve objektif olarak sunulmaktadır. BtcOley tarafından sunulan her türlü haber ve raporlar; sadece bilgilendirmeye ve tavsiyeye yöneliktir ve doğrudan çözüm, sonuç, hukuki görüş, politik ve sosyolojik araştırma bilgisi özelliği taşımamaktadır ve kesin doğruluğu garanti edilmemektedir. Veriler birbiriyle çelişkili veya tutarsız olabilir. Bu tür durumlarda BtcOley hiçbir sorumluluk üstlenmez.</li>
                </ol>
                <h5>MADDE 7- SÖZLEŞME DEĞİŞİKLİKLERİ</h5>
                <p>BtcOley, tamamen kendi takdirine bağlı ve tek taraflı olarak işbu Sözleşme’yi uygun göreceği herhangi bir zamanda Site’de ilan ederek değiştirebilir. İşbu Sözleşmenin değişen hükümleri, ilan edildikleri tarihte geçerlilik kazanacak, geri kalan hükümler aynen yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. Değişiklikleri takip etmek Kullanıcı’nın sorumluluğundadır. BtcOley’in değişiklikleri  bizzat Kullanıcı’ya bildirme yükümlülüğü yoktur. Kullanıcı, Site’de yer alan  hizmetlerinden yararlanmaya devam etmekle bu değişiklikleri de kabul etmiş sayılır. İşbu Sözleşme Kullanıcı’ nın tek taraflı beyanları ile değiştirilemez.</p>
                <h5>MADDE 8- GİZLİLİK POLİTİKASI</h5>
                <ol>
                    <li>BtcOley, Kullanıcı’ya ilişkin bilgileri, ilgili yasadaki düzenlemelere uygun olarak kullanabilir. BtcOley, Kullanıcı’ya ait gizli bilgileri, Sözleşme’de ve Sözleşme’nin ayrılmaz parçası olan eklerinde aksine müsaade edilen durumlar haricinde, üçüncü kişi ve kurumlara kullandıramaz.</li>
                    <li>İş bu Sözleşme’nin Kullanıcı tarafından kabul ve onaylanması halinde, <strong>6698</strong> sayılı <strong>Kişisel Verilerin Korunması</strong>hakkındaki kanundan doğan hak ve yükümlülükleri, BtcOley ile Kullanıcı karşılıklı olarak kabul edilmiş sayılacak ve Kanun kapsamına uygun olarak davranacaklarını kabul ve taahhüt ederler.</li>
                    <li>BtcOley, sunmuş olduğu hizmetlerin ve servislerin daha efektif kullanılabilmesi amacıyla birçok 3. Parti kurum ve kuruluşlarla çeşitli şekillerde işbirliği yapabilir. Bu işbirliği; reklam, sponsorluk, izinli pazarlama, veri paylaşımı ve yasal diğer ticari yöntemlerle olabilir. BtcOley, iletişim faaliyetlerinde, kanunların öngördüğü şekilde izinli iletişim/pazarlama yapacağını, Kullanıcı’nın isteği dışında iletişim yapmamayı, Kullanıcı’nın sistemden ücretsiz ve kolayca çıkabilmesini sağlayacak araçlar sunmayı beyan ve taahhüt eder.</li>
                    <li>BtcOley, kendi uhdesinde bulundurduğu Kullanıcı’ya ait gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır olarak saklamayı yükümlülük olarak kabul ettiğini ve gizliliğin sağlanıp sürdürülmesi, gizli bilginin tamamının veya herhangi bir parçasının kamu alanına girmesini veya yetkisiz Kullanıcı’yı veya üçüncü bir kişiye açıklanmasını önleme gereği olan gerekli tüm tedbirleri almayı ve üzerine düşen tüm özeni  göstermeyi, işbu bildirimle taahhüt etmektedir. Gerekli özen ve tedbir gösterilmesine rağmen üçüncü kişilerin elde edeceği bilgilerden BtcOley sorumlu olmayacaktır.</li>
                </ol>
                <h5>MADDE 9- SÖZLEŞME SÜRESİ</h5>
                <p>İşbu Sözleşme, Kullanıcı, Site’ye üye olduğu sürece yürürlükte kalacak ve taraflar arası hüküm ve sonuçlarını doğurmaya devam edecektir.</p>
                <h5>MADDE 10-  ÜCRETLENDİRME</h5>
                <ol>
                    <li>Site’de olan kripto para transferlerinin iadesi olmayacağından,  BtcOley tarafından takdir edilen komisyon veya işlem ücretlerinin iadesi de mümkün değildir ve Kullanıcı’nın  bu ücretlerin iadesini isteme hakkı bulunmadığı gibi BtcOley’in sorumluluğu da doğmayacaktır.</li>
                    <li>BtcOley, Kullanıcı’lar tarafından yapılan her alım-satım ve para çekme-yatırma işlemlerinden, kendi belirlediği ve www.btcoley.com web sitesi üzerinden yayınladığı bir oran üzerinden komisyon (hizmet bedeli) alma hakkına sahiptir. BtcOley, komisyon ücretini tek taraflı olarak belirleyeceği gibi, önceden duyurmak şartıyla, değişiklik yapma hakkını da haizdir. Kullanıcı, işbu Sözleşme’yi kabul etme ve onaylama ile bu ücret ve komisyonun BtcOley tarafından tahsil edilmesini kabul, beyan ve taahhüt etmiş sayılacaktır. BtcOley, komisyon ücretini Kullanıcı’nın gerçekleştirdiği işleme konu olan kripto para üzerinden tahsil edebileceği gibi, Kullanıcı’nın Site üzerinde mevcut TL hesabından da tahsil edebilir. Kullanıcı’nın hesap bakiyeleri komisyon ücretine yetmezse, komisyon bedeli transfer tutarından düşülebilir ya da transfer işlemi Site tarafından reddedilebilir.</li>
                    <li>BtcOley’ in ücret ve oranlar hakkında yapacağı değişiklikleri  Kullanıcı’ya bizzat bildirme zorunluluğu bulunmamaktadır. Ücret ve oranlarda yapılacak değişiklikler Kullanıcı’nın takip ve sorumluluğunda olacaktır.</li>
                    <li>BtcOley, ücret ve oranlarda yapacağı değişiklikleri “Duyurular” alanında ilan edecektir. Söz konusu değişiklikler belirtilen alanda ilan edildiği tarih itibariyle  geçerli olacaktır.</li>
                </ol>
                <h5>MADDE 11- TAZMİNAT</h5>
                <p>Kullanıcı, BtcOley’ in müdürlerini, hissedarlarını, çalışanlarını, iştiraklerini ve bağlı kuruşlarını, Site’ yi kullanması ile ilgili olarak, BtcOley aleyhinde herhangi bir üçüncü şahıs tarafından öne sürülebilecek her türlü talep ve iddiadan muaf tutmayı ve her türlü zarar ve ziyanını tazmin etmeyi kabul eder.</p>
                <h5>MADDE 12- ÜÇÜNCÜ ŞAHISLARIN İÇERİĞİ</h5>
                <ol>
                    <li>BtcOley, kendisi tarafından sunulan içeriklerin her türlü ticarete elverişlilik, performans, pazarlanabilirlik veya belli bir amaca uygunluk garantileri de dâhil olmak üzere ister açık ister örtülü olsun, hiçbir şekilde garanti vermez. Tüm içerikler Kullanıcı’ya "olduğu gibi" sunulmaktadır. BtcOley, içeriklerin kullanılmasından doğabilecek kâr kayıplarından ya da zararlardan sorumlu tutulamaz.</li>
                    <li>BtcOley tarafından sunulan içeriklerde, herhangi bir Kişiye/Kuruma/Şirkete/Markaya yapılan atıf, bu Kişilerin/Kurumların/Şirketlerin/Markaların piyasa değerine, çeşitli kriterlerdeki sıralamalarına, marka değerine etki yapacak veya hisse senetlerinin satın alınmasına, satılmasına veya elde tutulmasına yönelik bir tavsiye değildir.</li>
                    <li>BtcOley, anlaşmalı kuruluşlar ve üçüncü şahıslar tarafından sağlanan bilgilerin yayıncısıdır, üreticisi değildir. BtcOley’ in bu bilgilerin ve içeriklerin doğruluğunu araştırma, bu bilgi ve içeriklerin güvenli, doğru ve hukuka uygun olduğunu taahhüt ve garanti etme yükümlülüğü ve sorumluluğu yoktur. Site dahilindeki içeriğin hukuki ve cezai sorumluluğu, içeriği oluşturan kişinin kendisine aittir.</li>
                </ol>
                <h5>MADDE 13- FİKRİ MÜLKİYET HAKLARI</h5>
                <ol>
                    <li>BtcOley markasının ve Site’sinin mülkiyeti Magician of Meta Teknoloji A.Ş.’ne  ait olup, Site, Magician of Meta Teknoloji  A.Ş. tarafından işletilmektedir. Bu bağlamda, BtcOley markasının ve Site’sinin tüm kullanım ve mülkiyet hakkı Magician of Meta Teknoloji  A.Ş.‘ne ait olup, Site’ nin içeriği ve buna ilişkin fikri haklar da Magician of Meta Teknoloji A.Ş.‘ne aittir ya da hak sahibinin sarih izni ile kullanılmaktadır.</li>
                    <li>BtcOley tarafından açıkça izin verilmediği sürece Site’ nin içeriği, arayüzü, alan adı, tüm yazılım kodları, veritabanları, Site tasarımı ve sair tüm servislere ilişkin fikri ve sınai mülkiyet hakları BtcOley’e ait olup hiçbir şekilde kopyalanamaz, çoğaltılamaz, tekrardan üretilemez, tekrardan yayınlamaz,  değiştirilemez, indirilemez, yüklenemez veya dağıtılamaz. Site’ de yer alan herhangi bir içerik BtcOley’in fikri mülkiyet haklarını kullanma hakkını verecek şekilde yorumlanamaz.</li>
                    <li>BtcOley’in Site içeriğinde yer alan veya Site üzerinden erişilebilen bir içeriğin, materyalin veya bilginin doğruluğu, telif hakkına uygun olup olmadığı, yasallığını veya topluma uygunluğu konularında bir araştırma yapma mecburiyeti ve yükümlülüğü yoktur.
                    </li>
                    <li>BtcOley’in fikri mülkiyet haklarını ihlal edecek şekilde davranan Kullanıcı, BtcOley’in veya ilişkili üçüncü kişi/kişilerin tüm doğrudan veya dolaylı zararları ile masraflarını tazmin etmekle yükümlü olacaktır.</li>
                    <li>İşbu fikri mülkiyet haklarının ihlali halinde 6769 sayılı Sınai Mülkiyet Kanunu, 6102 sayılı Türk Ticaret Kanunu ve sair tüm mevzuatlar kapsamında hukuki yaptırımlar uygulanacağı ihbar ve ihtar olunur.</li>
                </ol>
                <h5>MADDE 14-MÜCBİR SEBEPLER</h5>
                <p>Hukuken mücbir sebep sayılan tüm hallerde, BtcOley, işbu Sözleşme ile belirlenen yükümlülüklerinden herhangi birini geç veya eksik ifa etme ya da  ifa etmeme nedeniyle yükümlü değildir. Bu ve bunun gibi durumlar, BtcOley için, gecikme, eksik ifa etme veya ifa edememe veya temerrüt addedilemeyecek veya bu durumlar için BtcOley’ den herhangi bir nam altında tazminat talep edilemeyecektir. Mücbir sebep terimi doğal afet, isyan, savaş, grev, iletişim sorunları, altyapı ve internet arızaları, elektrik kesintisi ve kötü hava koşulları da dahil ve fakat bunlarla sınırlı olmamak kaydıyla, ilgili tarafın makul kontrolü haricinde ve gerekli özeni göstermesine rağmen önleyemediği, kaçınılamayacak olaylar olarak yorumlanacaktır.</p>
                <h5>MADDE 15- SÖZLEŞMENİN FESHİ</h5>
                <p>BtcOley, Kullanıcı’nın işbu Sözleşme’nin ve/veya, Site içinde yer alan kullanıma, üyeliğe ve hizmetlere ilişkin benzeri kuralları ve de bu Sözleşme’deki hak ve yükümlülüklerini ihlal etmeleri durumunda ve özellikle aşağıda sayılan hallerde, Sözleşme’yi tek taraflı olarak ve bildirim yapmaksızın feshedebilecektir:

                </p>
                <ol type="a">
                    <li> Kullanıcı’nın herhangi bir yöntem kullanarak, Site’nin işleyişini manipüle edecek davranışlarda bulunması ,</li>
                    <li>Kullanıcı’nın kendisi için oluşturulmuş Kullanıcı profilini başkasına devretmesi veya kullanıma açması,</li>
                    <li>Kullanıcı’nın üçüncü kişilerin haklarına tecavüz eden ve/veya etme tehlikesi bulunan fiillerde bulunması,</li>
                    <li>Yasal mevzuat gereği hukuka aykırı sayılacak tüm fiillerin işlenmesi,Halinde yukarıdaki madde hükümleri uygulanacaktır.</li>
                </ol>
                <h5>MADDE 16- UYGULANACAK HUKUK VE YETKİ</h5>
                <ol>
                    <li>İşbu Sözleşme’nin uygulanmasında, yorumlanmasında ve hükümleri dahilinde doğan hukuki ilişkilerin yönetiminde Türk Hukuku uygulanacaktır . İşbu Sözleşme’den doğan veya doğabilecek her türlü ihtilaf halinde, İSTANBUL  Mahkemeleri ve İcra Daireleri yetkilidir.</li>
                    <li>Kullanıcı, İşbu Sözleşme’nin uygulanmasında doğabilecek ihtilaflarda BtcOley’in bilgisayar kayıtlarının ve Sözleşme hükümlerinin Hukuk Muhakemeleri Kanunun madde 193 uyarınca muteber, bağlayıcı, kesin ve münhasır delil teşkil edeceğini ve bu maddenin Delil Sözleşmesi niteliğinde olduğunu ve BtcOley kayıtlarına her türlü itiraz ve bunların usulüne uygun tutulduğu hususunda yemin  hakkından peşinen feragat ettiğini kabul, beyan ve taahhüt eder.</li>
                    <li>Site’nin herhangi bir sayfasına girerek, <strong>Kullanım Sözleşmesi</strong>'nin tamamını okuduğunuzu, içeriğini bütünü ile anladığınızı ve tüm hükümlerini onayladığınızı kabul, beyan ve taahhüt etmektesiniz. <strong>Kullanım Sözleşmesi ve Değişiklikleri</strong> BtcOley tarafından Site üzerinde ilan edildiği tarihte yürürlük kazanır.</li>
                </ol>

            </div>
        </div>
    )
}

export default TabActive;